import React from 'react';
import { SalesAssistant } from '../components/assistant/SalesAssistant';

export function Assistant() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-8">
        <h1 className="text-2xl font-semibold text-gray-900 mb-6">Sales Assistant</h1>
        <SalesAssistant />
      </div>
    </div>
  );
}