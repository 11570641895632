import { sendSMS } from './twilio';
import type { Customer } from '../types/customer';

import { supabase } from '../lib/supabase';

export async function generateReviewLink(customerId: string): Promise<string> {
  const trackingId = `${customerId}-${Date.now()}`;
  
  // Create tracking record in Supabase
  const { error } = await supabase
    .from('review_links')
    .insert({
      tracking_id: trackingId,
      customer_id: customerId,
      review_url: `https://g.page/r/`,
      created_at: new Date().toISOString()
    });

  if (error) throw error;

  const baseUrl = 'https://g.page/r/';
  const trackingUrl = `${window.location.origin}/r/${trackingId}`;
  
  return `${baseUrl}?redirect=${encodeURIComponent(trackingUrl)}`;
}

export async function trackReviewLinkClick(trackingId: string): Promise<void> {
  const { error } = await supabase
    .from('review_links')
    .update({
      clicked: true,
      clicked_at: new Date().toISOString()
    })
    .eq('tracking_id', trackingId);

  if (error) throw error;
}

export async function sendReviewRequest(customer: Customer): Promise<void> {
  try {
    const reviewLink = await generateReviewLink(customer.id);
    const message = 'Thank you for choosing our dealership! Please share your experience:';

    // Update customer review status in Supabase
    const { error } = await supabase
      .from('customers')
      .update({
        review_status: {
          sent: true,
          sentDate: new Date().toISOString(),
          linkUsed: reviewLink
        }
      })
      .eq('id', customer.id);

    if (error) throw error;

    // Send SMS with review link
    await sendSMS({
      to: customer.phone,
      message: `${message}\n${reviewLink}`
    });

  } catch (error) {
    console.error('Error sending review request:', error);
    throw error;
  }
}