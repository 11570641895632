import React, { useState, useEffect } from 'react';
import { Loader2, BarChart2, LayoutGrid, Table as TableIcon } from 'lucide-react';
import { InventoryStats } from '../components/inventory/InventoryStats';
import { InventoryGrid } from '../components/inventory/InventoryGrid';
import { InventoryFilters } from '../components/inventory/InventoryFilters';
import { InventoryTable } from '../components/inventory/InventoryTable';
import { InventoryDashboard } from '../components/inventory/InventoryDashboard';
import { getInventory, seedInventory } from '../lib/firebase/inventory';
import type { InventoryItem } from '../types/inventory';
import { fetchBaserowInventory } from '../services/baserowService';

// Model statistics data
const MODEL_STATS = [
  {"Model":"A3 Sedan","Count":8,"Average_Year":2023.5,"Total_Service_Count":12},
  {"Model":"A4 allroad","Count":6,"Average_Year":2023.0,"Total_Service_Count":8},
  {"Model":"A5 Sportback","Count":4,"Average_Year":2023.0,"Total_Service_Count":6},
  {"Model":"e-tron GT","Count":3,"Average_Year":2024.0,"Total_Service_Count":2},
  {"Model":"Q4 e-tron","Count":5,"Average_Year":2024.0,"Total_Service_Count":4},
  {"Model":"Q5","Count":10,"Average_Year":2023.0,"Total_Service_Count":15},
  {"Model":"Q7","Count":7,"Average_Year":2023.0,"Total_Service_Count":9},
  {"Model":"Q8 e-tron","Count":4,"Average_Year":2024.0,"Total_Service_Count":3},
  {"Model":"RS e-tron GT","Count":2,"Average_Year":2024.0,"Total_Service_Count":1},
  {"Model":"S5 Sportback","Count":3,"Average_Year":2023.0,"Total_Service_Count":4}
];

export function Inventory() {
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [filteredInventory, setFilteredInventory] = useState<InventoryItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('grid');
  const [showReporting, setShowReporting] = useState(false);
  const [sortField, setSortField] = useState<keyof InventoryItem>('stockNumber');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filters, setFilters] = useState({
    make: '',
    model: '',
    year: '',
    status: '',
    condition: '',
    priceRange: { min: 0, max: 1000000 },
    search: ''
  });

  useEffect(() => {
    loadInventory();
  }, []);

  const loadInventory = async () => {
    try {
      setError(null);
      const baserowData = await fetchBaserowInventory();
      setInventory(baserowData);
      setFilteredInventory(baserowData);
    } catch (err) {
      console.error('Error loading inventory:', err);
      setError('Failed to load inventory');
    } finally {
      setLoading(false);
    }
  };

  const makes = [...new Set(inventory.map(item => item.make))].sort();
  const models = [...new Set(inventory.map(item => item.model))].sort();
  const years = [...new Set(inventory.map(item => item.year))].sort((a, b) => b - a);
  const conditions = [...new Set(inventory.map(item => item.condition))].sort();

  const handleFilter = (newFilters: typeof filters) => {
    setFilters(newFilters);
    
    let filtered = inventory;
    
    if (newFilters.search) {
      const searchTerm = newFilters.search.toLowerCase();
      filtered = filtered.filter(item => 
        item.make.toLowerCase().includes(searchTerm) ||
        item.model.toLowerCase().includes(searchTerm) ||
        item.stockNumber.toLowerCase().includes(searchTerm) ||
        item.vin.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm)
      );
    }
    
    if (newFilters.make) {
      filtered = filtered.filter(item => item.make === newFilters.make);
    }
    if (newFilters.model) {
      filtered = filtered.filter(item => item.model === newFilters.model);
    }
    if (newFilters.year) {
      filtered = filtered.filter(item => item.year.toString() === newFilters.year);
    }
    if (newFilters.condition) {
      filtered = filtered.filter(item => item.condition === newFilters.condition);
    }
    if (newFilters.priceRange) {
      filtered = filtered.filter(item => 
        item.price >= newFilters.priceRange.min && 
        item.price <= newFilters.priceRange.max
      );
    }

    setFilteredInventory(filtered);
  };

  const handleSort = (field: keyof InventoryItem) => {
    const newDirection = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);

    const sorted = [...filteredInventory].sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];
      if (aValue < bValue) return newDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return newDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredInventory(sorted);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Inventory Management</h1>
          <p className="mt-2 text-sm text-gray-700">
            {filteredInventory.length} vehicles in inventory
          </p>
        </div>
        <div className="mt-4 sm:mt-0 flex space-x-3">
          <button
            onClick={() => setShowReporting(!showReporting)}
            className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ${
              showReporting
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            }`}
          >
            <BarChart2 className="h-4 w-4 mr-2" />
            {showReporting ? 'Hide Reports' : 'Show Reports'}
          </button>
          <button
            onClick={() => setViewMode('grid')}
            className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ${
              viewMode === 'grid'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            }`}
          >
            <LayoutGrid className="h-4 w-4 mr-2" />
            Grid
          </button>
          <button
            onClick={() => setViewMode('table')}
            className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ${
              viewMode === 'table'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            }`}
          >
            <TableIcon className="h-4 w-4 mr-2" />
            Table
          </button>
        </div>
      </div>

      {showReporting && (
        <>
          <InventoryStats stats={{
            totalVehicles: filteredInventory.length,
            availableVehicles: filteredInventory.filter(item => item.status === 'Available').length,
            totalValue: filteredInventory.reduce((sum, item) => sum + item.price, 0),
            avgPrice: filteredInventory.reduce((sum, item) => sum + item.price, 0) / filteredInventory.length,
            avgMileage: filteredInventory.reduce((sum, item) => sum + item.mileage, 0) / filteredInventory.length
          }} />

          <InventoryDashboard 
            inventory={filteredInventory} 
            modelStats={MODEL_STATS} 
          />
        </>
      )}

      <InventoryFilters
        filters={filters}
        onFilterChange={handleFilter}
        makes={makes}
        models={models}
        years={years}
        conditions={conditions}
      />

      {viewMode === 'grid' ? (
        <InventoryGrid inventory={filteredInventory} />
      ) : (
        <InventoryTable
          inventory={filteredInventory}
          sortField={sortField}
          sortDirection={sortDirection}
          onSort={handleSort}
        />
      )}
    </div>
  );
}