import Vapi from '@vapi-ai/web';

const VAPI_API_KEY = import.meta.env.VITE_VAPI_API_KEY;

if (!VAPI_API_KEY) {
  console.warn('Vapi API key missing - some features will be disabled');
}

export const vapiClient = new Vapi({
  apiKey: VAPI_API_KEY || '0a9d0273-0f29-4bb7-840c-6ceaa731943c' // Demo key
});