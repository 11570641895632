import { useState } from 'react';
import { vapiClient } from './config';

export function useVapiCall(phoneNumber: string) {
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [callId, setCallId] = useState<string | null>(null);

  const startCall = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const call = await vapiClient.createCall({
        phoneNumber,
        assistant: {
          name: "AutoCRM Sales Assistant",
          model: "gpt-4",
          systemPrompt: `You are an automotive sales assistant helping customers with their vehicle inquiries. 
                        Be professional, friendly, and knowledgeable about vehicles.`
        }
      });

      setCallId(call.id);
      setIsConnected(true);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to start call'));
    } finally {
      setIsLoading(false);
    }
  };

  const endCall = async () => {
    if (callId) {
      try {
        setIsLoading(true);
        await vapiClient.endCall(callId);
        setIsConnected(false);
        setCallId(null);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to end call'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return { isConnected, isLoading, error, startCall, endCall };
}