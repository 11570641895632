import React from 'react';
import { Brain, TrendingUp, AlertTriangle, CheckCircle2, Loader2 } from 'lucide-react';
import type { Lead } from '../../types/lead';

interface LeadScoreCardProps {
  score: {
    score: number;
    probability: number;
    factors: Array<{
      name: string;
      impact: number;
      type: 'positive' | 'negative';
    }>;
  };
  isLoading: boolean;
  lead?: Lead;
}

export function LeadScoreCard({ score, isLoading, lead }: LeadScoreCardProps) {
  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="flex items-center justify-center h-40">
          <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
        </div>
      </div>
    );
  }

  const getScoreColor = (score: number) => {
    if (score >= 80) return 'text-green-600';
    if (score >= 60) return 'text-blue-600';
    if (score >= 40) return 'text-yellow-600';
    return 'text-red-600';
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold text-gray-900">Lead Score Analysis</h3>
        <Brain className="h-6 w-6 text-purple-500" />
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="bg-gray-50 rounded-lg p-4">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Score</span>
            <TrendingUp className="h-5 w-5 text-gray-400" />
          </div>
          <p className={`mt-1 text-2xl font-bold ${getScoreColor(score.score)}`}>
            {score.score}
          </p>
        </div>

        <div className="bg-gray-50 rounded-lg p-4">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Probability</span>
            <CheckCircle2 className="h-5 w-5 text-gray-400" />
          </div>
          <p className="mt-1 text-2xl font-bold text-gray-900">
            {Math.round(score.probability * 100)}%
          </p>
        </div>
      </div>

      <div className="space-y-4">
        <h4 className="text-sm font-medium text-gray-700">Key Factors</h4>
        {score.factors.map((factor, index) => (
          <div key={`${factor.name}-${index}`} className="flex items-center justify-between">
            <div className="flex items-center">
              {factor.type === 'positive' ? (
                <CheckCircle2 className="h-4 w-4 text-green-500 mr-2" />
              ) : (
                <AlertTriangle className="h-4 w-4 text-yellow-500 mr-2" />
              )}
              <span className="text-sm text-gray-600">{factor.name}</span>
            </div>
            <span className={`text-sm font-medium ${
              factor.type === 'positive' ? 'text-green-600' : 'text-yellow-600'
            }`}>
              {factor.type === 'positive' ? '+' : '-'}{factor.impact}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}