import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { RealtimeChannel } from '@supabase/supabase-js';

interface UseDatabaseOptions {
  table: string;
  select?: string;
  filters?: Record<string, any>;
  orderBy?: { column: string; ascending?: boolean };
  limit?: number;
  realtime?: boolean;
}

export function useDatabase<T = any>({
  table,
  select = '*',
  filters = {},
  orderBy,
  limit,
  realtime = false
}: UseDatabaseOptions) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [subscription, setSubscription] = useState<RealtimeChannel | null>(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      let query = supabase
        .from(table)
        .select(select);

      // Apply filters
      Object.entries(filters).forEach(([key, value]) => {
        query = query.eq(key, value);
      });

      // Apply ordering
      if (orderBy) {
        query = query.order(orderBy.column, { ascending: orderBy.ascending ?? false });
      }

      // Apply limit
      if (limit) {
        query = query.limit(limit);
      }

      const { data: result, error: queryError } = await query;

      if (queryError) throw queryError;
      setData(result || []);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An error occurred'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    if (realtime) {
      const channel = supabase
        .channel(`${table}_changes`)
        .on(
          'postgres_changes',
          { 
            event: '*', 
            schema: 'public', 
            table: table,
            filter: Object.entries(filters).length > 0 ? filters : undefined
          },
          (payload) => {
            if (payload.eventType === 'INSERT') {
              setData(prev => {
                const newItem = payload.new as T;
                return [newItem, ...prev.filter(item => (item as any).id !== (newItem as any).id)];
              });
            } else if (payload.eventType === 'UPDATE') {
              setData(prev => 
                prev.map(item => 
                  (item as any).id === payload.new.id ? payload.new : item
                )
              );
            } else if (payload.eventType === 'DELETE') {
              setData(prev => 
                prev.filter(item => (item as any).id !== payload.old.id)
              );
            }
          }
        )
        .subscribe();

      setSubscription(channel);
      return () => {
        channel.unsubscribe();
      };
    }
  }, [table, JSON.stringify(filters), orderBy?.column, orderBy?.ascending, limit, select]);

  const refresh = () => {
    fetchData();
  };

  return { data, loading, error, refresh };
}