import { InventoryItem } from '../types/inventory';

const BASEROW_API_URL = 'https://baserow.io/public/grid/O2H8UwxWxrDaRAsfVrJOt9Vt6yMIq3U9BdWmU2cjnFM';

export async function fetchBaserowInventory(): Promise<InventoryItem[]> {
  try {
    const response = await fetch(BASEROW_API_URL);
    
    if (!response.ok) {
      console.warn('Falling back to sample inventory data');
      return getSampleInventory();
    }
    
    const data = await response.json();
    
    return data.map((item: any) => ({
      id: item.id.toString(),
      stockNumber: item.ID || `STK${Math.random().toString(36).substr(2, 6)}`,
      year: parseInt(item.Year) || new Date().getFullYear(),
      make: item.Make || '',
      model: item.Model || '',
      trim: item.Type || '',
      vin: item.VIN || `VIN${Math.random().toString(36).substr(2, 9)}`,
      price: parseFloat(item['Suggested price']) || 0,
      mileage: parseInt(item.Mileage) || 0,
      description: item.Remarks || '',
      status: item.Status || 'Available',
      imageUrl: item.Photo?.match(/\((.*?)\)/)?.[1] || 'https://images.unsplash.com/photo-1611566026373-c6c8da0ea861?auto=format&fit=crop&w=1600&q=80',
      dealership: item.Branch || '',
      listingUrl: '#',
      location: {
        city: item.Branch?.replace('CD ', '') || '',
        province: 'California',
        postalCode: getPostalCode(item.Branch)
      },
      bodyStyle: item.Type || '',
      condition: item.Condition || 'Used'
    }));
  } catch (error) {
    console.warn('Error fetching from Baserow, falling back to sample data:', error);
    return getSampleInventory();
  }
}

function getPostalCode(branch: string): string {
  const postalCodes: { [key: string]: string } = {
    'CD La Mesa': '91942',
    'CD Fremont': '94538',
    'CD San Leandro': '94577',
    'CD Pomona': '91766'
  };
  return postalCodes[branch] || '00000';
}

function getSampleInventory(): InventoryItem[] {
  return import('../data/sampleInventory').then(m => m.sampleInventory);
}