import { supabase } from './config';

export interface SettingsData {
  id?: string;
  type: string;
  data: any;
  created_at?: string;
  updated_at?: string;
}

export async function getSettings(type: string): Promise<any> {
  try {
    const { data, error } = await supabase
      .from('settings')
      .select('*')
      .eq('type', type)
      .single();
    
    if (error) {
      if (error.code === 'PGRST116') {
        // No data found
        return null;
      }
      throw error;
    }
    
    return data?.data || null;
  } catch (error) {
    console.error(`Error fetching ${type} settings:`, error);
    throw error;
  }
}

export async function updateSettings(id: string, data: any): Promise<boolean> {
  try {
    const { error } = await supabase
      .from('settings')
      .update({
        data,
        updated_at: new Date().toISOString()
      })
      .eq('id', id);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error updating settings:', error);
    throw error;
  }
}

export async function createSettings(type: string, data: any): Promise<string> {
  try {
    const { data: result, error } = await supabase
      .from('settings')
      .insert([{
        type,
        data,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    return result.id;
  } catch (error) {
    console.error('Error creating settings:', error);
    throw error;
  }
}