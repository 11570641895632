import React, { useState } from 'react';
import { Mail, Wand2, Loader2 } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

export function EmailGenerator() {
  const { generateResponse, isLoading } = useAI();
  const [formData, setFormData] = useState({
    recipient: '',
    purpose: 'follow_up',
    vehicle: '',
    points: ''
  });
  const [generatedEmail, setGeneratedEmail] = useState<string | null>(null);

  const handleGenerate = async () => {
    const prompt = `Generate a professional email for:
      Purpose: ${formData.purpose}
      Recipient: ${formData.recipient}
      Vehicle: ${formData.vehicle}
      Key Points: ${formData.points}
      
      Make it personalized, professional, and persuasive.`;

    const response = await generateResponse(prompt);
    if (response) {
      setGeneratedEmail(response);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Mail className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Email Generator</h2>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Recipient Name</label>
            <input
              type="text"
              value={formData.recipient}
              onChange={(e) => setFormData({ ...formData, recipient: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="e.g. John Smith"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email Purpose</label>
            <select
              value={formData.purpose}
              onChange={(e) => setFormData({ ...formData, purpose: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="follow_up">Follow Up</option>
              <option value="initial_contact">Initial Contact</option>
              <option value="quote">Price Quote</option>
              <option value="test_drive">Test Drive Invitation</option>
              <option value="thank_you">Thank You</option>
              <option value="service_reminder">Service Reminder</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Vehicle Information</label>
            <input
              type="text"
              value={formData.vehicle}
              onChange={(e) => setFormData({ ...formData, vehicle: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="e.g. 2024 BMW M5 Competition"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Key Points to Include</label>
            <textarea
              value={formData.points}
              onChange={(e) => setFormData({ ...formData, points: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Enter any specific points you want to include in the email..."
            />
          </div>

          <button
            onClick={handleGenerate}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Generating Email...
              </>
            ) : (
              <>
                <Wand2 className="-ml-1 mr-2 h-5 w-5" />
                Generate Email
              </>
            )}
          </button>
        </div>

        {generatedEmail && (
          <div className="mt-6 bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Generated Email</h3>
            <div className="prose prose-blue max-w-none">
              {generatedEmail.split('\n').map((line, index) => (
                <p key={index} className="text-gray-600">{line}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}