import React from 'react';
import { Video, Upload } from 'lucide-react';

interface VideoMessageButtonProps {
  onRecord: () => void;
  onUpload: () => void;
  className?: string;
}

export function VideoMessageButton({ onRecord, onUpload, className = '' }: VideoMessageButtonProps) {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <button
        onClick={onRecord}
        className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
      >
        <Video className="h-4 w-4 mr-2" />
        Record Video
      </button>
      <button
        onClick={onUpload}
        className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        <Upload className="h-4 w-4 mr-2" />
        Upload Video
      </button>
    </div>
  );
}