import React, { useState } from 'react';
import { Lightbulb, Volume2, Loader2, RefreshCw } from 'lucide-react';
import { openai } from '../../utils/openai';

const SALES_TIPS = [
  "Tailor your pitch to each customer's unique lifestyle and priorities",
  "Offer a test drive early in the conversation to build excitement",
  "Highlight the value of add-ons only if they match the customer's needs",
  "Keep your energy positive and maintain genuine enthusiasm",
  "Ask open-ended questions to better understand the customer's preferences",
  "Maintain eye contact to build trust and convey sincerity",
  "Leverage digital tools to share vehicle info and features seamlessly",
  "Mention current promotions or limited-time offers with transparency",
  "Be prepared to handle objections with empathy and facts",
  "Ensure your online presence aligns with your in-person experience",
  "Stay updated on competitors' offerings to highlight your advantages",
  "Use simple language to explain complex vehicle technologies",
  "Encourage customers to bring friends or family for support",
  "Offer financing details early to avoid surprises later in the process",
  "Make the showroom environment welcoming and customer-friendly",
  "Follow up with personalized video messages for a unique touch",
  "Keep notes on each customer's preferences for future conversations",
  "Show appreciation with a handwritten thank-you note post-sale",
  "Stay informed on current market trends and customer expectations",
  "Proactively share vehicle maintenance tips for added value",
  "Be punctual and prepared for all appointments and test drives",
  "Discuss total cost of ownership, not just the sticker price",
  "Respect the customer's time by streamlining the sales process",
  "Use stories about a vehicle's reliability and user satisfaction",
  "Make sure customers feel they are in control of the buying process",
  "Understand the importance of body language and non-verbal cues",
  "Keep your work area organized to appear professional and efficient",
  "Use social proof, such as positive reviews and testimonials, to build trust",
  "Send timely reminders for scheduled appointments and follow-ups",
  "Provide detailed comparisons when customers are undecided between models",
  "Offer solutions, not just products—solve the customer's problem",
  "Be knowledgeable about aftermarket services and warranties",
  "Practice patience; some customers need more time to decide",
  "Stay available and reachable after the sale to foster loyalty",
  "Listen more than you talk during initial meetings to build trust",
  "Create engaging content to share online that educates potential buyers",
  "Keep up with technological advancements in automotive features",
  "Highlight safety features and their real-world benefits",
  "Host dealership events to create community and invite prospects",
  "Utilize digital channels for follow-ups, such as emails or texts",
  "Reward referrals to incentivize word-of-mouth promotion",
  "Provide a seamless transition from the online browsing to in-store experience",
  "Stay calm under pressure and during negotiation talks",
  "Remember that every interaction builds your long-term reputation"
];

export function SalesTipCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [currentTip, setCurrentTip] = useState(() => 
    SALES_TIPS[Math.floor(Math.random() * SALES_TIPS.length)]
  );

  const generateNewTip = async () => {
    setIsLoading(true);
    try {
      // Clear previous audio
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
        setAudioUrl(null);
      }

      // Get a new random tip
      const newTip = SALES_TIPS[Math.floor(Math.random() * SALES_TIPS.length)];
      setCurrentTip(newTip);

      // Generate speech
      const speechResponse = await openai.audio.speech.create({
        model: "tts-1",
        voice: "alloy",
        input: newTip
      });

      // Convert the response to a blob URL
      const blob = new Blob([await speechResponse.arrayBuffer()], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);
    } catch (error) {
      console.error('Error generating audio:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Lightbulb className="h-6 w-6 text-yellow-500" />
            <h3 className="ml-2 text-lg font-medium text-gray-900">Sales Tip of the Day</h3>
          </div>
          <button
            onClick={generateNewTip}
            disabled={isLoading}
            className="inline-flex items-center rounded-md bg-blue-50 px-3 py-2 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
            New Tip
          </button>
        </div>

        <p className="mt-4 text-gray-600">{currentTip}</p>

        <div className="mt-4">
          {isLoading ? (
            <div className="flex items-center justify-center py-4">
              <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
              <span className="ml-2 text-sm text-gray-500">Generating audio...</span>
            </div>
          ) : audioUrl ? (
            <div className="flex items-center space-x-4">
              <Volume2 className="h-5 w-5 text-gray-400" />
              <audio controls className="w-full">
                <source src={audioUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ) : (
            <button
              onClick={generateNewTip}
              className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
            >
              <Volume2 className="h-5 w-5 mr-2" />
              Generate Audio
            </button>
          )}
        </div>
      </div>
    </div>
  );
}