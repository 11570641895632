import React, { useState, useEffect } from 'react';
import { Share2, Save } from 'lucide-react';
import { getSettings, updateSettings, createSettings } from '../../lib/supabase/settings';
import type { LeadDistributionSettings } from '../../types/leadDistribution';

const INITIAL_SETTINGS: LeadDistributionSettings = {
  mode: 'round-robin',
  notifyViaSMS: true,
  notifyViaEmail: true,
  timeoutMinutes: 5,
  enableAfterHours: false,
};

export function LeadDistributionSettings() {
  const [settings, setSettings] = useState<LeadDistributionSettings & { id?: string }>(INITIAL_SETTINGS);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      const distributionSettings = await getSettings('leadDistribution');
      if (!distributionSettings) {
        // Create initial settings if none exist
        const id = await createSettings('leadDistribution', INITIAL_SETTINGS);
        setSettings({ ...INITIAL_SETTINGS, id });
      } else {
        setSettings({ ...INITIAL_SETTINGS, ...distributionSettings });
      }
    } catch (err) {
      setError('Failed to load lead distribution settings');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);
    
    try {
      const id = settings.id || await createSettings('leadDistribution', settings);
      await updateSettings(id, settings);
      setIsEditing(false);
    } catch (err) {
      setError('Failed to save settings');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* Rest of the component remains the same */}
    </div>
  );
}