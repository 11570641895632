import React, { useState } from 'react';
import {
  Card,
  Select,
  SelectItem,
  DateRangePicker,
  DateRangePickerValue,
} from '@tremor/react';
import { Calendar, Users, Clock, TrendingUp, ArrowUp, ArrowDown, Filter, Download, ChevronRight } from 'lucide-react';
import { LeadsChart } from '../components/reports/LeadsChart';
import { SourcesChart } from '../components/reports/SourcesChart';
import { PerformanceChart } from '../components/reports/PerformanceChart';
import { ResponseTimeChart } from '../components/reports/ResponseTimeChart';
import { SalesFunnel } from '../components/reports/SalesFunnel';
import { MetricsDashboard } from '../components/reports/MetricsDashboard';
import { motion } from 'framer-motion';

// Mock data
const mockLeads = [
  {
    id: '1',
    name: 'John Smith',
    status: 'new',
    source: 'website',
    createdAt: '2024-03-01T10:00:00Z',
  },
  {
    id: '2',
    name: 'Jane Doe',
    status: 'qualified',
    source: 'referral',
    createdAt: '2024-03-02T14:30:00Z',
  },
  {
    id: '3',
    name: 'Mike Johnson',
    status: 'converted',
    source: 'website',
    createdAt: '2024-03-03T09:15:00Z',
  },
];

export function Reports() {
  const [selectedView, setSelectedView] = useState(0);
  const [selectedTimeframe, setSelectedTimeframe] = useState('30');
  const [dateRange, setDateRange] = useState({
    from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    to: new Date()
  });

  const transformLeadsData = () => {
    const monthlyData: { [key: string]: { leads: number; conversions: number } } = {};
    
    mockLeads.forEach(lead => {
      const date = new Date(lead.createdAt);
      const monthKey = date.toLocaleString('default', { month: 'short', year: 'numeric' });
      
      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = { leads: 0, conversions: 0 };
      }
      
      monthlyData[monthKey].leads++;
      if (lead.status === 'converted') {
        monthlyData[monthKey].conversions++;
      }
    });

    return Object.entries(monthlyData).map(([date, data]) => ({
      date,
      leads: data.leads,
      conversions: data.conversions,
      revenue: data.conversions * 25000 // Example average revenue per conversion
    }));
  };

  const transformSourceData = () => {
    const sourceCounts: { [key: string]: number } = {};
    const total = mockLeads.length;

    mockLeads.forEach(lead => {
      if (!sourceCounts[lead.source]) {
        sourceCounts[lead.source] = 0;
      }
      sourceCounts[lead.source]++;
    });

    return Object.entries(sourceCounts).map(([source, count]) => ({
      source,
      value: Math.round((count / total) * 100)
    }));
  };

  const stats = {
    totalLeads: mockLeads.length,
    newLeads: mockLeads.filter(l => l.status === 'new').length,
    qualifiedLeads: mockLeads.filter(l => l.status === 'qualified').length,
    convertedLeads: mockLeads.filter(l => l.status === 'converted').length,
    conversionRate: ((mockLeads.filter(l => l.status === 'converted').length / mockLeads.length) * 100).toFixed(1)
  };

  const funnelData = [
    {
      stage: 'New Leads',
      count: stats.totalLeads,
      value: stats.totalLeads * 30000,
      color: 'bg-blue-500'
    },
    {
      stage: 'Qualified',
      count: stats.qualifiedLeads,
      value: stats.qualifiedLeads * 30000,
      color: 'bg-indigo-500'
    },
    {
      stage: 'Converted',
      count: stats.convertedLeads,
      value: stats.convertedLeads * 30000,
      color: 'bg-violet-500'
    }
  ];

  const leadsChartData = transformLeadsData();
  const sourceChartData = transformSourceData();

  const metricsData = {
    totalLeads: stats.totalLeads,
    conversionRate: parseFloat(stats.conversionRate),
    avgResponseTime: 2.4,
    totalSales: stats.convertedLeads,
    revenue: stats.totalLeads * 25000,
    metrics: leadsChartData,
    sourceDistribution: sourceChartData
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="space-y-8"
    >
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
            Performance Metrics
          </h1>
          <p className="mt-2 text-lg text-gray-600">
            Comprehensive analysis of your dealership's performance
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:flex sm:items-center sm:space-x-4">
          <DateRangePicker
            className="max-w-md"
            value={dateRange}
            onValueChange={setDateRange}
            locale="en-US"
            selectPlaceholder="Select date range"
          />
          <Select
            className="mt-2 sm:mt-0"
            defaultValue="30"
            onValueChange={setSelectedTimeframe}
          >
            <SelectItem value="7">Last 7 days</SelectItem>
            <SelectItem value="30">Last 30 days</SelectItem>
            <SelectItem value="90">Last 90 days</SelectItem>
            <SelectItem value="365">Last year</SelectItem>
          </Select>
          <button
            onClick={() => {
              // TODO: Implement export functionality
              console.log('Exporting metrics...');
            }}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Download className="h-4 w-4 mr-2" />
            Export
          </button>
        </div>
      </div>

      <MetricsDashboard 
        data={metricsData}
        dateRange={dateRange}
      />
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl shadow-sm p-6"
        >
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900">Lead Performance</h2>
            <button
              onClick={() => {}}
              className="text-blue-600 hover:text-blue-700 flex items-center text-sm font-medium"
            >
              View Details
              <ChevronRight className="ml-1 h-4 w-4" />
            </button>
          </div>
          <LeadsChart data={leadsChartData} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl shadow-sm p-6"
        >
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900">Lead Sources</h2>
            <button
              onClick={() => {}}
              className="text-purple-600 hover:text-purple-700 flex items-center text-sm font-medium"
            >
              View Details
              <ChevronRight className="ml-1 h-4 w-4" />
            </button>
          </div>
          <SourcesChart data={sourceChartData} />
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="bg-gradient-to-br from-amber-50 to-orange-50 rounded-xl shadow-sm p-6"
      >
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Response Time Analysis</h2>
          <button
            onClick={() => {}}
            className="text-amber-600 hover:text-amber-700 flex items-center text-sm font-medium"
          >
            View Details
            <ChevronRight className="ml-1 h-4 w-4" />
          </button>
        </div>
        <ResponseTimeChart data={[]} />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="bg-gradient-to-br from-emerald-50 to-teal-50 rounded-xl shadow-sm p-6"
      >
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Sales Performance</h2>
          <button
            onClick={() => {}}
            className="text-emerald-600 hover:text-emerald-700 flex items-center text-sm font-medium"
          >
            View Details
            <ChevronRight className="ml-1 h-4 w-4" />
          </button>
        </div>
        <PerformanceChart data={leadsChartData} />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
        className="grid gap-6 md:grid-cols-4 mt-8"
      >
        <Card className="bg-gradient-to-br from-blue-500 to-indigo-600 text-white p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-blue-100">Total Leads</p>
              <p className="mt-2 text-3xl font-bold text-white">
                {stats.totalLeads}
              </p>
            </div>
            <Users className="h-12 w-12 text-white opacity-50" />
          </div>
        </Card>

        <Card className="bg-gradient-to-br from-emerald-500 to-green-600 text-white p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-emerald-100">Conversion Rate</p>
              <p className="mt-2 text-3xl font-bold text-white">
                {((stats.convertedLeads / stats.totalLeads) * 100).toFixed(1)}%
              </p>
            </div>
            <TrendingUp className="h-12 w-12 text-white opacity-50" />
          </div>
        </Card>

        <Card className="bg-gradient-to-br from-purple-500 to-violet-600 text-white p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-purple-100">Avg Response Time</p>
              <p className="mt-2 text-3xl font-bold text-white">2.4h</p>
            </div>
            <Clock className="h-12 w-12 text-white opacity-50" />
          </div>
        </Card>

        <Card className="bg-gradient-to-br from-amber-500 to-orange-600 text-white p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-amber-100">Monthly Growth</p>
              <p className="mt-2 text-3xl font-bold text-white">+12.5%</p>
            </div>
            <Calendar className="h-12 w-12 text-white opacity-50" />
          </div>
        </Card>
      </motion.div>
    </motion.div>
  );
}