import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { About } from '../pages/About';
import { Dashboard } from '../pages/Dashboard';
import { Leads } from '../pages/Leads';
import { Customers } from '../pages/Customers';
import { Inventory } from '../pages/Inventory';
import { Communications } from '../pages/Communications';
import { EmailPage } from '../pages/EmailPage';
import { SMSPage } from '../pages/SMSPage';
import { Reports } from '../pages/Reports';
import { Settings } from '../pages/Settings';
import { AITools } from '../pages/AITools';
import { SalesCoach } from '../pages/SalesCoach';
import { VehicleRecommender } from '../pages/VehicleRecommender';
import { EmailGenerator } from '../pages/EmailGenerator';
import { CustomerInsights } from '../pages/CustomerInsights';
import { MarketAnalyzer } from '../pages/MarketAnalyzer';
import { CompetitorAnalysis } from '../pages/CompetitorAnalysis';
import { PriceOptimizer } from '../pages/PriceOptimizer'; 
import { InventoryOptimizer } from '../pages/InventoryOptimizer';
import { ObjectionHandler } from '../pages/ObjectionHandler';
import { MeetingPrep } from '../pages/MeetingPrep';
import { CallScripts } from '../pages/CallScripts';
import { Login } from '../pages/Login';
import { SignUp } from '../pages/SignUp';
import { Assistant } from '../pages/Assistant';
import { DripCampaigns } from '../pages/DripCampaigns';
import { InterestCampaigns } from '../pages/InterestCampaigns';
import { SmartLists } from '../pages/SmartLists';
import { Messages } from '../pages/Messages';
import { Reviews } from '../pages/Reviews';
import { ReviewRedirect } from '../pages/ReviewRedirect';
import { CarSearch } from '../pages/CarSearch';
import { VINDecoder } from '../pages/VINDecoder';
import { VehicleDatabase } from '../pages/VehicleDatabase';
import { VehicleDescriptionGeneratorPage } from '../pages/VehicleDescriptionGenerator';
import { WebsiteAnalytics } from '../pages/WebsiteAnalytics';
import { Workflows } from '../pages/Workflows';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/about" replace />} />
      <Route path="/about" element={<About />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/leads" element={<Leads />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/communications" element={<Communications />} />
      <Route path="/email" element={<EmailPage />} />
      <Route path="/sms" element={<SMSPage />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/ai-tools/*" element={<AITools />}>
        <Route path="sales-coach" element={<SalesCoach />} />
        <Route path="recommender" element={<VehicleRecommender />} />
        <Route path="email" element={<EmailGenerator />} />
        <Route path="insights" element={<CustomerInsights />} />
        <Route path="market-analyzer" element={<MarketAnalyzer />} />
        <Route path="competitor-analysis" element={<CompetitorAnalysis />} />
        <Route path="price-optimizer" element={<PriceOptimizer />} />
        <Route path="inventory-optimizer" element={<InventoryOptimizer />} />
        <Route path="objection-handler" element={<ObjectionHandler />} />
        <Route path="meeting-prep" element={<MeetingPrep />} />
        <Route path="call-scripts" element={<CallScripts />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/assistant" element={<Assistant />} />
      <Route path="/drip-campaigns" element={<DripCampaigns />} />
      <Route path="/interest-campaigns" element={<InterestCampaigns />} />
      <Route path="/smart-lists" element={<SmartLists />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/r/:trackingId" element={<ReviewRedirect />} />
      <Route path="/car-search" element={<CarSearch />} />
      <Route path="/vin-decoder" element={<VINDecoder />} />
      <Route path="/vehicle-database" element={<VehicleDatabase />} />
      <Route path="/vehicle-description-generator" element={<VehicleDescriptionGeneratorPage />} />
      <Route path="/website-analytics" element={<WebsiteAnalytics />} />
      <Route path="/workflows" element={<Workflows />} />
    </Routes>
  );
}