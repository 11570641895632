import React, { useState } from 'react';
import { UserSettings } from '../components/settings/UserSettings';
import { DealershipSettings } from '../components/settings/DealershipSettings';
import { BusinessHours } from '../components/settings/BusinessHours';
import { EmailSettings } from '../components/settings/EmailSettings';
import { TemplateSettings } from '../components/settings/TemplateSettings';
import { LeadDistributionSettings } from '../components/settings/LeadDistributionSettings';
import { Cog, Users, Clock, Building2, Mail, MessageSquare, Share2 } from 'lucide-react';
import { classNames } from '../utils/classNames';

const tabs = [
  { id: 'users', name: 'Users', icon: Users },
  { id: 'dealership', name: 'Dealership Information', icon: Building2 },
  { id: 'hours', name: 'Business Hours', icon: Clock },
  { id: 'distribution', name: 'Lead Distribution', icon: Share2 },
  { id: 'email', name: 'Email Processing', icon: Mail },
  { id: 'templates', name: 'Message Templates', icon: MessageSquare },
  { id: 'general', name: 'General Settings', icon: Cog },
];

export function Settings() {
  const [currentTab, setCurrentTab] = useState('users');
  const [loading, setLoading] = useState(false);

  // Mock data for demonstration
  const users = [
    { id: '1', name: 'John Smith', email: 'john@example.com', role: 'admin' },
    { id: '2', name: 'Jane Doe', email: 'jane@example.com', role: 'sales' },
  ];

  const templates = [
    { id: '1', name: 'Welcome Email', type: 'email', content: 'Welcome to our dealership!' },
    { id: '2', name: 'Follow Up SMS', type: 'sms', content: 'Thanks for visiting!' },
  ];

  const dealershipSettings = {
    name: 'AutoCRM Dealership',
    address: '123 Main St',
    phone: '(555) 123-4567',
    email: 'info@autocrm.com',
  };

  const businessHours = {
    monday: { open: '09:00', close: '18:00' },
    tuesday: { open: '09:00', close: '18:00' },
    wednesday: { open: '09:00', close: '18:00' },
    thursday: { open: '09:00', close: '18:00' },
    friday: { open: '09:00', close: '18:00' },
    saturday: { open: '10:00', close: '16:00' },
    sunday: { open: '', close: '' },
  };

  const emailSettings = {
    enabled: true,
    server: 'smtp.example.com',
    port: 587,
    username: 'user@example.com',
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 px-6" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setCurrentTab(tab.id)}
                className={classNames(
                  tab.id === currentTab
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
              >
                <tab.icon
                  className={classNames(
                    tab.id === currentTab
                      ? 'text-blue-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'h-5 w-5 mr-2'
                  )}
                  aria-hidden="true"
                />
                {tab.name}
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6">
          {currentTab === 'users' && (
            <UserSettings users={users} />
          )}
          {currentTab === 'dealership' && (
            <DealershipSettings settings={dealershipSettings} />
          )}
          {currentTab === 'hours' && (
            <BusinessHours settings={businessHours} />
          )}
          {currentTab === 'distribution' && (
            <LeadDistributionSettings />
          )}
          {currentTab === 'email' && (
            <EmailSettings settings={emailSettings} />
          )}
          {currentTab === 'templates' && (
            <TemplateSettings templates={templates} />
          )}
          {currentTab === 'general' && (
            <div className="text-sm text-gray-500">General settings coming soon...</div>
          )}
        </div>
      </div>
    </div>
  );
}