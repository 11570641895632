import React, { useState } from 'react';
import { Plus, Users } from 'lucide-react';
import { InterestCampaignList } from '../components/campaigns/InterestCampaignList';
import { InterestCampaignForm } from '../components/campaigns/InterestCampaignForm';
import { InterestSegmentBuilder } from '../components/campaigns/InterestSegmentBuilder';
import type { Campaign } from '../types/campaign';
import { useFirestore } from '../hooks/useFirestore';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function InterestCampaigns() {
  const [showForm, setShowForm] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState<Campaign | null>(null);
  const { data: campaigns, loading } = useFirestore<Campaign>('campaigns', []);

  const handleCreateCampaign = async (campaign: Partial<Campaign>) => {
    try {
      const campaignsRef = collection(db, 'campaigns');
      await addDoc(campaignsRef, {
        ...campaign,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
      setShowForm(false);
    } catch (error) {
      console.error('Error creating campaign:', error);
    }
  };

  const handleUpdateCampaign = async (campaign: Campaign) => {
    try {
      const campaignRef = doc(db, 'campaigns', campaign.id);
      await updateDoc(campaignRef, {
        ...campaign,
        updatedAt: new Date().toISOString()
      });
      setEditingCampaign(null);
    } catch (error) {
      console.error('Error updating campaign:', error);
    }
  };

  const handleToggleStatus = async (campaignId: string) => {
    const campaign = campaigns.find(c => c.id === campaignId);
    if (!campaign) return;

    try {
      const campaignRef = doc(db, 'campaigns', campaignId);
      await updateDoc(campaignRef, {
        status: campaign.status === 'active' ? 'paused' : 'active',
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error toggling campaign status:', error);
    }
  };

  const handleViewMetrics = (campaignId: string) => {
    // TODO: Implement metrics view
    console.log('View metrics for campaign:', campaignId);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Interest-Based Campaigns</h1>
          <p className="mt-2 text-sm text-gray-700">
            Create and manage targeted campaigns based on customer interests and preferences
          </p>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Plus className="-ml-0.5 h-5 w-5" />
          New Campaign
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Users className="h-6 w-6 text-blue-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Active Campaigns
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {campaigns.filter(c => c.status === 'active').length}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Users className="h-6 w-6 text-green-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total Recipients
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {campaigns.reduce((sum, c) => sum + (c.segment?.customerCount || 0), 0).toLocaleString()}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Users className="h-6 w-6 text-purple-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Avg. Conversion Rate
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {(campaigns.reduce((sum, c) => {
                      const rate = (c.metrics?.converted || 0) / (c.metrics?.totalRecipients || 1);
                      return sum + (isNaN(rate) ? 0 : rate);
                    }, 0) / Math.max(campaigns.length, 1) * 100).toFixed(1)}%
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InterestCampaignList
        campaigns={campaigns}
        onToggleStatus={handleToggleStatus}
        onEdit={setEditingCampaign}
        onViewMetrics={handleViewMetrics}
      />

      {(showForm || editingCampaign) && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">
                      {editingCampaign ? 'Edit Campaign' : 'New Interest-Based Campaign'}
                    </h3>
                    <div className="mt-6">
                      <InterestCampaignForm
                        onSubmit={editingCampaign ? handleUpdateCampaign : handleCreateCampaign}
                        onCancel={() => {
                          setShowForm(false);
                          setEditingCampaign(null);
                        }}
                        initialData={editingCampaign || undefined}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}