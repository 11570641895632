import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrendingUp, Users, Car, Clock, Calendar, DollarSign, ArrowRight, ArrowUpRight, BarChart } from 'lucide-react';
import { getLeads } from '../services/leadsService';
import { getCustomers } from '../services/customerService';
import { getInventory } from '../services/inventoryService';
import { ReminderWidget } from '../components/reminders/ReminderWidget';
import { SalesTipCard } from '../components/dashboard/SalesTipCard';
import { SalesFunnel } from '../components/reports/SalesFunnel';
import { LeadsChart } from '../components/reports/LeadsChart';
import { SourcesChart } from '../components/reports/SourcesChart';
import type { Lead } from '../types/lead';
import type { Customer } from '../types/customer';
import { motion } from 'framer-motion';
import { QuickActions } from '../components/dashboard/QuickActions';
import { RecentActivity } from '../components/dashboard/RecentActivity';
import { WeatherWidget } from '../components/dashboard/WeatherWidget';
import { UpcomingEvents } from '../components/dashboard/UpcomingEvents';

export function Dashboard() {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [timeframe, setTimeframe] = useState<'7d' | '30d' | '90d'>('30d');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [leadsData, customersData, inventoryData] = await Promise.all([
          getLeads(),
          getCustomers(),
          getInventory()
        ]);
        
        setLeads(leadsData);
        setCustomers(customersData);
        setInventory(inventoryData);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateStats = () => {
    const totalLeads = leads.length;
    const convertedLeads = leads.filter(lead => lead.status === 'converted').length;
    const conversionRate = totalLeads > 0 ? (convertedLeads / totalLeads) * 100 : 0;
    
    const thisMonth = new Date().getMonth();
    const thisMonthLeads = leads.filter(lead => {
      const leadMonth = new Date(lead.createdAt).getMonth();
      return leadMonth === thisMonth;
    }).length;

    const lastMonthLeads = leads.filter(lead => {
      const leadMonth = new Date(lead.createdAt).getMonth();
      return leadMonth === (thisMonth - 1);
    }).length;

    const monthlyGrowth = lastMonthLeads > 0 
      ? ((thisMonthLeads - lastMonthLeads) / lastMonthLeads) * 100 
      : 0;

    return {
      totalLeads,
      activeCustomers: customers.length,
      conversionRate: conversionRate.toFixed(1),
      monthlyGrowth: monthlyGrowth.toFixed(1),
      revenue: convertedLeads * 35000 // Average sale price
    };
  };

  const funnelData = [
    { stage: 'New Leads', count: leads.length, value: leads.length * 35000, color: 'bg-blue-500' },
    { stage: 'Contacted', count: leads.filter(l => l.status === 'contacted').length, value: leads.filter(l => l.status === 'contacted').length * 35000, color: 'bg-indigo-500' },
    { stage: 'Qualified', count: leads.filter(l => l.status === 'qualified').length, value: leads.filter(l => l.status === 'qualified').length * 35000, color: 'bg-violet-500' },
    { stage: 'Converted', count: leads.filter(l => l.status === 'converted').length, value: leads.filter(l => l.status === 'converted').length * 35000, color: 'bg-purple-500' }
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600"></div>
      </div>
    );
  }

  const stats = calculateStats();

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Welcome Back</h1>
          <p className="mt-1 text-sm text-gray-500">
            Here's what's happening today, {new Date().toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <select
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value as '7d' | '30d' | '90d')}
            className="rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="7d">Last 7 days</option>
            <option value="30d">Last 30 days</option>
            <option value="90d">Last 90 days</option>
          </select>
          <button
            onClick={() => navigate('/reports')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <BarChart className="h-4 w-4 mr-2" />
            View Reports
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          onClick={() => navigate('/leads')}
          className="overflow-hidden rounded-xl bg-gradient-to-br from-blue-50 to-blue-100 px-4 py-5 shadow-soft hover:shadow-md hover:scale-105 transition-all duration-200 cursor-pointer sm:p-6"
        >
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Car className="h-6 w-6 text-brand-600" aria-hidden="true" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">Total Leads</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {stats.totalLeads}
                </dd>
                <p className="mt-1 text-sm text-gray-600">
                  {leads.filter(l => l.status === 'new').length} new today
                </p>
              </dl>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
          onClick={() => navigate('/customers')}
          className="overflow-hidden rounded-xl bg-gradient-to-br from-green-50 to-green-100 px-4 py-5 shadow-soft hover:shadow-md transition-shadow duration-200 cursor-pointer sm:p-6"
        >
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Users className="h-6 w-6 text-brand-600" aria-hidden="true" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">Active Customers</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {stats.activeCustomers}
                </dd>
              </dl>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="overflow-hidden rounded-xl bg-gradient-to-br from-purple-50 to-purple-100 px-4 py-5 shadow-soft sm:p-6"
        >
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <TrendingUp className="h-6 w-6 text-brand-600" aria-hidden="true" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">Conversion Rate</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {stats.conversionRate}%
                </dd>
              </dl>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          className="overflow-hidden rounded-xl bg-gradient-to-br from-amber-50 to-amber-100 px-4 py-5 shadow-soft sm:p-6"
        >
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <DollarSign className="h-6 w-6 text-amber-600" aria-hidden="true" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="truncate text-sm font-medium text-amber-700">Monthly Growth</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {stats.monthlyGrowth}%
                </dd>
              </dl>
            </div>
          </div>
        </motion.div>
      </div>
      
      <div className="grid grid-cols-1 gap-6">
        <QuickActions />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
          className="lg:col-span-3"
        >
          <SalesTipCard />
        </motion.div>

        <div className="lg:col-span-2">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.5 }}
            className="space-y-6"
          >
            <ReminderWidget />
            <RecentActivity />
          </motion.div>
        </div>

        <div className="lg:col-span-1">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.5 }}
            className="space-y-6"
          >
            <WeatherWidget />
            <UpcomingEvents />
          </motion.div>
        </div>
      </div>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.6 }}
        className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-lg p-6 text-white"
      >
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold mb-2">Enhance Your Sales with AI</h2>
            <p className="text-blue-100">Access powerful AI tools for lead scoring, customer insights, and more</p>
          </div>
          <button onClick={() => navigate('/ai-tools')} className="bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
            Try AI Tools
          </button>
        </div>
      </motion.div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Sales Pipeline</h2>
              <p className="text-sm text-gray-500">Current pipeline value: {new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(funnelData.reduce((sum, item) => sum + item.value, 0))}</p>
            </div>
            <button
              onClick={() => navigate('/leads')}
              className="text-sm text-blue-600 hover:text-blue-700 flex items-center"
            >
              View All Leads
              <ArrowRight className="ml-1 h-4 w-4" />
            </button>
          </div>
          <SalesFunnel data={funnelData} totalLeads={leads.length} />
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold text-gray-900">Lead Sources</h2>
            <button
              onClick={() => navigate('/reports')}
              className="text-sm text-blue-600 hover:text-blue-700 flex items-center"
            >
              View Details
              <ArrowRight className="ml-1 h-4 w-4" />
            </button>
          </div>
          <SourcesChart
            data={[
              { source: 'Website', value: 40 },
              { source: 'Referral', value: 25 },
              { source: 'Phone', value: 20 },
              { source: 'Email', value: 15 }
            ]}
          />
        </div>
      </div>
    </motion.div>
  );
}