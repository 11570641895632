import React, { useState } from 'react';
import { Bell, Plus, Gift, Calendar } from 'lucide-react';
import type { Reminder } from '../../types/reminder';
import { ReminderList } from './ReminderList';
import { ReminderModal } from './ReminderModal';
import { sampleReminders } from '../../data/sampleReminders';

export function ReminderWidget() {
  const [reminders, setReminders] = useState<Reminder[]>(sampleReminders);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState<'all' | 'birthday' | 'anniversary'>('all');

  const handleComplete = (id: string) => {
    setReminders(reminders.map(reminder =>
      reminder.id === id ? { ...reminder, status: 'completed' } : reminder
    ));
  };

  const handleDelete = (id: string) => {
    setReminders(reminders.filter(reminder => reminder.id !== id));
  };

  const handleCreate = (reminder: Omit<Reminder, 'id' | 'createdAt' | 'updatedAt'>) => {
    const newReminder: Reminder = {
      ...reminder,
      id: Date.now().toString(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    setReminders([newReminder, ...reminders]);
  };

  const filteredReminders = reminders.filter(reminder => {
    if (filter === 'all') return true;
    return reminder.type === filter;
  });

  return (
    <div className="px-4 py-5 sm:p-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center">
          <Bell className="h-5 w-5 text-gray-400" />
          <h3 className="ml-2 text-lg font-medium text-gray-900">Reminders</h3>
        </div>
        <div className="mt-3 sm:mt-0 sm:flex sm:items-center sm:space-x-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setFilter('all')}
              className={`px-3 py-1 rounded-md text-sm font-medium ${
                filter === 'all' 
                  ? 'bg-blue-100 text-blue-700' 
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Calendar className="inline-block h-4 w-4 mr-1" />
              All
            </button>
            <button
              onClick={() => setFilter('birthday')}
              className={`px-3 py-1 rounded-md text-sm font-medium ${
                filter === 'birthday' 
                  ? 'bg-pink-100 text-pink-700' 
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Gift className="inline-block h-4 w-4 mr-1" />
              Birthdays
            </button>
            <button
              onClick={() => setFilter('anniversary')}
              className={`px-3 py-1 rounded-md text-sm font-medium ${
                filter === 'anniversary' 
                  ? 'bg-purple-100 text-purple-700' 
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Calendar className="inline-block h-4 w-4 mr-1" />
              Anniversaries
            </button>
          </div>
          <button
            onClick={() => setShowModal(true)}
            className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
          >
            <Plus className="-ml-0.5 mr-1.5 h-5 w-5" />
            New Reminder
          </button>
        </div>
      </div>

      <div className="mt-6">
        <ReminderList
          reminders={filteredReminders}
          onComplete={handleComplete}
          onDelete={handleDelete}
        />
      </div>

      {showModal && (
        <ReminderModal
          onClose={() => setShowModal(false)}
          onSubmit={handleCreate}
        />
      )}
    </div>
  );
}