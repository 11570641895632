import { v4 as uuidv4 } from 'uuid';

const TWILIO_ACCOUNT_SID = import.meta.env.VITE_TWILIO_ACCOUNT_SID;
const TWILIO_AUTH_TOKEN = import.meta.env.VITE_TWILIO_AUTH_TOKEN;
const TWILIO_PHONE_NUMBER = import.meta.env.VITE_TWILIO_PHONE_NUMBER;

const MOCK_SMS_HISTORY = [
  {
    id: '1',
    to: TWILIO_PHONE_NUMBER,
    from: TWILIO_PHONE_NUMBER,
    body: 'Thank you for your interest in the 2024 BMW M5. Would you like to schedule a test drive?',
    status: 'delivered',
    direction: 'outbound',
    createdAt: new Date(Date.now() - 1000 * 60 * 30),
    updatedAt: new Date(Date.now() - 1000 * 60 * 30),
    userId: 'system'
  },
  {
    id: '2',
    from: '+19025551234',
    from: '+19025551234',
    body: 'Yes, I would love to! Is tomorrow afternoon available?',
    status: 'received',
    direction: 'inbound',
    createdAt: new Date(Date.now() - 1000 * 60 * 25),
    updatedAt: new Date(Date.now() - 1000 * 60 * 25),
    userId: 'system'
  },
  {
    id: '3',
    to: TWILIO_PHONE_NUMBER,
    from: TWILIO_PHONE_NUMBER,
    body: 'Great! We have slots available at 2 PM or 4 PM. Which works better for you?',
    status: 'delivered',
    direction: 'outbound',
    createdAt: new Date(Date.now() - 1000 * 60 * 20),
    updatedAt: new Date(Date.now() - 1000 * 60 * 20),
    userId: 'system'
  }
];

const TWILIO_API_URL = `https://api.twilio.com/2010-04-01/Accounts/${TWILIO_ACCOUNT_SID}`;

interface SendSMSParams {
  to: string;
  message: string;
  userId?: string;
  mediaUrl?: string;
}

interface SMSResponse {
  success: boolean;
  messageId?: string;
  status?: string;
  error?: string;
}

export async function sendSMS({ to, message, userId, mediaUrl }: SendSMSParams): Promise<SMSResponse> {
  try {
    if (!TWILIO_ACCOUNT_SID || !TWILIO_AUTH_TOKEN || !TWILIO_PHONE_NUMBER) {
      console.warn('Missing Twilio credentials, using mock response');
      return {
        success: true,
        messageId: uuidv4(),
        status: 'delivered'
      };
    }

    // Format phone number to E.164 format
    const formattedTo = to.startsWith('+') ? to : `+1${to.replace(/\D/g, '')}`;
    
    if (!formattedTo.match(/^\+1\d{10}$/)) {
      throw new Error('Invalid phone number format. Please use format: +1 (555) 555-5555');
    }

    const params = new URLSearchParams({
      To: formattedTo,
      From: TWILIO_PHONE_NUMBER,
      Body: message,
      StatusCallback: `${window.location.origin}/api/sms/status?userId=${userId || 'system'}`,
    });

    // Add media URL if provided and valid
    if (mediaUrl && mediaUrl.startsWith('blob:')) {
      params.append('MediaUrl', mediaUrl);
    }

    const response = await fetch(`${TWILIO_API_URL}/Messages.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(`${TWILIO_ACCOUNT_SID}:${TWILIO_AUTH_TOKEN}`),
      },
      body: params
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return {
      success: true,
      messageId: data.sid,
      status: data.status
    };
  } catch (error) {
    console.error('Error sending SMS:', error);
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to send SMS'
    };
  }
}

export async function getSMSHistory(limit = 100): Promise<any[]> {
  try {
    if (!TWILIO_ACCOUNT_SID || !TWILIO_AUTH_TOKEN || !TWILIO_PHONE_NUMBER) {
      console.log('Using mock SMS history - Twilio credentials not configured');
      return Promise.resolve(MOCK_SMS_HISTORY);
    }

    // Get only the most recent messages
    const sentResponse = await fetch(
      `${TWILIO_API_URL}/Messages.json?From=${TWILIO_PHONE_NUMBER}&PageSize=${limit}`, 
      {
        headers: {
          'Authorization': 'Basic ' + btoa(`${TWILIO_ACCOUNT_SID}:${TWILIO_AUTH_TOKEN}`),
        },
      }
    );

    const receivedResponse = await fetch(
      `${TWILIO_API_URL}/Messages.json?To=${TWILIO_PHONE_NUMBER}&PageSize=${limit}`, 
      {
        headers: {
          'Authorization': 'Basic ' + btoa(`${TWILIO_ACCOUNT_SID}:${TWILIO_AUTH_TOKEN}`),
        },
      }
    );

    if (!sentResponse.ok || !receivedResponse.ok) {
      console.warn('Failed to fetch SMS history from Twilio API, using mock data');
      return MOCK_SMS_HISTORY;
    }

    const sentData = await sentResponse.json();
    const receivedData = await receivedResponse.json();

    // Combine and sort only the most recent conversation
    const allMessages = [...sentData.messages, ...receivedData.messages]
      .filter((msg, index, self) => 
        index === self.findIndex(m => m.sid === msg.sid)
      )
      .map((msg: any) => ({
        id: msg.sid,
        to: msg.to,
        from: msg.from,
        body: msg.body,
        status: msg.status,
        direction: msg.direction,
        mediaUrl: msg.media_url || msg.MediaUrl,
        createdAt: new Date(msg.date_created),
        updatedAt: new Date(msg.date_updated),
        userId: msg.subresource_uris?.status?.split('userId=')[1] || null,
      }))
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .slice(0, limit); // Only return the most recent messages

    return allMessages;
  } catch (error) {
    console.warn('Error fetching SMS history, using mock data:', error);
    return MOCK_SMS_HISTORY;
  }
}