import React, { useState } from 'react';
import { X, Save } from 'lucide-react';
import type { DripCampaign } from '../../types/dripCampaign';

interface CampaignModalProps {
  onClose: () => void;
  onSubmit: (campaign: Omit<DripCampaign, 'id'>) => void;
  initialCampaign?: DripCampaign;
}

export function CampaignModal({ onClose, onSubmit, initialCampaign }: CampaignModalProps) {
  const [formData, setFormData] = useState({
    name: initialCampaign?.name || '',
    description: initialCampaign?.description || '',
    triggerEvent: initialCampaign?.triggerEvent || 'lead_created',
    delayDays: initialCampaign?.delayDays || 0,
    steps: initialCampaign?.steps || [],
    isActive: initialCampaign?.isActive || false
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                onClick={onClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                  {initialCampaign ? 'Edit Campaign' : 'New Campaign'}
                </h3>
                <form onSubmit={handleSubmit} className="mt-6 space-y-4">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Campaign Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <textarea
                      id="description"
                      value={formData.description}
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      rows={3}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label htmlFor="triggerEvent" className="block text-sm font-medium text-gray-700">
                      Trigger Event
                    </label>
                    <select
                      id="triggerEvent"
                      value={formData.triggerEvent}
                      onChange={(e) => setFormData({ ...formData, triggerEvent: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    >
                      <option value="lead_created">Lead Created</option>
                      <option value="vehicle_viewed">Vehicle Viewed</option>
                      <option value="test_drive_scheduled">Test Drive Scheduled</option>
                      <option value="quote_requested">Quote Requested</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="delayDays" className="block text-sm font-medium text-gray-700">
                      Initial Delay (days)
                    </label>
                    <input
                      type="number"
                      id="delayDays"
                      value={formData.delayDays}
                      onChange={(e) => setFormData({ ...formData, delayDays: Math.max(0, parseInt(e.target.value) || 0) })}
                      min="0"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="isActive"
                      checked={formData.isActive}
                      onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label htmlFor="isActive" className="ml-2 block text-sm text-gray-900">
                      Active Campaign
                    </label>
                  </div>

                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:w-auto"
                    >
                      <Save className="-ml-0.5 mr-1.5 h-5 w-5" />
                      Save Campaign
                    </button>
                    <button
                      type="button"
                      onClick={onClose}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}