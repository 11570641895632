import React from 'react';
import { MessageSquare } from 'lucide-react';
import { Link } from 'react-router-dom';
import { EmailComposer } from '../components/communications/EmailComposer';

export function EmailPage() {
  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Email</h1>
          <p className="mt-1 text-sm text-gray-500">
            Send and manage email communications
          </p>
        </div>
        <Link
          to="/sms"
          className="inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold rounded-md text-gray-900 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <MessageSquare className="h-5 w-5" />
          Switch to SMS
        </Link>
      </div>

      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow rounded-lg">
          <div className="p-6">
            <EmailComposer />
          </div>
        </div>
      </div>
    </div>
  );
}