import React from 'react';
import { LeadCard } from './LeadCard';
import { LeadScoreCard } from './LeadScoreCard';
import { Lead } from '../../types/lead';

interface LeadGridProps {
  leads: Lead[];
  scores: { [key: string]: number };
  selectedLead: string | null;
  onSelectLead: (id: string) => void;
  onUpdateLead: (id: string, data: Partial<Lead>) => Promise<void>;
  onShowScore?: (leadId: string) => void;
  onShowInsights?: (leadId: string) => void;
  onSendEmail: (lead: Lead, message: string) => void;
  onSendSMS: (lead: Lead, message: string) => void;
}

export function LeadGrid({ 
  leads, 
  scores, 
  selectedLead, 
  onSelectLead, 
  onUpdateLead,
  onShowScore,
  onShowInsights,
  onSendEmail,
  onSendSMS
}: LeadGridProps) {
  // Create a Set of unique lead IDs to prevent duplicates
  const uniqueLeadIds = new Set(leads.map(lead => lead.id));
  const uniqueLeads = Array.from(uniqueLeadIds).map(id => leads.find(lead => lead.id === id)!);
  
  // Sort leads by score
  const sortedLeads = [...uniqueLeads].sort((a, b) => (scores[b.id] || 0) - (scores[a.id] || 0));

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
      {sortedLeads.map((lead) => (
        <div key={`lead-${lead.id}`} className="space-y-4">
          <LeadCard
            lead={lead}
            score={scores[lead.id] || 0}
            isSelected={selectedLead === lead.id}
            onSelect={() => onSelectLead(lead.id)}
            onStatusChange={(status) => onUpdateLead(lead.id, { status })}
            onShowScore={onShowScore ? () => onShowScore(lead.id) : undefined}
            onShowInsights={onShowInsights ? () => onShowInsights(lead.id) : undefined}
            onSendEmail={(message) => onSendEmail(lead, message)}
            onSendSMS={(message) => onSendSMS(lead, message)}
          />
          {selectedLead === lead.id && (
            <LeadScoreCard
              score={{
                score: scores[lead.id] || 0,
                probability: 0.65,
                factors: [
                  {
                    name: "Recent Contact",
                    impact: 15,
                    type: "positive"
                  },
                  {
                    name: "High-Value Vehicle Interest",
                    impact: 20,
                    type: "positive"
                  },
                  {
                    name: "Limited Engagement History",
                    impact: 10,
                    type: "negative"
                  }
                ]
              }}
              isLoading={false}
              lead={lead}
            />
          )}
        </div>
      ))}
    </div>
  );
}