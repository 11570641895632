import React, { useState, useEffect } from 'react';
import { Mail, MessageSquare, ArrowUpRight, Loader2, AlertCircle, Clock, Check, CheckCheck, ArrowLeft } from 'lucide-react';
import { formatDate } from '../../utils/formatDate';
import { getSMSHistory } from '../../utils/twilio';
import { useAuth } from '../../context/AuthContext';
import { useNotifications } from '../../context/NotificationContext';
import { MessageStatus } from './MessageStatus';

interface Communication {
  id: string;
  type: 'sms' | 'email';
  to: string;
  from: string;
  body: string;
  status: string;
  direction: 'inbound' | 'outbound';
  mediaUrl?: string;
  createdAt: Date;
  updatedAt: Date;
}

interface ConversationGroup {
  phoneNumber: string;
  messages: Communication[];
  lastMessage: Communication;
  unreadCount: number;
}

export function CommunicationHistory() {
  const [loading, setLoading] = useState(true);
  const [communications, setCommunications] = useState<Communication[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedConversation, setSelectedConversation] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'list' | 'thread'>('list');
  const { user } = useAuth();
  const { addNotification } = useNotifications();

  useEffect(() => {
    loadCommunications();
  }, []);

  const loadCommunications = async () => {
    try {
      setLoading(true);
      setError(null);

      const smsHistory = await getSMSHistory(100);
      setCommunications(smsHistory);

      addNotification({
        type: 'system',
        title: 'SMS History Loaded',
        message: `Loaded ${smsHistory.length} messages`,
        priority: 'low'
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load communications';
      setError(errorMessage);
      addNotification({
        type: 'system',
        title: 'Error',
        message: errorMessage,
        priority: 'high'
      });
    } finally {
      setLoading(false);
    }
  };

  const groupByConversation = (messages: Communication[]): ConversationGroup[] => {
    const groups = messages.reduce((acc, message) => {
      // Determine the other party's phone number
      const phoneNumber = message.direction === 'inbound' ? message.from : message.to;
      
      if (!acc[phoneNumber]) {
        acc[phoneNumber] = {
          phoneNumber,
          messages: [],
          lastMessage: message,
          unreadCount: 0
        };
      }
      
      acc[phoneNumber].messages.push(message);
      if (message.direction === 'inbound' && message.status === 'received') {
        acc[phoneNumber].unreadCount++;
      }
      
      // Update last message if this one is more recent
      if (new Date(message.createdAt) > new Date(acc[phoneNumber].lastMessage.createdAt)) {
        acc[phoneNumber].lastMessage = message;
      }
      
      return acc;
    }, {} as Record<string, ConversationGroup>);

    return Object.values(groups).sort(
      (a, b) => new Date(b.lastMessage.createdAt).getTime() - new Date(a.lastMessage.createdAt).getTime()
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 text-blue-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex items-start">
          <AlertCircle className="h-5 w-5 text-red-400 mt-0.5" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error loading communications</h3>
            <p className="mt-1 text-sm text-red-700">{error}</p>
            <button
              onClick={loadCommunications}
              className="mt-2 text-sm font-medium text-red-800 hover:text-red-600"
            >
              Try again
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (viewMode === 'thread' && selectedConversation) {
    const conversation = communications.filter(
      comm => comm.to === selectedConversation || comm.from === selectedConversation
    ).sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    return (
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-medium text-gray-900">
                Conversation with {selectedConversation}
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                {conversation.length} messages
              </p>
            </div>
            <button
              onClick={() => {
                setViewMode('list');
                setSelectedConversation(null);
              }}
              className="inline-flex items-center gap-x-1.5 text-sm font-medium text-blue-600 hover:text-blue-500"
            >
              <ArrowLeft className="h-4 w-4" />
              Back to list
            </button>
          </div>
        </div>

        <div className="p-4 space-y-4 max-h-[600px] overflow-y-auto">
          {conversation.map((message) => (
            <div
              key={message.id}
              className={`flex ${
                message.direction === 'inbound' ? 'justify-start' : 'justify-end'
              }`}
            >
              <div
                className={`max-w-[75%] rounded-lg p-4 ${
                  message.direction === 'inbound'
                    ? 'bg-gray-100'
                    : 'bg-blue-100 text-blue-900'
                }`}
              >
                <div className="flex items-center gap-2 mb-1">
                  <MessageSquare className="h-4 w-4" />
                  <span className="text-xs text-gray-500">
                    {formatDate(message.createdAt.toString())}
                  </span>
                  <MessageStatus status={message.status} />
                </div>
                <p className="text-sm whitespace-pre-wrap">{message.body}</p>
                {message.mediaUrl && (
                  <div className="mt-2">
                    {message.mediaUrl.includes('video') ? (
                      <video
                        controls
                        className="rounded-md max-w-full"
                        src={message.mediaUrl}
                      />
                    ) : (
                      <img
                        src={message.mediaUrl}
                        alt="Media attachment"
                        className="rounded-md max-w-full"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const conversationGroups = groupByConversation(communications);

  if (conversationGroups.length === 0) {
    return (
      <div className="bg-white shadow rounded-lg p-6 text-center">
        <MessageSquare className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No messages</h3>
        <p className="mt-1 text-sm text-gray-500">
          Start a conversation to see your message history.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg">
      <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
        <h2 className="text-lg font-medium text-gray-900">Message History</h2>
        <p className="mt-1 text-sm text-gray-500">
          Recent conversations with customers
        </p>
      </div>
      
      <ul role="list" className="divide-y divide-gray-200">
        {conversationGroups.map(({ phoneNumber, messages, lastMessage, unreadCount }) => (
          <li
            key={phoneNumber}
            className="px-4 py-5 sm:px-6 hover:bg-gray-50 cursor-pointer"
            onClick={() => {
              setSelectedConversation(phoneNumber);
              setViewMode('thread');
            }}
          >
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <MessageSquare className="h-5 w-5 text-gray-400" />
                    <span className="text-sm font-medium text-gray-900">
                      {phoneNumber}
                    </span>
                    {unreadCount > 0 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        {unreadCount} new
                      </span>
                    )}
                  </div>
                  <MessageStatus status={lastMessage.status} />
                </div>
                
                <div className="mt-1">
                  <p className="text-sm text-gray-600 line-clamp-2">
                    {lastMessage.body}
                  </p>
                </div>
                
                <div className="mt-2 flex items-center justify-between text-sm text-gray-500">
                  <span>{messages.length} messages</span>
                  <span>Last message: {formatDate(lastMessage.createdAt.toString())}</span>
                </div>
              </div>
              
              <div className="ml-4">
                <ArrowUpRight className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}