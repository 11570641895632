import React from 'react';
import { Card, Title, Text, BarChart } from '@tremor/react';

interface LeadsChartProps {
  data: Array<{
    date: string;
    leads: number;
    conversions: number;
    revenue: number;
  }>;
}

export function LeadsChart({ data }: LeadsChartProps) {
  // Ensure all numeric values are valid numbers
  const validatedData = data.map(item => ({
    ...item,
    leads: Number.isNaN(item.leads) ? 0 : item.leads,
    conversions: Number.isNaN(item.conversions) ? 0 : item.conversions,
    revenue: Number.isNaN(item.revenue) ? 0 : item.revenue
  }));

  return (
    <Card className="bg-gradient-to-br from-blue-50 to-indigo-50 p-6">
      <Title className="text-xl font-semibold text-gray-900">Leads vs Conversions</Title>
      <Text className="mt-2 text-gray-600">
        Monthly comparison of lead generation and successful conversions
      </Text>
      <BarChart
        className="mt-6 h-80"
        data={validatedData}
        index="date"
        categories={["leads", "conversions"]}
        colors={["blue", "emerald"]}
        valueFormatter={(value) => value.toString()}
        showLegend={true}
        stack={false}
        yAxisWidth={48}
        showAnimation={true}
        showGridLines={false}
        showXAxis={true}
        showYAxis={true}
      />
    </Card>
  );
}