import React from 'react';
import { Phone, PhoneOff, Loader2, AlertCircle } from 'lucide-react';
import { useVapiCall } from '../../lib/vapi/hooks';

interface VapiCallButtonProps {
  phoneNumber: string;
  onCallStarted?: () => void;
  onCallEnded?: () => void;
  className?: string;
}

export function VapiCallButton({ 
  phoneNumber, 
  onCallStarted, 
  onCallEnded,
  className = ''
}: VapiCallButtonProps) {
  const { isConnected, isLoading, error, startCall, endCall } = useVapiCall(phoneNumber);

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    
    if (isConnected) {
      await endCall();
      onCallEnded?.();
    } else {
      await startCall();
      onCallStarted?.();
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={isLoading || error !== null}
      className={`inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold ${
        isConnected
          ? 'bg-red-600 text-white hover:bg-red-700'
          : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
      } disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
    >
      {isLoading ? (
        <Loader2 className="h-4 w-4 animate-spin" />
      ) : error ? (
        <AlertCircle className="h-4 w-4" />
      ) : isConnected ? (
        <PhoneOff className="h-4 w-4" />
      ) : (
        <Phone className="h-4 w-4" />
      )}
      <span>
        {isLoading ? 'Connecting...' : 
         error ? 'Call Failed' :
         isConnected ? 'End Call' : 'Call'}
      </span>
    </button>
  );
}