import React, { useState, useEffect } from 'react';
import { Send, X, Video } from 'lucide-react';
import { useTemplates } from '../../hooks/useTemplates';
import { AIMessageGenerator } from './AIMessageGenerator';
import { VideoMessageComposer } from '../video/VideoMessageComposer';

export function EmailComposer() {
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [showVideoComposer, setShowVideoComposer] = useState(false);
  const { templates, selectedTemplate, selectedTemplateData, setSelectedTemplate, fillTemplateVariables } = useTemplates('email');

  // Update content when template is selected
  useEffect(() => {
    if (selectedTemplateData) {
      const variables = {
        customerName: 'Customer',
        salesRepName: 'Sales Team',
        dealershipName: 'AutoCRM',
        vehicleName: 'Selected Vehicle',
        appointmentDate: 'tomorrow',
        appointmentTime: '10:00 AM',
        dealershipAddress: '123 Auto Drive',
        price: '$25,000',
        incentives: 'current promotions',
        financingOptions: 'available financing plans',
        validUntil: '30 days',
        serviceDate: 'next scheduled date',
        serviceType: 'regular maintenance'
      };

      const { content: filledContent, subject: filledSubject } = fillTemplateVariables(selectedTemplateData, variables);
      setContent(filledContent);
      setSubject(filledSubject);
    }
  }, [selectedTemplateData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // TODO: Implement email sending with video
    console.log('Sending email:', { recipient, subject, content, videoUrl });
  };

  const handleMessageGenerated = (generatedMessage: string) => {
    setContent(generatedMessage);
    setSelectedTemplate('');
  };

  const handleVideoReady = (url: string) => {
    setVideoUrl(url);
    setContent(prev => `${prev}\n\nVideo message: ${url}`);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex gap-4">
        <div className="flex-1">
          <label htmlFor="recipient" className="block text-sm font-medium text-gray-700">
            To
          </label>
          <input
            type="email"
            id="recipient"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            required
          />
        </div>

        <div className="w-64">
          <label htmlFor="template" className="block text-sm font-medium text-gray-700">
            Template
          </label>
          <select
            id="template"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
          Subject
        </label>
        <input
          type="text"
          id="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          required
        />
      </div>

      <div className="flex items-center justify-between">
        <AIMessageGenerator
          messageType="email"
          onMessageGenerated={handleMessageGenerated}
          customerName="Customer"
        />
        <button
          type="button"
          onClick={() => setShowVideoComposer(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-200"
        >
          <Video className="-ml-0.5 h-5 w-5" />
          Add Video Message
        </button>
      </div>

      <div>
        <label htmlFor="content" className="block text-sm font-medium text-gray-700">
          Message
        </label>
        <textarea
          id="content"
          rows={6}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          required
        />
      </div>

      {showVideoComposer && (
        <VideoMessageComposer
          onClose={() => setShowVideoComposer(false)}
          onSend={async (videoUrl, message) => {
            setVideoUrl(videoUrl);
            setContent(prev => `${prev}\n\nVideo message: ${videoUrl}\n\n${message}`);
            setShowVideoComposer(false);
          }}
        />
      )}
    </form>
  );
}