import { openai } from './openai';

export interface VehicleDescriptionParams {
  year: number;
  make: string;
  model: string;
  trim?: string;
  features?: string[];
  benefits?: string[];
  dealershipName?: string;
  dealershipLocation?: {
    address: string;
    city: string;
    province: string;
    postalCode: string;
  };
}

export async function generateVehicleDescription(params: VehicleDescriptionParams): Promise<string> {
  try {
    const prompt = buildPrompt(params);
    
    const completion = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: `You are an expert automotive copywriter specializing in creating compelling vehicle descriptions for dealership websites and marketing materials. Your descriptions are professional, engaging, and highlight key features and benefits while maintaining accuracy and credibility.`
        },
        {
          role: "user",
          content: prompt
        }
      ],
      temperature: 0.8,
      max_tokens: 500
    });

    return completion.choices[0]?.message?.content || 
      'Failed to generate description. Please try again.';
  } catch (error) {
    console.error('Error generating vehicle description:', error);
    throw new Error(error instanceof Error ? error.message : 'Failed to generate vehicle description');
  }
}

function buildPrompt({ 
  year, 
  make, 
  model, 
  trim, 
  features, 
  benefits,
  dealershipName,
  dealershipLocation 
}: VehicleDescriptionParams): string {
  let prompt = `Generate a professional and engaging description for a ${year} ${make} ${model}`;
  
  if (trim) {
    prompt += ` ${trim}`;
  }

  prompt += '.\n\n';

  if (features?.length) {
    prompt += `Key features include: ${features.join(', ')}.\n\n`;
  }

  if (benefits?.length) {
    prompt += `Highlight these benefits: ${benefits.join(', ')}.\n\n`;
  }

  if (dealershipName && dealershipLocation) {
    prompt += `This vehicle is available at ${dealershipName} located at ${dealershipLocation.address}, ${dealershipLocation.city}, ${dealershipLocation.province} ${dealershipLocation.postalCode}. Include how this vehicle is well-suited for the local area and climate.\n\n`;
  }

  prompt += `Format the description in a way that's engaging and professional, emphasizing the vehicle's value proposition and unique selling points. Include a strong call-to-action at the end.`;

  return prompt;
}