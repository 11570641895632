import React, { useState, useEffect } from 'react';
import { Cloud, Sun, CloudRain, Wind, Loader2 } from 'lucide-react';

interface WeatherData {
  temp: number;
  condition: string;
  icon: string;
  forecast: Array<{
    date: string;
    temp: number;
    condition: string;
  }>;
}

export function WeatherWidget() {
  const [weather, setWeather] = useState<WeatherData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // For demo purposes, using mock data
    // In production, integrate with a weather API
    setTimeout(() => {
      setWeather({
        temp: 22,
        condition: 'Partly Cloudy',
        icon: 'cloud',
        forecast: [
          { date: 'Tomorrow', temp: 24, condition: 'Sunny' },
          { date: 'Saturday', temp: 21, condition: 'Rain' },
          { date: 'Sunday', temp: 20, condition: 'Cloudy' }
        ]
      });
      setLoading(false);
    }, 1000);
  }, []);

  const getWeatherIcon = (condition: string) => {
    switch (condition.toLowerCase()) {
      case 'sunny': return Sun;
      case 'rain': return CloudRain;
      case 'cloudy': return Cloud;
      default: return Wind;
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-center h-32">
          <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
        </div>
      </div>
    );
  }

  if (error || !weather) {
    return null;
  }

  const WeatherIcon = getWeatherIcon(weather.condition);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Local Weather</h3>
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <WeatherIcon className="h-10 w-10 text-blue-500 mr-4" />
          <div>
            <p className="text-3xl font-bold text-gray-900">{weather.temp}°C</p>
            <p className="text-sm text-gray-500">{weather.condition}</p>
          </div>
        </div>
        <div className="text-right">
          <p className="text-sm text-gray-500">Halifax, NS</p>
          <p className="text-xs text-gray-400">Perfect for test drives</p>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 border-t pt-4">
        {weather.forecast.map((day) => (
          <div key={day.date} className="text-center">
            <p className="text-sm font-medium text-gray-600">{day.date}</p>
            <p className="text-lg font-semibold text-gray-900 my-1">{day.temp}°C</p>
            <p className="text-xs text-gray-500">{day.condition}</p>
          </div>
        ))}
      </div>
    </div>
  );
}