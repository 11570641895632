import React from 'react';
import { Play, Pause, Pencil, BarChart2, Mail, MessageSquare } from 'lucide-react';
import type { DripCampaign } from '../../types/dripCampaign';
import { formatDate } from '../../utils/formatDate';

interface CampaignListProps {
  campaigns: DripCampaign[];
  onEdit: (campaign: DripCampaign) => void;
  onToggleStatus: (campaignId: string) => void;
}

export function CampaignList({ campaigns, onEdit, onToggleStatus }: CampaignListProps) {
  return (
    <div className="bg-white shadow rounded-lg">
      <ul role="list" className="divide-y divide-gray-200">
        {campaigns.map((campaign) => (
          <li key={campaign.id} className="p-6">
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <h3 className="text-lg font-medium text-gray-900">{campaign.name}</h3>
                    <span className={`ml-3 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                      campaign.status === 'active'
                        ? 'bg-green-100 text-green-800'
                        : campaign.status === 'paused'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => onToggleStatus(campaign.id)}
                      className={`rounded-full p-1 ${
                        campaign.status === 'active'
                          ? 'text-green-600 hover:text-green-700'
                          : 'text-gray-400 hover:text-gray-500'
                      }`}
                    >
                      {campaign.status === 'active' ? (
                        <Pause className="h-5 w-5" />
                      ) : (
                        <Play className="h-5 w-5" />
                      )}
                    </button>
                    <button
                      onClick={() => onEdit(campaign)}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <Pencil className="h-5 w-5" />
                    </button>
                    <button className="text-gray-400 hover:text-gray-500">
                      <BarChart2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                <p className="mt-1 text-sm text-gray-500">{campaign.description}</p>
                
                <div className="mt-4 flex items-center space-x-6 text-sm text-gray-500">
                  <div className="flex items-center">
                    <Mail className="mr-1.5 h-4 w-4 text-gray-400" />
                    {campaign.steps.filter(s => s.type === 'email').length} Emails
                  </div>
                  <div className="flex items-center">
                    <MessageSquare className="mr-1.5 h-4 w-4 text-gray-400" />
                    {campaign.steps.filter(s => s.type === 'sms').length} SMS
                  </div>
                  <div>
                    Created {formatDate(campaign.createdAt)}
                  </div>
                </div>

                {campaign.metrics && (
                  <div className="mt-4 grid grid-cols-3 gap-4">
                    <div className="bg-gray-50 rounded-lg p-3">
                      <p className="text-xs font-medium text-gray-500">Total Enrolled</p>
                      <p className="mt-1 text-lg font-semibold text-gray-900">
                        {campaign.metrics.totalEnrolled}
                      </p>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-3">
                      <p className="text-xs font-medium text-gray-500">Completion Rate</p>
                      <p className="mt-1 text-lg font-semibold text-gray-900">
                        {campaign.metrics.completionRate}%
                      </p>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-3">
                      <p className="text-xs font-medium text-gray-500">Avg. Response Time</p>
                      <p className="mt-1 text-lg font-semibold text-gray-900">
                        {campaign.metrics.avgResponseTime} days
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}