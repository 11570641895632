import React from 'react';
import { Card, Title, Text, LineChart } from '@tremor/react';

interface ResponseTimeChartProps {
  data: Array<{
    time: string;
    avgMinutes: number;
    totalResponses: number;
  }>;
}

export function ResponseTimeChart({ data }: ResponseTimeChartProps) {
  return (
    <Card className="bg-gradient-to-br from-amber-50 to-yellow-50 p-6">
      <Title className="text-xl font-semibold text-gray-900">Response Time Analysis</Title>
      <Text className="mt-2 text-gray-600">
        Average response time and total responses by hour
      </Text>
      <LineChart
        className="mt-6 h-96"
        data={data}
        index="time"
        categories={["avgMinutes", "totalResponses"]}
        colors={["amber", "blue"]}
        valueFormatter={(value) => `${value} ${value > 50 ? 'responses' : 'min'}`}
        yAxisWidth={56}
        showAnimation={true}
        showLegend={true}
        showGridLines={true}
        showXAxis={true}
        showYAxis={true}
        curveType="natural"
      />
    </Card>
  );
}