import React, { useState } from 'react';
import { Plus, Pencil, Save, X, Copy, Trash2 } from 'lucide-react';
import { Template } from '../../types/template';
import { defaultTemplates } from '../../data/defaultTemplates';

export function TemplateSettings() {
  const [templates, setTemplates] = useState<Template[]>(defaultTemplates);
  const [editingTemplate, setEditingTemplate] = useState<Template | null>(null);
  const [showNewTemplate, setShowNewTemplate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reviewSettings, setReviewSettings] = useState({
    reviewBaseUrl: 'https://g.page/r/',
    reviewReminder: 'Thank you for choosing our dealership! We value your feedback. Please take a moment to share your experience:',
    reminderDelay: 7 // days after purchase
  });

  const handleSave = (template: Template) => {
    if (editingTemplate) {
      setTemplates(templates.map(t => t.id === template.id ? template : t));
    } else {
      setTemplates([...templates, { ...template, id: Date.now().toString() }]);
    }
    setEditingTemplate(null);
    setShowNewTemplate(false);
  };

  const handleDelete = (templateId: string) => {
    setTemplates(templates.filter(t => t.id !== templateId));
  };

  const handleDuplicate = (template: Template) => {
    const newTemplate = {
      ...template,
      id: Date.now().toString(),
      name: `${template.name} (Copy)`,
      isDefault: false,
    };
    setTemplates([...templates, newTemplate]);
  };

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-lg font-medium text-gray-900">Message Templates</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage your email and SMS templates
          </p>
        </div>
        <button
          onClick={() => setShowNewTemplate(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Plus className="-ml-0.5 h-5 w-5" />
          New Template
        </button>
      </div>

      <div className="mt-4 grid gap-4">
        {templates.map((template) => (
          <div
            key={template.id}
            className="rounded-lg border border-gray-200 bg-white shadow-sm"
          >
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    {template.name}
                    {template.isDefault && (
                      <span className="ml-2 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                        Default
                      </span>
                    )}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {template.type.toUpperCase()} • {template.category.replace('_', ' ')}
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleDuplicate(template)}
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => setEditingTemplate(template)}
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <Pencil className="h-4 w-4" />
                  </button>
                  {!template.isDefault && (
                    <button
                      onClick={() => handleDelete(template.id)}
                      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </div>
              <div className="mt-4">
                {template.type === 'email' && template.subject && (
                  <div className="mb-2">
                    <span className="text-sm font-medium text-gray-700">Subject: </span>
                    <span className="text-sm text-gray-500">{template.subject}</span>
                  </div>
                )}
                <div className="rounded-md bg-gray-50 p-4">
                  <pre className="text-sm text-gray-700 whitespace-pre-wrap font-sans">
                    {template.content}
                  </pre>
                </div>
                <div className="mt-2">
                  <span className="text-xs text-gray-500">
                    Variables: {template.variables.map(v => `{{${v}}}`).join(', ')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Review Settings</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Review Link Base URL
            </label>
            <input
              type="text"
              value={reviewSettings.reviewBaseUrl}
              onChange={(e) => setReviewSettings({ ...reviewSettings, reviewBaseUrl: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Review Reminder Message
            </label>
            <textarea
              value={reviewSettings.reviewReminder}
              onChange={(e) => setReviewSettings({ ...reviewSettings, reviewReminder: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Reminder Delay (days after purchase)
            </label>
            <input
              type="number"
              value={reviewSettings.reminderDelay}
              onChange={(e) => setReviewSettings({ ...reviewSettings, reminderDelay: parseInt(e.target.value) })}
              min={1}
              max={30}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      {(editingTemplate || showNewTemplate) && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    onClick={() => {
                      setEditingTemplate(null);
                      setShowNewTemplate(false);
                    }}
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData(e.currentTarget);
                    const template: Template = {
                      id: editingTemplate?.id || '',
                      name: formData.get('name') as string,
                      type: formData.get('type') as 'email' | 'sms',
                      subject: formData.get('subject') as string,
                      content: formData.get('content') as string,
                      category: formData.get('category') as Template['category'],
                      variables: (formData.get('variables') as string).split(',').map(v => v.trim()),
                      isDefault: editingTemplate?.isDefault || false,
                    };
                    handleSave(template);
                  }}
                  className="space-y-4"
                >
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Template Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      defaultValue={editingTemplate?.name}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                        Type
                      </label>
                      <select
                        name="type"
                        id="type"
                        defaultValue={editingTemplate?.type || 'email'}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="email">Email</option>
                        <option value="sms">SMS</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                        Category
                      </label>
                      <select
                        name="category"
                        id="category"
                        defaultValue={editingTemplate?.category || 'custom'}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="welcome">Welcome</option>
                        <option value="follow_up">Follow Up</option>
                        <option value="thank_you">Thank You</option>
                        <option value="service">Service</option>
                        <option value="custom">Custom</option>
                      </select>
                    </div>
                  </div>

                  {editingTemplate?.type !== 'sms' && (
                    <div>
                      <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                        Subject
                      </label>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        defaultValue={editingTemplate?.subject}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  )}

                  <div>
                    <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                      Content
                    </label>
                    <textarea
                      name="content"
                      id="content"
                      rows={6}
                      defaultValue={editingTemplate?.content}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label htmlFor="variables" className="block text-sm font-medium text-gray-700">
                      Variables (comma-separated)
                    </label>
                    <input
                      type="text"
                      name="variables"
                      id="variables"
                      defaultValue={editingTemplate?.variables.join(', ')}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                    >
                      <Save className="-ml-0.5 mr-1.5 h-5 w-5" />
                      Save Template
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setEditingTemplate(null);
                        setShowNewTemplate(false);
                      }}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}