import React, { useState } from 'react';
import { Plus, X, Save, ArrowRight } from 'lucide-react';
import type { Workflow, WorkflowStage, WorkflowTrigger, WorkflowAction } from '../../types/workflow';

interface WorkflowBuilderProps {
  onSave: (workflow: Omit<Workflow, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  initialData?: Workflow;
  onCancel: () => void;
}

const ACTION_TYPES = [
  { value: 'send_email', label: 'Send Email' },
  { value: 'send_sms', label: 'Send SMS' },
  { value: 'create_task', label: 'Create Task' },
  { value: 'update_lead', label: 'Update Lead' },
  { value: 'update_customer', label: 'Update Customer' },
  { value: 'create_reminder', label: 'Create Reminder' },
  { value: 'assign_to_user', label: 'Assign to User' },
  { value: 'add_to_list', label: 'Add to List' }
];

const TRIGGER_EVENTS = [
  { value: 'lead.created', label: 'Lead Created' },
  { value: 'lead.updated', label: 'Lead Updated' },
  { value: 'lead.qualified', label: 'Lead Qualified' },
  { value: 'lead.converted', label: 'Lead Converted' },
  { value: 'customer.created', label: 'Customer Created' },
  { value: 'customer.updated', label: 'Customer Updated' },
  { value: 'message.sent', label: 'Message Sent' },
  { value: 'review.submitted', label: 'Review Submitted' }
];

export function WorkflowBuilder({ onSave, initialData, onCancel }: WorkflowBuilderProps) {
  const [name, setName] = useState(initialData?.name || '');
  const [description, setDescription] = useState(initialData?.description || '');
  const [stages, setStages] = useState<WorkflowStage[]>(initialData?.stages || []);
  const [triggers, setTriggers] = useState<WorkflowTrigger[]>(initialData?.triggers || []);
  const [active, setActive] = useState(initialData?.active || false);
  const [error, setError] = useState<string | null>(null);

  const addStage = () => {
    setStages([
      ...stages,
      {
        name: `Stage ${stages.length + 1}`,
        conditions: [],
        actions: []
      }
    ]);
  };

  const removeStage = (index: number) => {
    setStages(stages.filter((_, i) => i !== index));
  };

  const addAction = (stageIndex: number) => {
    const newStages = [...stages];
    newStages[stageIndex].actions.push({
      type: 'send_email',
      config: {}
    });
    setStages(newStages);
  };

  const removeAction = (stageIndex: number, actionIndex: number) => {
    const newStages = [...stages];
    newStages[stageIndex].actions.splice(actionIndex, 1);
    setStages(newStages);
  };

  const addTrigger = () => {
    setTriggers([
      ...triggers,
      {
        event: 'lead.created',
        conditions: []
      }
    ]);
  };

  const removeTrigger = (index: number) => {
    setTriggers(triggers.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!name.trim()) {
      setError('Name is required');
      return;
    }

    if (stages.length === 0) {
      setError('At least one stage is required');
      return;
    }

    if (triggers.length === 0) {
      setError('At least one trigger is required');
      return;
    }

    try {
      await onSave({
        name,
        description,
        stages,
        triggers,
        active
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save workflow');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 gap-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Workflow Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            required
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-gray-700">Triggers</h3>
            <button
              type="button"
              onClick={addTrigger}
              className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
            >
              <Plus className="h-4 w-4 mr-1" />
              Add Trigger
            </button>
          </div>

          <div className="mt-2 space-y-3">
            {triggers.map((trigger, index) => (
              <div key={index} className="flex items-center gap-2 bg-gray-50 p-3 rounded-md">
                <select
                  value={trigger.event}
                  onChange={(e) => {
                    const newTriggers = [...triggers];
                    newTriggers[index].event = e.target.value as any;
                    setTriggers(newTriggers);
                  }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  {TRIGGER_EVENTS.map(event => (
                    <option key={event.value} value={event.value}>
                      {event.label}
                    </option>
                  ))}
                </select>

                <button
                  type="button"
                  onClick={() => removeTrigger(index)}
                  className="p-1 text-gray-400 hover:text-gray-500"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-gray-700">Stages</h3>
            <button
              type="button"
              onClick={addStage}
              className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
            >
              <Plus className="h-4 w-4 mr-1" />
              Add Stage
            </button>
          </div>

          <div className="mt-2 space-y-4">
            {stages.map((stage, stageIndex) => (
              <div key={stageIndex} className="border border-gray-200 rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                  <input
                    type="text"
                    value={stage.name}
                    onChange={(e) => {
                      const newStages = [...stages];
                      newStages[stageIndex].name = e.target.value;
                      setStages(newStages);
                    }}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder="Stage Name"
                  />
                  <button
                    type="button"
                    onClick={() => removeStage(stageIndex)}
                    className="ml-2 p-1 text-gray-400 hover:text-gray-500"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>

                <div className="space-y-3">
                  {stage.actions.map((action, actionIndex) => (
                    <div key={actionIndex} className="flex items-center gap-2 bg-gray-50 p-3 rounded-md">
                      <select
                        value={action.type}
                        onChange={(e) => {
                          const newStages = [...stages];
                          newStages[stageIndex].actions[actionIndex].type = e.target.value as any;
                          setStages(newStages);
                        }}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        {ACTION_TYPES.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>

                      <button
                        type="button"
                        onClick={() => removeAction(stageIndex, actionIndex)}
                        className="p-1 text-gray-400 hover:text-gray-500"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  ))}

                  <button
                    type="button"
                    onClick={() => addAction(stageIndex)}
                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    <Plus className="h-4 w-4 mr-1" />
                    Add Action
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="active"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <label htmlFor="active" className="ml-2 block text-sm text-gray-900">
            Activate workflow
          </label>
        </div>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
        >
          <Save className="h-4 w-4 mr-1.5" />
          Save Workflow
        </button>
      </div>
    </form>
  );
}