import { v4 as uuidv4 } from 'uuid';

const CLOUDINARY_UPLOAD_PRESET = 'ml_default';
const CLOUDINARY_CLOUD_NAME = 'dgyd6kfbt';
const CLOUDINARY_API_KEY = '367441534672126';

interface UploadResponse {
  secure_url: string;
  public_id: string;
}

export async function uploadVideo(videoBlob: Blob): Promise<UploadResponse> {
  const formData = new FormData();
  formData.append('file', videoBlob);
  formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
  formData.append('resource_type', 'video');
  formData.append('public_id', `video_${uuidv4()}`);

  const uploadEndpoint = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/video/upload`;

  try {
    const response = await fetch(uploadEndpoint, {
      method: 'POST',
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status: ${response.status}`);
    }

    const data = await response.json();
    return {
      secure_url: data.secure_url,
      public_id: data.public_id
    };
  } catch (error) {
    console.error('Error uploading to Cloudinary:', error);
    throw new Error('Failed to upload video');
  }
}