import React, { useState } from 'react';
import { Users, Loader2, Brain } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

export function CustomerInsights() {
  const { generateResponse, isLoading } = useAI();
  const [customerData, setCustomerData] = useState({
    name: '',
    email: '',
    phone: '',
    purchaseHistory: '',
    interests: '',
    budget: '',
    timeline: '',
    preferences: ''
  });
  const [insights, setInsights] = useState<string | null>(null);

  const handleAnalyze = async () => {
    const prompt = `Analyze this customer profile and provide insights:
      Name: ${customerData.name}
      Purchase History: ${customerData.purchaseHistory}
      Interests: ${customerData.interests}
      Budget: ${customerData.budget}
      Timeline: ${customerData.timeline}
      Preferences: ${customerData.preferences}
      
      Provide detailed insights about:
      1. Customer preferences and motivations
      2. Recommended vehicles and features
      3. Best approach for engagement
      4. Potential objections and how to handle them`;

    const response = await generateResponse(prompt);
    if (response) {
      setInsights(response);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Users className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Customer Insights</h2>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Customer Name</label>
              <input
                type="text"
                value={customerData.name}
                onChange={(e) => setCustomerData({ ...customerData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Purchase History</label>
              <input
                type="text"
                value={customerData.purchaseHistory}
                onChange={(e) => setCustomerData({ ...customerData, purchaseHistory: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Previous vehicles, service history..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Interests</label>
              <input
                type="text"
                value={customerData.interests}
                onChange={(e) => setCustomerData({ ...customerData, interests: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Vehicle types, features..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Budget</label>
              <input
                type="text"
                value={customerData.budget}
                onChange={(e) => setCustomerData({ ...customerData, budget: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Budget range..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Timeline</label>
              <input
                type="text"
                value={customerData.timeline}
                onChange={(e) => setCustomerData({ ...customerData, timeline: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Purchase timeline..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Preferences</label>
              <input
                type="text"
                value={customerData.preferences}
                onChange={(e) => setCustomerData({ ...customerData, preferences: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Specific preferences..."
              />
            </div>
          </div>

          <button
            onClick={handleAnalyze}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Analyzing...
              </>
            ) : (
              <>
                <Brain className="-ml-1 mr-2 h-5 w-5" />
                Generate Insights
              </>
            )}
          </button>
        </div>

        {insights && (
          <div className="mt-6 bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Customer Insights</h3>
            <div className="prose prose-blue max-w-none">
              {insights.split('\n').map((line, index) => (
                <p key={index} className="text-gray-600">{line}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}