import AgoraRTC, {
  IAgoraRTCClient,
  ILocalAudioTrack,
  ILocalVideoTrack,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ClientConfig,
} from 'agora-rtc-sdk-ng';
import { v4 as uuidv4 } from 'uuid';

const APP_ID = 'd42d5ac2f9234523b97b2f9f34aaff77';

const clientConfig: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
  role: 'host'
};

class AgoraVideoService {
  private client: IAgoraRTCClient;
  private localAudioTrack: ILocalAudioTrack | null = null;
  private localVideoTrack: ILocalVideoTrack | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private recordedChunks: Blob[] = [];
  private recordingUrl: string | null = null;

  constructor() {
    this.client = AgoraRTC.createClient(clientConfig);
    this.setupEventHandlers();
  }

  private setupEventHandlers() {
    this.client.on('connection-state-change', (curState, prevState, reason) => {
      console.log('Connection state changed:', prevState, '->', curState, 'reason:', reason);
    });

    this.client.on('error', (err) => {
      console.error('Agora client error:', err);
      throw new Error(err.message || 'Agora client error');
    });
  }

  async initializeDevices(): Promise<{
    audioTrack: IMicrophoneAudioTrack;
    videoTrack: ICameraVideoTrack;
  }> {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('Your browser does not support media devices');
      }

      // Request permissions first with constraints
      await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        },
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          frameRate: { ideal: 30 }
        }
      });

      const [audioTrack, videoTrack] = await Promise.all([
        AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: 'music_standard',
          AEC: true,
          ANS: true,
          AGC: true
        }),
        AgoraRTC.createCameraVideoTrack({
          encoderConfig: {
            width: 1280,
            height: 720,
            frameRate: 30,
            bitrateMin: 600,
            bitrateMax: 2000
          },
          optimizationMode: 'detail'
        })
      ]);

      this.localAudioTrack = audioTrack;
      this.localVideoTrack = videoTrack;

      return { audioTrack, videoTrack };
    } catch (error) {
      console.error('Error initializing devices:', error);
      if (error instanceof Error && error.name === 'NotAllowedError') {
        throw new Error('Permission denied: Please enable camera and microphone access');
      }
      throw new Error('Failed to initialize recording devices');
    }
  }

  async startRecording(videoElement: HTMLVideoElement): Promise<void> {
    if (!this.localVideoTrack || !this.localAudioTrack) {
      throw new Error('Media tracks not initialized');
    }

    try {
      const mediaStream = new MediaStream([
        this.localVideoTrack.getMediaStreamTrack(),
        this.localAudioTrack.getMediaStreamTrack()
      ]);

      videoElement.srcObject = mediaStream;
      await videoElement.play();

      // Initialize MediaRecorder with WebM format
      this.recordedChunks = [];
      this.mediaRecorder = new MediaRecorder(mediaStream, {
        mimeType: 'video/webm;codecs=vp8,opus'
      });

      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };

      // Start recording
      this.mediaRecorder.start(1000); // Collect data every second
    } catch (error) {
      await this.cleanup();
      throw error;
    }
  }

  async stopRecording(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.mediaRecorder) {
        reject(new Error('No active recording'));
        return;
      }

      this.mediaRecorder.onstop = async () => {
        try {
          const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
          const videoUrl = URL.createObjectURL(blob);
          this.recordingUrl = videoUrl;
          await this.cleanup();
          resolve(videoUrl);
        } catch (error) {
          reject(error);
        }
      };

      this.mediaRecorder.stop();
    });
  }

  private async cleanup(): Promise<void> {
    try {
      if (this.localAudioTrack) {
        this.localAudioTrack.close();
        this.localAudioTrack = null;
      }
      
      if (this.localVideoTrack) {
        this.localVideoTrack.close();
        this.localVideoTrack = null;
      }

      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
      }
      this.mediaRecorder = null;
      this.recordedChunks = [];
      
      await this.client.leave();
    } catch (error) {
      console.error('Error during cleanup:', error);
    }
  }

  async releaseDevices(): Promise<void> {
    if (this.recordingUrl) {
      URL.revokeObjectURL(this.recordingUrl);
      this.recordingUrl = null;
    }
    await this.cleanup();
  }
}

export const agoraService = new AgoraVideoService();