import React, { useState } from 'react';
import { BarChart2, Loader2 } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

export function InventoryOptimizer() {
  const { generateResponse, isLoading } = useAI();
  const [analysis, setAnalysis] = useState<string | null>(null);
  const [inventoryData, setInventoryData] = useState({
    currentStock: '',
    salesVelocity: '',
    seasonality: 'summer',
    marketTrends: '',
    demographics: '',
    competitorInventory: '',
    historicalData: '',
    supplyChainFactors: '',
    profitMargins: '',
    holdingCosts: ''
  });

  const handleAnalyze = async () => {
    const prompt = `Analyze inventory optimization for:
      Current Stock: ${inventoryData.currentStock}
      Sales Velocity: ${inventoryData.salesVelocity}
      Seasonality: ${inventoryData.seasonality}
      Market Trends: ${inventoryData.marketTrends}
      Demographics: ${inventoryData.demographics}
      Competitor Inventory: ${inventoryData.competitorInventory}
      Historical Data: ${inventoryData.historicalData}
      Supply Chain Factors: ${inventoryData.supplyChainFactors}
      Profit Margins: ${inventoryData.profitMargins}
      Holding Costs: ${inventoryData.holdingCosts}

      Provide recommendations for:
      1. Optimal inventory levels
      2. Stock mix suggestions
      3. Timing for acquisitions
      4. Risk mitigation strategies
      5. Supply chain optimization
      6. Profit margin optimization
      7. Holding cost reduction strategies
      8. Competitive positioning
      9. Seasonal adjustment recommendations
      10. Long-term inventory planning`;

    const response = await generateResponse(prompt);
    if (response) {
      setAnalysis(response);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <BarChart2 className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Inventory Optimizer</h2>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Current Stock Levels</label>
              <textarea
                value={inventoryData.currentStock}
                onChange={(e) => setInventoryData({ ...inventoryData, currentStock: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="List current inventory levels..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Sales Velocity</label>
              <textarea
                value={inventoryData.salesVelocity}
                onChange={(e) => setInventoryData({ ...inventoryData, salesVelocity: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Recent sales trends..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Seasonality</label>
              <select
                value={inventoryData.seasonality}
                onChange={(e) => setInventoryData({ ...inventoryData, seasonality: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="spring">Spring</option>
                <option value="summer">Summer</option>
                <option value="fall">Fall</option>
                <option value="winter">Winter</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Market Trends</label>
              <textarea
                value={inventoryData.marketTrends}
                onChange={(e) => setInventoryData({ ...inventoryData, marketTrends: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Current market trends..."
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Demographics</label>
              <textarea
                value={inventoryData.demographics}
                onChange={(e) => setInventoryData({ ...inventoryData, demographics: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Local demographic information..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Competitor Inventory</label>
              <textarea
                value={inventoryData.competitorInventory}
                onChange={(e) => setInventoryData({ ...inventoryData, competitorInventory: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Competitor inventory levels..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Supply Chain Factors</label>
              <textarea
                value={inventoryData.supplyChainFactors}
                onChange={(e) => setInventoryData({ ...inventoryData, supplyChainFactors: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Supply chain considerations..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Profit Margins</label>
              <textarea
                value={inventoryData.profitMargins}
                onChange={(e) => setInventoryData({ ...inventoryData, profitMargins: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Current profit margins..."
              />
            </div>
          </div>
          <button
            onClick={handleAnalyze}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Analyzing...
              </>
            ) : (
              <>
                <BarChart2 className="-ml-1 mr-2 h-5 w-5" />
                Generate Recommendations
              </>
            )}
          </button>
        </div>

        {analysis && (
          <div className="mt-6 bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Optimization Recommendations</h3>
            <div className="prose prose-blue max-w-none">
              {analysis.split('\n').map((line, index) => (
                <p key={index} className="text-gray-600">{line}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}