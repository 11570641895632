import React, { createContext, useContext, useState } from 'react';
import { openai, generateAIResponse } from '../../utils/openai';

interface AIContextType {
  isLoading: boolean;
  error: string | null;
  generateResponse: (prompt: string) => Promise<string | null>;
  clearError: () => void;
}

const AIContext = createContext<AIContextType | undefined>(undefined);

export function AIProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateResponse = async (prompt: string) => {
    try {
      setIsLoading(true);
      setError(null);
      return await generateAIResponse(prompt);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const clearError = () => setError(null);

  return (
    <AIContext.Provider value={{ isLoading, error, generateResponse, clearError }}>
      {children}
    </AIContext.Provider>
  );
}

export function useAI() {
  const context = useContext(AIContext);
  if (context === undefined) {
    throw new Error('useAI must be used within an AIProvider');
  }
  return context;
}