import React, { useState, useEffect } from 'react';
import { Phone, Mail, Clock, CheckCircle2, XCircle, SkipForward } from 'lucide-react';
import type { SmartListMember } from '../../types/smartList';
import type { Lead } from '../../types/lead';
import { formatDate } from '../../utils/formatDate';
import { getSmartListMembers, updateMemberStatus, updateLastContacted } from '../../services/smartListService';

interface SmartListMembersProps {
  listId: string;
  onSendSMS: (lead: Lead) => void;
  onSendEmail: (lead: Lead) => void;
}

export function SmartListMembers({ listId, onSendSMS, onSendEmail }: SmartListMembersProps) {
  const [members, setMembers] = useState<SmartListMember[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadMembers();
  }, [listId]);

  const loadMembers = async () => {
    try {
      setLoading(true);
      const data = await getSmartListMembers(listId);
      setMembers(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load members');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (memberId: string, status: SmartListMember['status']) => {
    try {
      await updateMemberStatus(memberId, status);
      await loadMembers();
    } catch (err) {
      console.error('Error updating member status:', err);
    }
  };

  const handleContact = async (memberId: string, lead: Lead, type: 'sms' | 'email') => {
    try {
      if (type === 'sms') {
        onSendSMS(lead);
      } else {
        onSendEmail(lead);
      }
      await updateLastContacted(memberId);
      await loadMembers();
    } catch (err) {
      console.error('Error updating contact status:', err);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {members.map((member) => (
        <div
          key={member.id}
          className="bg-white rounded-lg shadow-sm border border-gray-200 p-4"
        >
          <div className="flex items-center justify-between">
            <div>
              <h4 className="text-lg font-medium text-gray-900">
                {member.lead.name}
              </h4>
              <div className="mt-1 flex items-center space-x-4 text-sm text-gray-500">
                <div className="flex items-center">
                  <Mail className="h-4 w-4 mr-1" />
                  {member.lead.email}
                </div>
                <div className="flex items-center">
                  <Phone className="h-4 w-4 mr-1" />
                  {member.lead.phone}
                </div>
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  Last Contact: {formatDate(member.lastContacted || member.lead.lastContact)}
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <button
                onClick={() => handleContact(member.id, member.lead, 'sms')}
                className="p-1 text-gray-400 hover:text-gray-500"
                title="Send SMS"
              >
                <Phone className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleContact(member.id, member.lead, 'email')}
                className="p-1 text-gray-400 hover:text-gray-500"
                title="Send Email"
              >
                <Mail className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleStatusUpdate(member.id, 'completed')}
                className="p-1 text-gray-400 hover:text-green-500"
                title="Mark as completed"
              >
                <CheckCircle2 className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleStatusUpdate(member.id, 'skipped')}
                className="p-1 text-gray-400 hover:text-yellow-500"
                title="Skip"
              >
                <SkipForward className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleStatusUpdate(member.id, 'active')}
                className="p-1 text-gray-400 hover:text-red-500"
                title="Reset status"
              >
                <XCircle className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      ))}

      {members.length === 0 && (
        <div className="text-center py-12">
          <Users className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No members</h3>
          <p className="mt-1 text-sm text-gray-500">
            This list currently has no matching leads
          </p>
        </div>
      )}
    </div>
  );
}