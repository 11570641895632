import React from 'react';
import { Play, Pause, BarChart2, Users, Calendar, Tag } from 'lucide-react';
import type { Campaign } from '../../types/campaign';
import { formatDate } from '../../utils/formatDate';

interface InterestCampaignListProps {
  campaigns: Campaign[];
  onToggleStatus: (id: string) => void;
  onEdit: (campaign: Campaign) => void;
  onViewMetrics: (id: string) => void;
}

export function InterestCampaignList({
  campaigns,
  onToggleStatus,
  onEdit,
  onViewMetrics
}: InterestCampaignListProps) {
  return (
    <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
      {campaigns.map((campaign) => (
        <div key={campaign.id} className="p-6">
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <div className="flex items-center">
                <h3 className="text-lg font-medium text-gray-900">{campaign.name}</h3>
                <span className={`ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  campaign.status === 'active'
                    ? 'bg-green-100 text-green-800'
                    : campaign.status === 'paused'
                    ? 'bg-yellow-100 text-yellow-800'
                    : campaign.status === 'completed'
                    ? 'bg-gray-100 text-gray-800'
                    : 'bg-blue-100 text-blue-800'
                }`}>
                  {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                </span>
              </div>
              <p className="mt-1 text-sm text-gray-500">{campaign.description}</p>
            </div>
            <div className="flex items-center space-x-3">
              <button
                onClick={() => onToggleStatus(campaign.id)}
                className={`rounded-full p-1 ${
                  campaign.status === 'active'
                    ? 'text-green-600 hover:text-green-700'
                    : 'text-gray-400 hover:text-gray-500'
                }`}
              >
                {campaign.status === 'active' ? (
                  <Pause className="h-5 w-5" />
                ) : (
                  <Play className="h-5 w-5" />
                )}
              </button>
              <button
                onClick={() => onViewMetrics(campaign.id)}
                className="text-gray-400 hover:text-gray-500"
              >
                <BarChart2 className="h-5 w-5" />
              </button>
              <button
                onClick={() => onEdit(campaign)}
                className="text-gray-400 hover:text-gray-500"
              >
                <Users className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
            <div className="flex items-center text-sm text-gray-500">
              <Tag className="mr-1.5 h-4 w-4 text-gray-400" />
              {campaign.segment.interests.join(', ')}
            </div>
            <div className="flex items-center text-sm text-gray-500">
              <Users className="mr-1.5 h-4 w-4 text-gray-400" />
              {campaign.segment.customerCount.toLocaleString()} recipients
            </div>
            <div className="flex items-center text-sm text-gray-500">
              <Calendar className="mr-1.5 h-4 w-4 text-gray-400" />
              {campaign.schedule.type === 'immediate'
                ? 'Sends immediately'
                : campaign.schedule.type === 'recurring'
                ? `Sends ${campaign.schedule.frequency}`
                : `Scheduled for ${formatDate(campaign.schedule.startDate || '')}`}
            </div>
          </div>

          {campaign.metrics && (
            <div className="mt-4 grid grid-cols-4 gap-4">
              <div className="bg-gray-50 rounded-lg p-3">
                <p className="text-xs font-medium text-gray-500">Delivered</p>
                <p className="mt-1 text-lg font-semibold text-gray-900">
                  {((campaign.metrics.delivered / campaign.metrics.totalRecipients) * 100).toFixed(1)}%
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-3">
                <p className="text-xs font-medium text-gray-500">Open Rate</p>
                <p className="mt-1 text-lg font-semibold text-gray-900">
                  {((campaign.metrics.opened / campaign.metrics.delivered) * 100).toFixed(1)}%
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-3">
                <p className="text-xs font-medium text-gray-500">Click Rate</p>
                <p className="mt-1 text-lg font-semibold text-gray-900">
                  {((campaign.metrics.clicked / campaign.metrics.opened) * 100).toFixed(1)}%
                </p>
              </div>
              <div className="bg-gray-50 rounded-lg p-3">
                <p className="text-xs font-medium text-gray-500">Conversions</p>
                <p className="mt-1 text-lg font-semibold text-gray-900">
                  {campaign.metrics.converted}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}