import React, { useState, useEffect } from 'react';
import { Mail, MessageSquare, Loader2, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { SMSChat } from '../components/communications/SMSChat';
import { SMSComposer } from '../components/communications/SMSComposer';
import { getSMSHistory } from '../utils/twilio';
import { formatDate } from '../utils/formatDate';

interface Message {
  id: string;
  to: string;
  from: string;
  body: string;
  status: string;
  direction: 'inbound' | 'outbound';
  mediaUrl?: string;
  createdAt: Date;
}

interface Conversation {
  phoneNumber: string;
  messages: Message[];
  lastMessage: Message;
  unreadCount: number;
}

export function SMSPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const [showComposer, setShowComposer] = useState(false);

  useEffect(() => {
    loadMessages();
  }, []);

  const loadMessages = async () => {
    try {
      setLoading(true);
      const history = await getSMSHistory(1); // Only get the most recent conversation
      const grouped = groupMessagesByPhoneNumber(history);
      setConversations(grouped);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load messages');
    } finally {
      setLoading(false);
    }
  };

  const groupMessagesByPhoneNumber = (messages: Message[]): Conversation[] => {
    const groups = messages.reduce((acc, message) => {
      const phoneNumber = message.direction === 'inbound' ? message.from : message.to;
      
      if (!acc[phoneNumber]) {
        acc[phoneNumber] = {
          phoneNumber,
          messages: [],
          lastMessage: message,
          unreadCount: 0
        };
      }
      
      acc[phoneNumber].messages.push(message);
      if (message.direction === 'inbound' && message.status === 'received') {
        acc[phoneNumber].unreadCount++;
      }
      
      if (new Date(message.createdAt) > new Date(acc[phoneNumber].lastMessage.createdAt)) {
        acc[phoneNumber].lastMessage = message;
      }
      
      return acc;
    }, {} as Record<string, Conversation>);

    return Object.values(groups).sort(
      (a, b) => new Date(b.lastMessage.createdAt).getTime() - new Date(a.lastMessage.createdAt).getTime()
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 text-blue-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex items-start">
          <AlertCircle className="h-5 w-5 text-red-400 mt-0.5" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error loading messages</h3>
            <p className="mt-1 text-sm text-red-700">{error}</p>
            <button
              onClick={loadMessages}
              className="mt-2 text-sm font-medium text-red-800 hover:text-red-600"
            >
              Try again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">SMS Messages</h1>
          <p className="mt-1 text-sm text-gray-500">
            Send and manage text message communications
          </p>
        </div>
        <div className="mt-4 sm:mt-0 flex space-x-3">
          <button
            onClick={() => setShowComposer(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
          >
            <MessageSquare className="h-5 w-5" />
            New Message
          </button>
          <Link
            to="/email"
            className="inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold rounded-md text-gray-900 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <Mail className="h-5 w-5" />
            Switch to Email
          </Link>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        {showComposer ? (
          <div className="p-6">
            <SMSComposer 
              onClose={() => setShowComposer(false)} 
              onSent={loadMessages} 
            />
          </div>
        ) : selectedNumber ? (
          <SMSChat
            messages={conversations.find(c => c.phoneNumber === selectedNumber)?.messages || []}
            phoneNumber={selectedNumber}
            onBack={() => setSelectedNumber(null)}
            onMessageSent={loadMessages}
          />
        ) : (
          <div>
            <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">Conversations</h2>
              <p className="mt-1 text-sm text-gray-500">
                Your most recent SMS conversation
              </p>
            </div>
            
            <div className="divide-y divide-gray-200">
              {conversations.map((conversation) => (
                <div
                  key={conversation.phoneNumber}
                  onClick={() => setSelectedNumber(conversation.phoneNumber)}
                  className="p-4 hover:bg-gray-50 cursor-pointer"
                >
                  <div className="flex items-center gap-4">
                    <MessageSquare className="h-5 w-5 text-gray-400" />
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">
                          {conversation.phoneNumber}
                        </p>
                        {conversation.unreadCount > 0 && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            {conversation.unreadCount} new
                          </span>
                        )}
                      </div>
                      <p className="mt-1 text-sm text-gray-500 truncate">
                        {conversation.lastMessage.body}
                      </p>
                      <p className="mt-1 text-xs text-gray-400">
                        {formatDate(conversation.lastMessage.createdAt.toString())}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}