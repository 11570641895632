import React from 'react';
import { Card, Title, Text, BarChart } from '@tremor/react';

interface PerformanceChartProps {
  data: Array<{
    name: string;
    leads: number;
    conversions: number;
    revenue: number;
  }>;
}

export function PerformanceChart({ data }: PerformanceChartProps) {
  const valueFormatter = (number: number) => 
    number > 1000 
      ? `$${(number/1000).toFixed(1)}K`
      : number.toString();

  return (
    <Card className="bg-gradient-to-br from-emerald-50 to-teal-50 p-6">
      <Title className="text-xl font-semibold text-gray-900">Sales Representative Performance</Title>
      <Text className="mt-2 text-gray-600">
        Comparison of leads, conversions, and revenue by sales rep
      </Text>
      <BarChart
        className="mt-6 h-96"
        data={data}
        index="name"
        categories={["leads", "conversions", "revenue"]}
        colors={["blue", "emerald", "amber"]}
        valueFormatter={valueFormatter}
        stack={false}
        yAxisWidth={56}
        showAnimation={true}
        showLegend={true}
        showGridLines={false}
        showXAxis={true}
        showYAxis={true}
      />
    </Card>
  );
}