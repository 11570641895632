import { supabase } from '../lib/supabase';
import type { Customer } from '../types/customer';

export async function getCustomers(): Promise<Customer[]> {
  try {
    const { data: customers, error } = await supabase
      .from('customers')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    
    return customers.map(customer => ({
      id: customer.id,
      firstName: customer.first_name,
      lastName: customer.last_name,
      email: customer.email,
      phone: customer.phone,
      address: customer.address,
      purchaseDate: customer.purchase_date,
      birthday: customer.birthday,
      anniversary: customer.anniversary,
      status: customer.status,
      preferred_contact: customer.preferred_contact,
      vehicle: customer.vehicle,
      salesRepId: customer.sales_rep_id,
      notes: customer.notes,
      lastContact: customer.last_contact,
      reviewStatus: customer.review_status
    }));
  } catch (error) {
    console.error('Error fetching customers:', error);
    throw error;
  }
}

export async function createCustomer(customer: Omit<Customer, 'id'>): Promise<Customer> {
  try {
    const { data, error } = await supabase
      .from('customers')
      .insert([{
        first_name: customer.firstName,
        last_name: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        address: customer.address,
        purchase_date: customer.purchaseDate,
        birthday: customer.birthday,
        anniversary: customer.anniversary,
        status: customer.status,
        preferred_contact: customer.preferred_contact,
        vehicle: customer.vehicle,
        sales_rep_id: customer.salesRepId,
        notes: customer.notes,
        last_contact: customer.lastContact,
        review_status: customer.reviewStatus
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
}