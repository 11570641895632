import React from 'react';

interface LeadFiltersProps {
  selectedStatus: string;
  selectedSource: string;
  onStatusChange: (status: string) => void;
  onSourceChange: (source: string) => void;
}

export function LeadFilters({
  selectedStatus,
  selectedSource,
  onStatusChange,
  onSourceChange,
}: LeadFiltersProps) {
  const statuses = ['all', 'new', 'contacted', 'qualified', 'converted', 'lost'];
  const sources = ['all', 'website', 'referral', 'phone', 'email', 'other'];

  return (
    <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
      <div>
        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
          Status
        </label>
        <select
          id="status"
          value={selectedStatus}
          onChange={(e) => onStatusChange(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
        >
          {statuses.map((status) => (
            <option key={status} value={status}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="source" className="block text-sm font-medium text-gray-700">
          Source
        </label>
        <select
          id="source"
          value={selectedSource}
          onChange={(e) => onSourceChange(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
        >
          {sources.map((source) => (
            <option key={source} value={source}>
              {source.charAt(0).toUpperCase() + source.slice(1)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}