import React from 'react';
import { Activity, Phone, Mail, Car, Calendar, MessageSquare } from 'lucide-react';
import { formatDate } from '../../utils/formatDate';

interface ActivityItem {
  id: string;
  type: 'call' | 'email' | 'lead' | 'appointment' | 'message';
  description: string;
  timestamp: string;
  user?: string;
}

const getActivityIcon = (type: ActivityItem['type']) => {
  switch (type) {
    case 'call': return Phone;
    case 'email': return Mail;
    case 'lead': return Car;
    case 'appointment': return Calendar;
    case 'message': return MessageSquare;
    default: return Activity;
  }
};

export function RecentActivity() {
  // This would typically come from your backend
  const activities: ActivityItem[] = [
    {
      id: '1',
      type: 'lead',
      description: 'New lead: Sarah Johnson interested in BMW X5',
      timestamp: new Date().toISOString(),
      user: 'John Smith'
    },
    {
      id: '2',
      type: 'call',
      description: 'Outbound call to Michael Chen (5 minutes)',
      timestamp: new Date(Date.now() - 30 * 60000).toISOString(),
      user: 'Emma Davis'
    },
    {
      id: '3',
      type: 'appointment',
      description: 'Test drive scheduled with David Wilson',
      timestamp: new Date(Date.now() - 60 * 60000).toISOString(),
      user: 'John Smith'
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-gray-900">Recent Activity</h3>
        <span className="text-sm text-gray-500">Today</span>
      </div>
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {activities.map((activity, activityIdx) => {
            const Icon = getActivityIcon(activity.type);
            return (
              <li key={activity.id}>
                <div className="relative pb-8">
                  {activityIdx !== activities.length - 1 ? (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center ring-8 ring-white">
                        <Icon className="h-5 w-5 text-blue-500" />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-600">{activity.description}</p>
                        {activity.user && (
                          <p className="text-xs text-gray-500 mt-0.5">by {activity.user}</p>
                        )}
                      </div>
                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                        {formatDate(activity.timestamp)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}