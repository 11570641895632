import React from 'react';
import { 
  Car, 
  Users, 
  MessageSquare, 
  BarChart2, 
  Zap,
  CheckCircle2,
  Mail
} from 'lucide-react';

export function About() {
  const features = [ 
    {
      name: 'Lead Management',
      description: 'Track and manage leads throughout their journey. Never miss an opportunity with our intelligent lead scoring and automated follow-ups.',
      icon: Users,
      href: '#',
    },
    {
      name: 'Inventory Tracking',
      description: 'Keep track of your entire inventory in real-time. Sync with your DMS and get instant updates on vehicle status and location.',
      icon: Car,
      href: '#',
    },
    {
      name: 'Communication Hub',
      description: 'Centralize all your customer communications. Email, SMS, and call tracking in one place for seamless customer engagement.',
      icon: MessageSquare,
      href: '#',
    },
    {
      name: 'Analytics & Reporting',
      description: 'Make data-driven decisions with comprehensive analytics. Track performance, identify trends, and optimize your sales process.',
      icon: BarChart2,
      href: '#',
    },
    {
      name: 'Automation Tools',
      description: 'Automate repetitive tasks and focus on what matters. From follow-ups to inventory updates, let our system handle the routine work.',
      icon: Zap,
      href: '#',
    },
    {
      name: 'Customer Insights',
      description: 'Get deep insights into customer behavior and preferences. Build lasting relationships with data-driven personalization.',
      icon: CheckCircle2,
      href: '#',
    }
  ];

  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-50 to-white">
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Experience the Future of Automotive CRM
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              AutoCRM.pro - The leading automotive CRM platform that transforms dealership operations with AI-powered lead management, inventory tracking, and customer engagement tools.
            </p>
            <div className="mt-4 flex items-center text-sm text-gray-500">
              <Mail className="h-4 w-4 mr-2" />
              <a href="mailto:shawn@shawnryder.com" className="hover:text-indigo-600">
                shawn@shawnryder.com
              </a>
            </div>
          </div>
          <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <img
                src="https://images.unsplash.com/photo-1552519507-da3b142c6e3d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80"
                alt="Modern car dealership showroom"
                width={2432}
                height={1442}
                className="w-[76rem] rounded-xl shadow-2xl ring-1 ring-white/10 transition-shadow duration-300"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-600">Everything you need</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Powerful features for modern dealerships
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our comprehensive suite of tools helps you manage leads, track inventory, and grow your business efficiently.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}