import React, { useState } from 'react';
import { VideoMessageButton } from './VideoMessageButton';
import { VideoRecorder } from './VideoRecorder';
import { VideoLibrary } from './VideoLibrary';
import { VideoTemplateSelector } from './VideoTemplateSelector';
import { X, Send, Loader2 } from 'lucide-react';
import { uploadVideo } from '../../utils/cloudinary';

interface VideoMessageComposerProps {
  onClose: () => void;
  onSend: (videoUrl: string, message: string) => Promise<void>;
  recipientName?: string;
}

export function VideoMessageComposer({ onClose, onSend, recipientName }: VideoMessageComposerProps) {
  const [mode, setMode] = useState<'record' | 'upload' | 'library' | null>(null);
  const [message, setMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleVideoReady = async (videoBlob: Blob) => {
    try {
      setIsUploading(true);
      setError(null);
      const { secure_url } = await uploadVideo(videoBlob);
      await onSend(secure_url, message);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to upload video');
    } finally {
      setIsUploading(false);
    }
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 100 * 1024 * 1024) { // 100MB limit
      setError('Video file size must be less than 100MB');
      return;
    }

    handleVideoReady(file);
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                onClick={onClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                  Record Video Message
                  {recipientName && <span className="text-sm text-gray-500 ml-2">for {recipientName}</span>}
                </h3>

                {!mode && (
                  <div className="mt-4">
                    <VideoMessageButton
                      onRecord={() => setMode('record')}
                      onUpload={() => setMode('upload')}
                    />
                    <button
                      onClick={() => setMode('library')}
                      className="mt-2 w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md"
                    >
                      Select from library
                    </button>
                  </div>
                )}

                {mode === 'record' && (
                  <div className="mt-4">
                    <VideoRecorder
                      onVideoReady={handleVideoReady}
                      maxDuration={120}
                    />
                  </div>
                )}

                {mode === 'upload' && (
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Upload Video
                    </label>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleUpload}
                      className="mt-1 block w-full text-sm text-gray-500
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-md file:border-0
                        file:text-sm file:font-semibold
                        file:bg-blue-50 file:text-blue-700
                        hover:file:bg-blue-100"
                    />
                  </div>
                )}

                {mode === 'library' && (
                  <div className="mt-4">
                    <VideoLibrary
                      videos={[]}
                      onSelect={() => {}}
                      onDelete={() => {}}
                      onShare={() => {}}
                    />
                  </div>
                )}

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={3}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder="Add a personal message..."
                  />
                </div>

                {error && (
                  <div className="mt-4 rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">Error</h3>
                        <div className="mt-2 text-sm text-red-700">
                          <p>{error}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    disabled={isUploading}
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:w-auto"
                  >
                    {isUploading ? (
                      <>
                        <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                        Uploading...
                      </>
                    ) : (
                      <>
                        <Send className="-ml-1 mr-2 h-5 w-5" />
                        Send Message
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}