import React, { useState } from 'react';
import { Calendar, Clock, User, Car, FileText, Loader2 } from 'lucide-react';
import { useAI } from '../ai/AIContext';

interface Meeting {
  id: string;
  customerName: string;
  date: string;
  time: string;
  type: 'test_drive' | 'sales_meeting' | 'delivery';
  vehicle: string;
  notes: string;
}

export function MeetingPrep() {
  const { generateResponse, isLoading } = useAI();
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null);
  const [insights, setInsights] = useState<string | null>(null);

  const meetings: Meeting[] = [
    {
      id: '1',
      customerName: 'Michael Chen',
      date: '2024-03-20',
      time: '14:00',
      type: 'test_drive',
      vehicle: '2024 BMW M5 Competition',
      notes: 'Interested in performance features, coming from Mercedes-AMG'
    },
    {
      id: '2',
      customerName: 'Sarah Johnson',
      date: '2024-03-20',
      time: '15:30',
      type: 'sales_meeting',
      vehicle: '2024 Range Rover Sport',
      notes: 'Looking for luxury SUV, family of 4'
    }
  ];

  const generateInsights = async (meeting: Meeting) => {
    const prompt = `Generate meeting preparation insights for:
    Customer: ${meeting.customerName}
    Meeting Type: ${meeting.type}
    Vehicle: ${meeting.vehicle}
    Notes: ${meeting.notes}
    
    Include:
    1. Key talking points
    2. Potential concerns to address
    3. Relevant vehicle features to highlight
    4. Suggested questions to ask
    5. Closing strategies`;

    const response = await generateResponse(prompt);
    if (response) {
      setInsights(response);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-6">Meeting Preparation</h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-1">
          <h3 className="text-sm font-medium text-gray-700 mb-4">Today's Meetings</h3>
          <div className="space-y-4">
            {meetings.map(meeting => (
              <div
                key={meeting.id}
                onClick={() => {
                  setSelectedMeeting(meeting);
                  generateInsights(meeting);
                }}
                className={`p-4 rounded-lg border cursor-pointer transition-colors ${
                  selectedMeeting?.id === meeting.id
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-200 hover:border-blue-300'
                }`}
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    <User className="h-4 w-4 text-gray-400 mr-2" />
                    <span className="font-medium text-gray-900">{meeting.customerName}</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-1" />
                    {meeting.time}
                  </div>
                </div>
                <div className="flex items-center text-sm text-gray-600 mb-2">
                  <Car className="h-4 w-4 mr-2" />
                  {meeting.vehicle}
                </div>
                <div className="flex items-center text-sm text-gray-500">
                  <Calendar className="h-4 w-4 mr-2" />
                  {new Date(meeting.date).toLocaleDateString()}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="lg:col-span-2">
          {selectedMeeting ? (
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">
                  Meeting with {selectedMeeting.customerName}
                </h3>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  {selectedMeeting.type.replace('_', ' ')}
                </span>
              </div>

              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center mb-4">
                  <FileText className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="font-medium text-gray-700">Meeting Notes</span>
                </div>
                <p className="text-gray-600">{selectedMeeting.notes}</p>
              </div>

              {isLoading ? (
                <div className="flex items-center justify-center py-12">
                  <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
                </div>
              ) : insights ? (
                <div className="prose prose-blue max-w-none">
                  {insights.split('\n').map((line, index) => (
                    <p key={index} className="text-gray-600">{line}</p>
                  ))}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">Select a meeting to view preparation insights</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}