import React, { useState } from 'react';
import { Car, Loader2 } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

export function VehicleRecommender() {
  const { generateResponse, isLoading } = useAI();
  const [preferences, setPreferences] = useState({
    budget: '',
    usage: '',
    passengers: '',
    fuelType: '',
    features: [] as string[]
  });
  const [recommendations, setRecommendations] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const prompt = `Recommend vehicles based on these preferences:
      Budget: ${preferences.budget}
      Primary Usage: ${preferences.usage}
      Passengers: ${preferences.passengers}
      Fuel Type: ${preferences.fuelType}
      Must-Have Features: ${preferences.features.join(', ')}
      
      Provide 3 specific vehicle recommendations with reasoning.`;

    const response = await generateResponse(prompt);
    if (response) {
      setRecommendations(response);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Car className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Vehicle Recommender</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Budget</label>
              <input
                type="text"
                value={preferences.budget}
                onChange={(e) => setPreferences({ ...preferences, budget: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g. $50,000"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Primary Usage</label>
              <select
                value={preferences.usage}
                onChange={(e) => setPreferences({ ...preferences, usage: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Select usage</option>
                <option value="commuting">Daily Commuting</option>
                <option value="family">Family Vehicle</option>
                <option value="luxury">Luxury/Comfort</option>
                <option value="performance">Performance/Sport</option>
                <option value="outdoor">Outdoor/Adventure</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Number of Passengers</label>
              <input
                type="number"
                value={preferences.passengers}
                onChange={(e) => setPreferences({ ...preferences, passengers: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                min="1"
                max="9"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Fuel Type</label>
              <select
                value={preferences.fuelType}
                onChange={(e) => setPreferences({ ...preferences, fuelType: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Select fuel type</option>
                <option value="gasoline">Gasoline</option>
                <option value="diesel">Diesel</option>
                <option value="electric">Electric</option>
                <option value="hybrid">Hybrid</option>
              </select>
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Generating Recommendations...
              </>
            ) : (
              'Get Recommendations'
            )}
          </button>
        </form>

        {recommendations && (
          <div className="mt-6 bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Recommendations</h3>
            <div className="prose prose-blue max-w-none">
              {recommendations.split('\n').map((line, index) => (
                <p key={index} className="text-gray-600">{line}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}