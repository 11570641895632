import React from 'react';
import { Car, DollarSign, TrendingUp, MapPin } from 'lucide-react';
import { formatCurrency } from '../../utils/formatters';

interface InventoryStatsProps {
  stats: {
    totalVehicles: number;
    availableVehicles: number;
    totalValue: number;
    avgPrice: number;
    avgMileage: number;
  };
}

export function InventoryStats({ stats }: InventoryStatsProps) {
  const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-CA').format(Math.round(num));
  };

  const statItems = [
    {
      name: 'Total Vehicles',
      value: stats.totalVehicles.toString(),
      icon: Car,
      color: 'blue',
      change: '+12%'
    },
    {
      name: 'Available Vehicles',
      value: stats.availableVehicles.toString(),
      icon: Car,
      color: 'green',
      change: '+5%'
    },
    {
      name: 'Average Price',
      value: formatCurrency(stats.avgPrice),
      icon: DollarSign,
      color: 'indigo',
      change: '+8%'
    },
    {
      name: 'Average Mileage',
      value: `${formatNumber(stats.avgMileage)} km`,
      icon: MapPin,
      color: 'amber',
      change: '-2%'
    }
  ];

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {statItems.map((item) => (
        <div
          key={item.name}
          className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
        >
          <div className="absolute top-0 right-0 -mt-3 -mr-3 h-24 w-24 rounded-full bg-blue-500 opacity-10"></div>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <item.icon className={`h-6 w-6 text-${item.color}-600`} />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">
                  {item.name}
                </dt>
                <dd className="mt-1 text-lg font-semibold text-gray-900">
                  {item.value}
                </dd>
                <dd className={`mt-1 text-sm ${
                  item.change.startsWith('+') ? 'text-green-600' : 'text-red-600'
                }`}>
                  {item.change} from last month
                </dd>
              </dl>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}