import { useState, useEffect } from 'react';
import { defaultTemplates, getTemplatesByType } from '../data/defaultTemplates';
import type { Template } from '../types/template';

export function useTemplates(type?: 'email' | 'sms') {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedTemplateData, setSelectedTemplateData] = useState<Template | null>(null);

  useEffect(() => {
    // If type is provided, filter templates by type
    const availableTemplates = type ? getTemplatesByType(type) : defaultTemplates;
    setTemplates(availableTemplates);
  }, [type]);

  useEffect(() => {
    if (selectedTemplate) {
      const template = templates.find(t => t.id === selectedTemplate);
      setSelectedTemplateData(template || null);
    } else {
      setSelectedTemplateData(null);
    }
  }, [selectedTemplate, templates]);

  const fillTemplateVariables = (template: Template, variables: Record<string, string>) => {
    let content = template.content;
    let subject = template.subject || '';

    Object.entries(variables).forEach(([key, value]) => {
      const regex = new RegExp(`{{${key}}}`, 'g');
      content = content.replace(regex, value);
      subject = subject.replace(regex, value);
    });

    return { content, subject };
  };

  return {
    templates,
    selectedTemplate,
    selectedTemplateData,
    setSelectedTemplate,
    fillTemplateVariables
  };
}