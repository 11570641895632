import React from 'react';
import { Gift, Calendar } from 'lucide-react';
import { formatDate } from '../../utils/formatDate';

interface Event {
  id: string;
  type: 'birthday' | 'anniversary' | 'service';
  customerName: string;
  date: string;
  details?: string;
}

export function UpcomingEvents() {
  // This would typically come from your backend
  const events: Event[] = [
    {
      id: '1',
      type: 'birthday',
      customerName: 'Michael Chen',
      date: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toISOString(),
      details: 'Purchased BMW M5 in 2023'
    },
    {
      id: '2',
      type: 'anniversary',
      customerName: 'Sarah Johnson',
      date: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toISOString(),
      details: '1 year since Range Rover purchase'
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Upcoming Events</h3>
      <div className="space-y-4">
        {events.map((event) => (
          <div
            key={event.id}
            className="flex items-start space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
          >
            {event.type === 'birthday' ? (
              <Gift className="h-5 w-5 text-pink-500 mt-0.5" />
            ) : (
              <Calendar className="h-5 w-5 text-purple-500 mt-0.5" />
            )}
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900">
                {event.customerName}
              </p>
              <p className="text-sm text-gray-500">
                {event.type === 'birthday' ? 'Birthday' : 'Purchase Anniversary'}
              </p>
              {event.details && (
                <p className="text-xs text-gray-400 mt-1">{event.details}</p>
              )}
            </div>
            <div className="text-sm text-gray-500">
              {formatDate(event.date)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}