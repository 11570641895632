import React from 'react';
import { useLocation } from 'react-router-dom';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
}

export function SEO({ 
  title = 'AutoCRM | Intelligent Automotive Dealership Management Platform',
  description = 'AutoCRM.pro - The leading automotive CRM platform that transforms dealership operations with AI-powered lead management, inventory tracking, and customer engagement tools.',
  image = 'https://images.unsplash.com/photo-1580273916550-e323be2ae537?auto=format&fit=crop&w=1200&q=80',
  type = 'website'
}: SEOProps) {
  const location = useLocation();
  const canonicalUrl = `https://autocrm.pro${location.pathname}`;

  return (
    <>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonicalUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />

      {/* Additional SEO Meta Tags */}
      <meta name="keywords" content="automotive crm, dealership software, car sales management, lead management, inventory tracking, automotive sales, dealer crm, auto dealer software, AI-powered CRM, automotive intelligence platform" />
      <meta name="author" content="AutoCRM" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </>
  );
}