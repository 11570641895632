import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Plus, 
  Calendar, 
  MessageSquare, 
  Car,
  FileText,
  Phone
} from 'lucide-react';

export function QuickActions() {
  const navigate = useNavigate();

  const actions = [
    { icon: Plus, label: 'Add Lead', path: '/leads', color: 'text-blue-600' },
    { icon: Calendar, label: 'Schedule Test Drive', path: '/calendar', color: 'text-green-600' },
    { icon: MessageSquare, label: 'Send Message', path: '/communications', color: 'text-purple-600' },
    { icon: Car, label: 'Add Vehicle', path: '/inventory', color: 'text-amber-600' },
    { icon: FileText, label: 'Create Quote', path: '/quotes', color: 'text-indigo-600' },
    { icon: Phone, label: 'Log Call', path: '/calls', color: 'text-rose-600' }
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Quick Actions</h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4">
        {actions.map((action) => (
          <button
            key={action.label}
            onClick={() => navigate(action.path)}
            className="flex flex-col items-center justify-center p-4 rounded-lg hover:bg-gray-50 transition-colors"
          >
            <action.icon className={`h-6 w-6 ${action.color} mb-2`} />
            <span className="text-sm text-gray-600">{action.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
}