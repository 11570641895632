import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import { AuthProvider } from './context/AuthContext';
import { AppRoutes } from './routes';
import { NotificationProvider } from './context/NotificationContext';
import { AIProvider } from './components/ai/AIContext';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationProvider>
          <AIProvider>
            <Layout>
              <AppRoutes />
            </Layout>
          </AIProvider>
        </NotificationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;