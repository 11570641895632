import React, { useState } from 'react';
import { Star, ThumbsUp, MessageSquare, Search, Filter } from 'lucide-react';
import { mockReviews } from '../data/mockReviews';
import { formatDate } from '../utils/formatDate';

export function Reviews() {
  const [filter, setFilter] = useState<'all' | '5' | '4' | '3' | '2' | '1'>('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [reviewStats, setReviewStats] = useState({
    totalRequests: 150,
    clickedLinks: 89,
    submittedReviews: mockReviews.length,
    averageRating: mockReviews.reduce((acc, review) => acc + review.rating, 0) / mockReviews.length
  });


  const filteredReviews = mockReviews.filter(review => {
    const matchesFilter = filter === 'all' || review.rating.toString() === filter;
    const matchesSearch = !searchQuery || 
      review.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
      review.author.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  const averageRating = mockReviews.reduce((acc, review) => acc + review.rating, 0) / mockReviews.length;
  const ratingCounts = mockReviews.reduce((acc, review) => {
    acc[review.rating] = (acc[review.rating] || 0) + 1;
    return acc;
  }, {} as Record<number, number>);

  const renderStars = (rating: number) => {
    return Array.from({ length: 5 }).map((_, index) => (
      <Star
        key={index}
        className={`h-5 w-5 ${
          index < rating ? 'text-yellow-400 fill-current' : 'text-gray-300'
        }`}
      />
    ));
  };

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Customer Reviews</h1>
          <p className="mt-2 text-sm text-gray-600">
            See what our customers are saying about us
          </p>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          <div className="bg-blue-50 rounded-lg p-4">
            <p className="text-sm font-medium text-blue-600">Review Requests</p>
            <p className="mt-2 text-3xl font-bold text-blue-900">{reviewStats.totalRequests}</p>
          </div>
          <div className="bg-green-50 rounded-lg p-4">
            <p className="text-sm font-medium text-green-600">Link Clicks</p>
            <p className="mt-2 text-3xl font-bold text-green-900">{reviewStats.clickedLinks}</p>
          </div>
          <div className="bg-purple-50 rounded-lg p-4">
            <p className="text-sm font-medium text-purple-600">Submitted Reviews</p>
            <p className="mt-2 text-3xl font-bold text-purple-900">{reviewStats.submittedReviews}</p>
          </div>
          <div className="bg-yellow-50 rounded-lg p-4">
            <p className="text-sm font-medium text-yellow-600">Average Rating</p>
            <p className="mt-2 text-3xl font-bold text-yellow-900">{reviewStats.averageRating.toFixed(1)}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-1">
            <div className="text-center md:text-left">
              <div className="text-5xl font-bold text-gray-900">{averageRating.toFixed(1)}</div>
              <div className="flex items-center justify-center md:justify-start mt-2">
                {renderStars(Math.round(averageRating))}
              </div>
              <div className="text-sm text-gray-500 mt-1">
                Based on {mockReviews.length} reviews
              </div>
            </div>

            <div className="mt-6 space-y-2">
              {[5, 4, 3, 2, 1].map(rating => (
                <button
                  key={rating}
                  onClick={() => setFilter(rating.toString() as any)}
                  className={`w-full flex items-center space-x-2 p-2 rounded-lg transition-colors ${
                    filter === rating.toString()
                      ? 'bg-blue-50 text-blue-700'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <div className="flex items-center flex-1">
                    <div className="flex items-center">
                      {renderStars(rating)}
                    </div>
                    <div className="ml-2">({ratingCounts[rating] || 0})</div>
                  </div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-yellow-400"
                      style={{
                        width: `${((ratingCounts[rating] || 0) / mockReviews.length) * 100}%`
                      }}
                    />
                  </div>
                </button>
              ))}
            </div>
          </div>

          <div className="md:col-span-2">
            <div className="mb-4">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search reviews..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>

            <div className="space-y-6">
              {filteredReviews.map(review => (
                <div key={review.id} className="border-b border-gray-200 pb-6 last:border-0">
                  <div className="flex items-start">
                    {review.avatarUrl && (
                      <img
                        src={review.avatarUrl}
                        alt={review.author}
                        className="h-10 w-10 rounded-full mr-4"
                      />
                    )}
                    <div className="flex-1">
                      <div className="flex items-center">
                        <h3 className="font-medium text-gray-900">{review.author}</h3>
                        <span className="mx-2 text-gray-300">•</span>
                        <span className="text-sm text-gray-500">
                          {formatDate(review.date)}
                        </span>
                      </div>
                      <div className="flex items-center mt-1">
                        {renderStars(review.rating)}
                      </div>
                      <p className="mt-2 text-gray-700">{review.content}</p>
                      
                      <div className="flex items-center mt-3 space-x-4">
                        <button className="flex items-center text-sm text-gray-500 hover:text-gray-700">
                          <ThumbsUp className="h-4 w-4 mr-1" />
                          Helpful ({review.helpful})
                        </button>
                        <button className="flex items-center text-sm text-gray-500 hover:text-gray-700">
                          <MessageSquare className="h-4 w-4 mr-1" />
                          Share
                        </button>
                      </div>

                      {review.dealerResponse && (
                        <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                          <div className="flex items-center mb-2">
                            <MessageSquare className="h-4 w-4 text-blue-600 mr-2" />
                            <span className="font-medium text-gray-900">Response from dealership</span>
                          </div>
                          <p className="text-gray-700">{review.dealerResponse}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}