import React, { useState } from 'react';
import { Car, Loader2, Clipboard, Check } from 'lucide-react';
import { generateVehicleDescription, VehicleDescriptionParams } from '../../utils/vehicleDescription';

interface VehicleDescriptionGeneratorProps {
  initialData?: Partial<VehicleDescriptionParams>;
  onGenerated?: (description: string) => void;
}

export function VehicleDescriptionGenerator({ 
  initialData,
  onGenerated 
}: VehicleDescriptionGeneratorProps) {
  const [params, setParams] = useState<VehicleDescriptionParams>({
    year: initialData?.year || new Date().getFullYear(),
    make: initialData?.make || '',
    model: initialData?.model || '',
    trim: initialData?.trim || '',
    features: initialData?.features || [],
    benefits: initialData?.benefits || [],
    dealershipName: initialData?.dealershipName || '',
    dealershipLocation: initialData?.dealershipLocation || {
      address: '',
      city: '',
      province: '',
      postalCode: ''
    }
  });

  const [description, setDescription] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const [newFeature, setNewFeature] = useState('');
  const [newBenefit, setNewBenefit] = useState('');

  const handleGenerate = async () => {
    if (!params.make || !params.model) {
      setError('Make and model are required');
      return;
    }

    setIsGenerating(true);
    setError(null);

    try {
      const generatedDescription = await generateVehicleDescription(params);
      setDescription(generatedDescription);
      if (onGenerated) {
        onGenerated(generatedDescription);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to generate description');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(description);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      setError('Failed to copy to clipboard');
    }
  };

  const addFeature = () => {
    if (newFeature.trim()) {
      setParams(prev => ({
        ...prev,
        features: [...(prev.features || []), newFeature.trim()]
      }));
      setNewFeature('');
    }
  };

  const addBenefit = () => {
    if (newBenefit.trim()) {
      setParams(prev => ({
        ...prev,
        benefits: [...(prev.benefits || []), newBenefit.trim()]
      }));
      setNewBenefit('');
    }
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Year
          </label>
          <input
            type="number"
            value={params.year}
            onChange={(e) => setParams({ ...params, year: parseInt(e.target.value) })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            min={1900}
            max={new Date().getFullYear() + 1}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Make
          </label>
          <input
            type="text"
            value={params.make}
            onChange={(e) => setParams({ ...params, make: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Model
          </label>
          <input
            type="text"
            value={params.model}
            onChange={(e) => setParams({ ...params, model: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Trim
          </label>
          <input
            type="text"
            value={params.trim}
            onChange={(e) => setParams({ ...params, trim: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Features
          </label>
          <div className="mt-1 flex space-x-2">
            <input
              type="text"
              value={newFeature}
              onChange={(e) => setNewFeature(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="Add a feature"
              onKeyPress={(e) => e.key === 'Enter' && addFeature()}
            />
            <button
              type="button"
              onClick={addFeature}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Add
            </button>
          </div>
          <div className="mt-2 flex flex-wrap gap-2">
            {params.features?.map((feature, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
              >
                {feature}
                <button
                  type="button"
                  onClick={() => setParams(prev => ({
                    ...prev,
                    features: prev.features?.filter((_, i) => i !== index)
                  }))}
                  className="ml-1 inline-flex items-center p-0.5 rounded-full text-blue-400 hover:bg-blue-200 hover:text-blue-500"
                >
                  <span className="sr-only">Remove</span>
                  ×
                </button>
              </span>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Benefits
          </label>
          <div className="mt-1 flex space-x-2">
            <input
              type="text"
              value={newBenefit}
              onChange={(e) => setNewBenefit(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="Add a benefit"
              onKeyPress={(e) => e.key === 'Enter' && addBenefit()}
            />
            <button
              type="button"
              onClick={addBenefit}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Add
            </button>
          </div>
          <div className="mt-2 flex flex-wrap gap-2">
            {params.benefits?.map((benefit, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                {benefit}
                <button
                  type="button"
                  onClick={() => setParams(prev => ({
                    ...prev,
                    benefits: prev.benefits?.filter((_, i) => i !== index)
                  }))}
                  className="ml-1 inline-flex items-center p-0.5 rounded-full text-green-400 hover:bg-green-200 hover:text-green-500"
                >
                  <span className="sr-only">Remove</span>
                  ×
                </button>
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Dealership Name
          </label>
          <input
            type="text"
            value={params.dealershipName}
            onChange={(e) => setParams({ ...params, dealershipName: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Address
          </label>
          <input
            type="text"
            value={params.dealershipLocation?.address}
            onChange={(e) => setParams({
              ...params,
              dealershipLocation: {
                ...params.dealershipLocation!,
                address: e.target.value
              }
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            City
          </label>
          <input
            type="text"
            value={params.dealershipLocation?.city}
            onChange={(e) => setParams({
              ...params,
              dealershipLocation: {
                ...params.dealershipLocation!,
                city: e.target.value
              }
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Province
          </label>
          <input
            type="text"
            value={params.dealershipLocation?.province}
            onChange={(e) => setParams({
              ...params,
              dealershipLocation: {
                ...params.dealershipLocation!,
                province: e.target.value
              }
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Postal Code
          </label>
          <input
            type="text"
            value={params.dealershipLocation?.postalCode}
            onChange={(e) => setParams({
              ...params,
              dealershipLocation: {
                ...params.dealershipLocation!,
                postalCode: e.target.value
              }
            })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleGenerate}
          disabled={isGenerating}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {isGenerating ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
              Generating...
            </>
          ) : (
            <>
              <Car className="-ml-1 mr-2 h-5 w-5" />
              Generate Description
            </>
          )}
        </button>
      </div>

      {description && (
        <div className="mt-6">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900">Generated Description</h3>
            <button
              type="button"
              onClick={handleCopy}
              className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {copied ? (
                <>
                  <Check className="-ml-1 mr-2 h-5 w-5 text-green-500" />
                  Copied!
                </>
              ) : (
                <>
                  <Clipboard className="-ml-1 mr-2 h-5 w-5" />
                  Copy
                </>
              )}
            </button>
          </div>
          <div className="mt-2 p-4 bg-gray-50 rounded-lg">
            <p className="text-sm text-gray-900 whitespace-pre-wrap">{description}</p>
          </div>
        </div>
      )}
    </div>
  );
}