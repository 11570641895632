import { supabase } from '../lib/supabase';
import type { Lead } from '../types/lead';

export async function getLeads(): Promise<Lead[]> {
  try {
    const { data: leads, error } = await supabase
      .from('leads')
      .select(`
        id,
        name,
        email,
        phone,
        vehicle,
        status,
        source,
        score,
        engagement_metrics,
        preferences,
        last_contact,
        created_at
      `)
      .order('created_at', { ascending: false });

    if (error) throw error;
    
    return leads.map(lead => ({
      id: lead.id,
      name: lead.name,
      email: lead.email,
      phone: lead.phone,
      vehicle: lead.vehicle,
      status: lead.status,
      source: lead.source,
      createdAt: lead.created_at,
      lastContact: lead.last_contact,
      score: lead.score,
      engagementMetrics: lead.engagement_metrics,
      preferences: lead.preferences
    }));
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
}

function transformLeads(data: any[]): Lead[] {
  return data.map(lead => ({
    id: lead.id,
    name: lead.name,
    email: lead.email,
    phone: lead.phone,
    vehicle: lead.vehicle,
    status: lead.status,
    source: lead.source,
    createdAt: lead.created_at,
    lastContact: lead.last_contact,
    score: lead.score,
    engagementMetrics: lead.engagement_metrics,
    preferences: lead.preferences
  }));
}

export async function createLead(leadData: Omit<Lead, 'id' | 'createdAt'>): Promise<Lead> {
  try {
    // Normalize phone and email
    const phone = leadData.phone?.replace(/[^0-9+]/g, '');
    const email = leadData.email?.toLowerCase().trim();

    // Check for existing lead
    const { data: existing } = await supabase
      .from('leads')
      .select('id')
      .or(`phone.eq.${phone},email.eq.${email}`)
      .maybeSingle();

    if (existing) {
      throw new Error('Lead already exists with this phone number or email');
    }

    const newLead = {
      ...leadData,
      phone,
      email,
      source: leadData.source,
      vehicle: leadData.vehicle,
      created_at: new Date().toISOString(),
      last_contact: new Date().toISOString()
    };

    const { data, error } = await supabase
      .from('leads')
      .insert([newLead])
      .select()
      .single();

    if (error) throw error;
    return transformLeads([data])[0];
  } catch (error) {
    console.error('Error creating lead:', error);
    throw error;
  }
}

export async function updateLead(id: string, data: Partial<Lead>): Promise<void> {
  try {
    // Prepare update data with validation
    const updateData = {
      ...data,
      source: data.source,
      vehicle: data.vehicle,
      last_contact: new Date().toISOString()
    };

    // Validate status if it's being updated
    if (data.status) {
      const validStatuses = ['new', 'contacted', 'qualified', 'converted', 'lost'];
      if (!validStatuses.includes(data.status)) {
        throw new Error(`Invalid status: ${data.status}`);
      }
    }

    // Perform the update
    const { error } = await supabase
      .from('leads')
      .update(updateData)
      .eq('id', id)
      .throwOnError();
    if (error) throw error;
  } catch (error) {
    console.error('Error updating lead:', error);
    // Rethrow with more specific error message
    throw new Error(
      error instanceof Error 
        ? `Failed to update lead: ${error.message}`
        : 'Failed to update lead: Unknown error'
    );
  }
}

export async function deleteLead(id: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('leads')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting lead:', error);
    throw error;
  }
}