import { supabase } from '../lib/supabase';
import type { Workflow, WorkflowInstance } from '../types/workflow';

export async function getWorkflows(): Promise<Workflow[]> {
  try {
    const { data: workflows, error } = await supabase
      .from('workflows')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return workflows;
  } catch (error) {
    console.error('Error fetching workflows:', error);
    throw error;
  }
}

export async function createWorkflow(workflow: Omit<Workflow, 'id' | 'createdAt' | 'updatedAt'>): Promise<Workflow> {
  try {
    const { data, error } = await supabase
      .from('workflows')
      .insert([workflow])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating workflow:', error);
    throw error;
  }
}

export async function updateWorkflow(id: string, updates: Partial<Workflow>): Promise<void> {
  try {
    const { error } = await supabase
      .from('workflows')
      .update(updates)
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating workflow:', error);
    throw error;
  }
}

export async function deleteWorkflow(id: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('workflows')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting workflow:', error);
    throw error;
  }
}

export async function getWorkflowInstances(workflowId: string): Promise<WorkflowInstance[]> {
  try {
    const { data: instances, error } = await supabase
      .from('workflow_instances')
      .select(`
        *,
        leads (*)
      `)
      .eq('workflow_id', workflowId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return instances;
  } catch (error) {
    console.error('Error fetching workflow instances:', error);
    throw error;
  }
}

export async function updateWorkflowInstance(id: string, updates: Partial<WorkflowInstance>): Promise<void> {
  try {
    const { error } = await supabase
      .from('workflow_instances')
      .update(updates)
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating workflow instance:', error);
    throw error;
  }
}