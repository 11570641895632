import React, { useState, useEffect } from 'react';
import { Plus, Play, Pause, Settings, ArrowRight, X } from 'lucide-react';
import { WorkflowBuilder } from '../components/workflows/WorkflowBuilder';
import { getWorkflows, createWorkflow, updateWorkflow, deleteWorkflow } from '../services/workflowService';
import type { Workflow } from '../types/workflow';

export function Workflows() {
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showBuilder, setShowBuilder] = useState(false);
  const [editingWorkflow, setEditingWorkflow] = useState<Workflow | null>(null);

  useEffect(() => {
    loadWorkflows();
  }, []);

  const loadWorkflows = async () => {
    try {
      setLoading(true);
      const data = await getWorkflows();
      setWorkflows(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load workflows');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateWorkflow = async (workflow: Omit<Workflow, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      await createWorkflow(workflow);
      await loadWorkflows();
      setShowBuilder(false);
    } catch (err) {
      console.error('Error creating workflow:', err);
      throw err;
    }
  };

  const handleUpdateWorkflow = async (workflow: Workflow) => {
    try {
      await updateWorkflow(workflow.id, workflow);
      await loadWorkflows();
      setEditingWorkflow(null);
    } catch (err) {
      console.error('Error updating workflow:', err);
      throw err;
    }
  };

  const handleDeleteWorkflow = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this workflow?')) return;
    
    try {
      await deleteWorkflow(id);
      await loadWorkflows();
    } catch (err) {
      console.error('Error deleting workflow:', err);
    }
  };

  const handleToggleActive = async (workflow: Workflow) => {
    try {
      await updateWorkflow(workflow.id, { active: !workflow.active });
      await loadWorkflows();
    } catch (err) {
      console.error('Error toggling workflow:', err);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Workflows</h1>
          <p className="mt-2 text-sm text-gray-700">
            Automate your sales and marketing processes
          </p>
        </div>
        <button
          onClick={() => setShowBuilder(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Plus className="-ml-0.5 h-5 w-5" />
          New Workflow
        </button>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {workflows.map((workflow) => (
          <div
            key={workflow.id}
            className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow duration-200"
          >
            <div className="p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <Settings className="h-5 w-5 text-blue-500" />
                  <h3 className="text-lg font-medium text-gray-900">{workflow.name}</h3>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleToggleActive(workflow)}
                    className={`p-1 rounded-full ${
                      workflow.active 
                        ? 'text-green-600 hover:text-green-700' 
                        : 'text-gray-400 hover:text-gray-500'
                    }`}
                    title={workflow.active ? 'Deactivate workflow' : 'Activate workflow'}
                  >
                    {workflow.active ? (
                      <Pause className="h-5 w-5" />
                    ) : (
                      <Play className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>

              {workflow.description && (
                <p className="mt-2 text-sm text-gray-500">{workflow.description}</p>
              )}

              <div className="mt-4 space-y-2">
                {workflow.triggers.map((trigger, index) => (
                  <div
                    key={index}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2"
                  >
                    {trigger.event}
                  </div>
                ))}
              </div>

              <div className="mt-4 flex items-center justify-between text-sm">
                <div className="text-gray-500">
                  {workflow.stages.length} stages
                </div>
                <button
                  onClick={() => setEditingWorkflow(workflow)}
                  className="inline-flex items-center text-blue-600 hover:text-blue-700"
                >
                  Edit Workflow
                  <ArrowRight className="ml-1 h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {(showBuilder || editingWorkflow) && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    onClick={() => {
                      setShowBuilder(false);
                      setEditingWorkflow(null);
                    }}
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">
                      {editingWorkflow ? 'Edit Workflow' : 'New Workflow'}
                    </h3>
                    <div className="mt-6">
                      <WorkflowBuilder
                        initialData={editingWorkflow || undefined}
                        onSave={editingWorkflow ? handleUpdateWorkflow : handleCreateWorkflow}
                        onCancel={() => {
                          setShowBuilder(false);
                          setEditingWorkflow(null);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}