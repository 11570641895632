import React, { useState } from 'react';
import { Play, Trash2, Share2, Clock } from 'lucide-react';
import { formatDate } from '../../utils/formatDate';

interface VideoItem {
  id: string;
  title: string;
  thumbnail: string;
  duration: number;
  createdAt: string;
  views: number;
}

interface VideoLibraryProps {
  videos: VideoItem[];
  onSelect: (video: VideoItem) => void;
  onDelete: (id: string) => void;
  onShare: (video: VideoItem) => void;
}

export function VideoLibrary({ videos, onSelect, onDelete, onShare }: VideoLibraryProps) {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900">Video Library</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {videos.map((video) => (
          <div
            key={video.id}
            className={`relative group rounded-lg overflow-hidden bg-gray-100 ${
              selectedVideo === video.id ? 'ring-2 ring-blue-500' : ''
            }`}
          >
            <div className="aspect-video relative">
              <img
                src={video.thumbnail}
                alt={video.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity flex items-center justify-center">
                <Play className="h-12 w-12 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
              <div className="absolute bottom-2 right-2 bg-black bg-opacity-75 text-white text-xs px-2 py-1 rounded">
                {formatDuration(video.duration)}
              </div>
            </div>

            <div className="p-4">
              <h4 className="text-sm font-medium text-gray-900 truncate">{video.title}</h4>
              <div className="mt-1 flex items-center justify-between text-xs text-gray-500">
                <div className="flex items-center">
                  <Clock className="h-3 w-3 mr-1" />
                  {formatDate(video.createdAt)}
                </div>
                <div>{video.views} views</div>
              </div>

              <div className="mt-4 flex justify-end space-x-2">
                <button
                  onClick={() => onShare(video)}
                  className="p-1 text-gray-400 hover:text-gray-500"
                >
                  <Share2 className="h-4 w-4" />
                </button>
                <button
                  onClick={() => onDelete(video.id)}
                  className="p-1 text-gray-400 hover:text-red-500"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {videos.length === 0 && (
        <div className="text-center py-12">
          <Play className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No videos</h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by recording or uploading a video
          </p>
        </div>
      )}
    </div>
  );
}