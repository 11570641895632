import React, { useState } from 'react';
import { Wand2, Loader2 } from 'lucide-react';
import { openai } from '../../utils/openai';

interface AIMessageGeneratorProps {
  messageType: 'email' | 'sms';
  onMessageGenerated: (message: string) => void;
  customerName: string;
  vehicleInfo?: string;
}

export function AIMessageGenerator({ 
  messageType, 
  onMessageGenerated, 
  customerName, 
  vehicleInfo 
}: AIMessageGeneratorProps) {
  const [isGenerating, setIsGenerating] = useState(false);

  const generateMessage = async () => {
    setIsGenerating(true);
    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4",
        messages: [
          {
            role: "system",
            content: `You are an expert automotive sales professional crafting personalized ${messageType} messages. Write in a professional yet warm tone, focusing on building relationships and providing value.`
          },
          {
            role: "user",
            content: `Write a personalized ${messageType} for ${customerName}${
              vehicleInfo ? ` regarding their interest in ${vehicleInfo}` : ''
            }. ${messageType === 'sms' ? 'Keep it concise and under 160 characters.' : ''}`
          }
        ]
      });

      const generatedMessage = completion.choices[0]?.message?.content;
      if (generatedMessage) {
        onMessageGenerated(generatedMessage);
      }
    } catch (error) {
      console.error('Error generating message:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <button
      type="button"
      onClick={generateMessage}
      disabled={isGenerating}
      className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isGenerating ? (
        <>
          <Loader2 className="animate-spin -ml-0.5 mr-2 h-5 w-5" />
          Generating...
        </>
      ) : (
        <>
          <Wand2 className="-ml-0.5 mr-2 h-5 w-5" />
          Generate AI Message
        </>
      )}
    </button>
  );
}