import { Review } from '../types/review';

export const mockReviews: Review[] = [
  {
    id: '1',
    author: 'Sarah Mitchell',
    rating: 5,
    content: 'Exceptional service at AutoCRM Dealership! The sales team was knowledgeable and patient, making my car buying experience smooth and enjoyable. Special thanks to John who went above and beyond to find the perfect vehicle for my needs.',
    date: '2024-03-15T14:30:00Z',
    avatarUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=150&h=150&q=80',
    dealerResponse: "Thank you for your kind words, Sarah! We're thrilled to hear about your positive experience with our team. Enjoy your new vehicle!",
    helpful: 12
  },
  {
    id: '2',
    author: 'Michael Chen',
    rating: 4,
    content: 'Great selection of vehicles and competitive pricing. The only reason for 4 stars instead of 5 is the slightly longer than expected wait time during the financing process. Otherwise, very satisfied with my purchase.',
    date: '2024-03-10T09:15:00Z',
    avatarUrl: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=150&h=150&q=80',
    helpful: 8
  },
  {
    id: '3',
    author: 'Emily Rodriguez',
    rating: 5,
    content: "I recently purchased a certified pre-owned vehicle from AutoCRM, and I couldn't be happier! The entire process was transparent, and they even helped me find great financing options. The dealership is clean and modern, and the staff is very professional.",
    date: '2024-03-08T16:45:00Z',
    avatarUrl: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&w=150&h=150&q=80',
    dealerResponse: "Thank you for choosing AutoCRM, Emily! We're glad we could help you find the perfect certified pre-owned vehicle.",
    helpful: 15
  },
  {
    id: '4',
    author: 'David Thompson',
    rating: 5,
    content: 'Outstanding experience with the service department. They diagnosed and fixed an issue with my vehicle quickly and at a fair price. The waiting area is comfortable with good coffee and WiFi. Will definitely return for future service needs.',
    date: '2024-03-05T11:20:00Z',
    avatarUrl: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=150&h=150&q=80',
    helpful: 10
  },
  {
    id: '5',
    author: 'Amanda Lee',
    rating: 3,
    content: 'Average experience. While the sales staff was friendly, I felt the process could have been more efficient. Appreciate their follow-up communication though.',
    date: '2024-03-01T13:50:00Z',
    avatarUrl: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=150&h=150&q=80',
    dealerResponse: "Thank you for your feedback, Amanda. We're always looking to improve our processes. Our team will reach out to learn more about how we can serve you better.",
    helpful: 6
  }
];