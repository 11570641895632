import React from 'react';
import { Check, X } from 'lucide-react';
import { formatDate } from '../../utils/formatDate';
import type { Notification } from '../../types/notification';

interface NotificationItemProps {
  notification: Notification;
  onMarkAsRead: (id: string) => void;
  onRemove: (id: string) => void;
}

export function NotificationItem({ notification, onMarkAsRead, onRemove }: NotificationItemProps) {
  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'lead': return '👤';
      case 'reminder': return '⏰';
      case 'message': return '✉️';
      case 'task': return '📋';
      default: return '🔔';
    }
  };

  return (
    <div className={`p-4 ${notification.status === 'unread' ? 'bg-blue-50' : ''}`}>
      <div className="flex items-start">
        <div className="flex-shrink-0 pt-0.5">
          <span className="text-2xl">
            {getNotificationIcon(notification.type)}
          </span>
        </div>
        <div className="ml-3 flex-1">
          <p className="text-sm font-medium text-gray-900">
            {notification.title}
          </p>
          <p className="mt-1 text-sm text-gray-500">
            {notification.message}
          </p>
          <div className="mt-2 flex items-center justify-between">
            <p className="text-xs text-gray-400">
              {formatDate(notification.createdAt)}
            </p>
            <div className="flex items-center space-x-2">
              {notification.status === 'unread' && (
                <button
                  onClick={() => onMarkAsRead(notification.id)}
                  className="rounded-md bg-blue-100 p-1 text-blue-600 hover:bg-blue-200"
                >
                  <Check className="h-4 w-4" />
                </button>
              )}
              <button
                onClick={() => onRemove(notification.id)}
                className="rounded-md bg-gray-100 p-1 text-gray-400 hover:text-gray-500"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}