import React from 'react';
import { Bell, Calendar, CheckCircle2, Clock, AlertTriangle, Gift } from 'lucide-react';
import type { Reminder } from '../../types/reminder';
import { formatDate } from '../../utils/formatDate';

interface ReminderListProps {
  reminders: Reminder[];
  onComplete: (id: string) => void;
  onDelete: (id: string) => void;
}

export function ReminderList({ reminders, onComplete, onDelete }: ReminderListProps) {
  const getPriorityIcon = (priority: string) => {
    switch (priority) {
      case 'high':
        return <AlertTriangle className="h-5 w-5 text-red-500" />;
      case 'medium':
        return <Bell className="h-5 w-5 text-yellow-500" />;
      case 'low':
        return <Clock className="h-5 w-5 text-blue-500" />;
      default:
        return <Bell className="h-5 w-5 text-gray-500" />;
    }
  };

  const getTypeIcon = (type: string) => {
    switch (type) {
      case 'birthday':
        return <Gift className="h-5 w-5 text-pink-500" />;
      case 'anniversary':
        return <Calendar className="h-5 w-5 text-purple-500" />;
      default:
        return getPriorityIcon('medium');
    }
  };

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {reminders.map((reminder, reminderIdx) => (
          <li key={reminder.id}>
            <div className="relative pb-8">
              {reminderIdx !== reminders.length - 1 ? (
                <span
                  className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-start space-x-3">
                <div className="relative">
                  {getTypeIcon(reminder.type)}
                </div>
                <div className="min-w-0 flex-1 bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{reminder.title}</p>
                      <p className="mt-1 text-sm text-gray-500">{reminder.description}</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex items-center space-x-4">
                      <button
                        onClick={() => onComplete(reminder.id)}
                        className="text-green-600 hover:text-green-700"
                      >
                        <CheckCircle2 className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => onDelete(reminder.id)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <span className="sr-only">Delete reminder</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <Calendar className="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400" />
                    Due: {formatDate(reminder.dueDate)}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}