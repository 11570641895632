import React from 'react';
import { Search } from 'lucide-react';

interface CustomerFiltersProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
  selectedSalesRep: string;
  onSalesRepChange: (salesRep: string) => void;
  showSalesRepFilter: boolean;
}

const SALES_REPS = [
  { id: '1', name: 'John Smith' },
  { id: '2', name: 'Sarah Johnson' },
];

export function CustomerFilters({
  searchQuery,
  onSearchChange,
  selectedSalesRep,
  onSalesRepChange,
  showSalesRepFilter,
}: CustomerFiltersProps) {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <div>
        <label htmlFor="salesRep" className="block text-sm font-medium text-gray-700">
          Sales Representative
        </label>
        <select
          id="salesRep"
          value={selectedSalesRep}
          onChange={(e) => onSalesRepChange(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
          disabled={!showSalesRepFilter}
        >
          <option value="all">All Sales Representatives</option>
          {SALES_REPS.map((rep) => (
            <option key={rep.id} value={rep.id}>
              {rep.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="purchaseDate" className="block text-sm font-medium text-gray-700">
          Purchase Date
        </label>
        <select
          id="purchaseDate"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
        >
          <option value="all">All Time</option>
          <option value="30">Last 30 Days</option>
          <option value="90">Last 90 Days</option>
          <option value="365">Last Year</option>
        </select>
      </div>
    </div>
  );
}