import React, { useState } from 'react';
import { Target, Loader2 } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

export function CompetitorAnalysis() {
  const { generateResponse, isLoading } = useAI();
  const [competitor, setCompetitor] = useState('');
  const [analysis, setAnalysis] = useState<string | null>(null);

  const handleAnalyze = async () => {
    const prompt = `Analyze this competitor dealership: ${competitor}. Focus on:
      1. Market positioning
      2. Strengths and weaknesses
      3. Competitive advantages
      4. Marketing strategies
      5. Recommendations for competing effectively`;

    const response = await generateResponse(prompt);
    if (response) {
      setAnalysis(response);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Target className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Competitor Analysis</h2>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Competitor Name</label>
            <input
              type="text"
              value={competitor}
              onChange={(e) => setCompetitor(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Enter competitor dealership name..."
            />
          </div>

          <button
            onClick={handleAnalyze}
            disabled={isLoading || !competitor.trim()}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Analyzing...
              </>
            ) : (
              <>
                <Target className="-ml-1 mr-2 h-5 w-5" />
                Analyze Competitor
              </>
            )}
          </button>
        </div>

        {analysis && (
          <div className="mt-6 bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Analysis Results</h3>
            <div className="prose prose-blue max-w-none">
              {analysis.split('\n').map((line, index) => (
                <p key={index} className="text-gray-600">{line}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}