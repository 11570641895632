import React, { useState } from 'react';
import { DollarSign, Loader2 } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

export function PriceOptimizer() {
  const { generateResponse, isLoading } = useAI();
  const [vehicleData, setVehicleData] = useState({
    make: '',
    model: '',
    year: new Date().getFullYear(),
    mileage: 0,
    condition: 'excellent', 
    features: [] as string[],
    marketData: {
      daysOnMarket: 0,
      competitorPrices: '',
      localDemand: 'medium'
    },
    seasonalFactors: true,
    certificationStatus: 'none'
  });
  const [newFeature, setNewFeature] = useState('');
  const [analysis, setAnalysis] = useState<string | null>(null);

  const handleAnalyze = async () => {
    const prompt = `Analyze optimal pricing for:
      ${vehicleData.year} ${vehicleData.make} ${vehicleData.model}
      Mileage: ${vehicleData.mileage}
      Condition: ${vehicleData.condition}
      Features: ${vehicleData.features.join(', ')}
      Days on Market: ${vehicleData.marketData.daysOnMarket}
      Competitor Prices: ${vehicleData.marketData.competitorPrices}
      Local Demand: ${vehicleData.marketData.localDemand}
      Seasonal Factors: ${vehicleData.seasonalFactors ? 'Consider seasonal trends' : 'Ignore seasonal trends'}
      Certification: ${vehicleData.certificationStatus}

      Provide:
      1. Recommended price range
      2. Market positioning analysis
      3. Competitive pricing insights
      4. Negotiation strategy
      5. Price adjustment recommendations based on market conditions
      6. Seasonal pricing strategy
      7. ROI analysis for certification`;

    const response = await generateResponse(prompt);
    if (response) {
      setAnalysis(response);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <DollarSign className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Price Optimizer</h2>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Make</label>
              <input
                type="text"
                value={vehicleData.make}
                onChange={(e) => setVehicleData({ ...vehicleData, make: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Model</label>
              <input
                type="text"
                value={vehicleData.model}
                onChange={(e) => setVehicleData({ ...vehicleData, model: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Year</label>
              <input
                type="number"
                value={vehicleData.year}
                onChange={(e) => setVehicleData({ ...vehicleData, year: parseInt(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Mileage</label>
              <input
                type="number"
                value={vehicleData.mileage}
                onChange={(e) => setVehicleData({ ...vehicleData, mileage: parseInt(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Market Data</label>
            <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div>
                <label className="block text-xs text-gray-500">Days on Market</label>
                <input
                  type="number"
                  value={vehicleData.marketData.daysOnMarket}
                  onChange={(e) => setVehicleData({
                    ...vehicleData,
                    marketData: {
                      ...vehicleData.marketData,
                      daysOnMarket: parseInt(e.target.value)
                    }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              
              <div>
                <label className="block text-xs text-gray-500">Local Demand</label>
                <select
                  value={vehicleData.marketData.localDemand}
                  onChange={(e) => setVehicleData({
                    ...vehicleData,
                    marketData: {
                      ...vehicleData.marketData,
                      localDemand: e.target.value
                    }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </div>

              <div>
                <label className="block text-xs text-gray-500">Certification Status</label>
                <select
                  value={vehicleData.certificationStatus}
                  onChange={(e) => setVehicleData({
                    ...vehicleData,
                    certificationStatus: e.target.value
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="none">None</option>
                  <option value="manufacturer">Manufacturer Certified</option>
                  <option value="dealership">Dealership Certified</option>
                </select>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Features</label>
            <div className="mt-2 flex gap-2">
              <input
                type="text"
                value={newFeature}
                onChange={(e) => setNewFeature(e.target.value)}
                className="block flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Add a feature"
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && newFeature.trim()) {
                    setVehicleData({
                      ...vehicleData,
                      features: [...vehicleData.features, newFeature.trim()]
                    });
                    setNewFeature('');
                  }
                }}
              />
              <button
                type="button"
                onClick={() => {
                  if (newFeature.trim()) {
                    setVehicleData({
                      ...vehicleData,
                      features: [...vehicleData.features, newFeature.trim()]
                    });
                    setNewFeature('');
                  }
                }}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Add
              </button>
            </div>
            <div className="mt-2 flex flex-wrap gap-2">
              {vehicleData.features.map((feature, index) => (
                <span
                  key={index}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                >
                  {feature}
                  <button
                    type="button"
                    onClick={() => setVehicleData({
                      ...vehicleData,
                      features: vehicleData.features.filter((_, i) => i !== index)
                    })}
                    className="ml-1.5 inline-flex items-center justify-center w-4 h-4 rounded-full hover:bg-blue-200"
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>
          <button
            onClick={handleAnalyze}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Analyzing...
              </>
            ) : (
              <>
                <DollarSign className="-ml-1 mr-2 h-5 w-5" />
                Optimize Price
              </>
            )}
          </button>
        </div>

        {analysis && (
          <div className="mt-6 bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Price Analysis</h3>
            <div className="prose prose-blue max-w-none">
              {analysis.split('\n').map((line, index) => (
                <p key={index} className="text-gray-600">{line}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}