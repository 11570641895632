import React, { useState, useEffect } from 'react';
import { Clock, Save, Loader2, Plus, Trash2 } from 'lucide-react';
import { getSettings, updateSettings, createSettings } from '../../lib/supabase/settings';

interface DaySchedule {
  day: string;
  isOpen: boolean;
  openTime: string;
  closeTime: string;
}

interface Holiday {
  date: string;
  name: string;
  isOpen: boolean;
}

interface BusinessHoursSettings {
  id?: string;
  type: string;
  schedule: DaySchedule[];
  holidays: Holiday[];
  specialHours: any[];
}

const DEFAULT_SCHEDULE: DaySchedule[] = [
  { day: 'Monday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
  { day: 'Tuesday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
  { day: 'Wednesday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
  { day: 'Thursday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
  { day: 'Friday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
  { day: 'Saturday', isOpen: true, openTime: '10:00', closeTime: '16:00' },
  { day: 'Sunday', isOpen: false, openTime: '09:00', closeTime: '18:00' },
];

const DEFAULT_SETTINGS: BusinessHoursSettings = {
  type: 'businessHours',
  schedule: DEFAULT_SCHEDULE,
  holidays: [],
  specialHours: [],
};

interface BusinessHoursProps {
  settings?: BusinessHoursSettings | null;
}

export function BusinessHours({ settings: initialSettings }: BusinessHoursProps) {
  const [settings, setSettings] = useState<BusinessHoursSettings>(() => {
    if (!initialSettings) return DEFAULT_SETTINGS;
    return {
      ...DEFAULT_SETTINGS,
      ...initialSettings,
      schedule: initialSettings.schedule || DEFAULT_SCHEDULE,
      holidays: initialSettings.holidays || [],
      specialHours: initialSettings.specialHours || [],
    };
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [newHoliday, setNewHoliday] = useState<Holiday>({
    date: '',
    name: '',
    isOpen: false,
  });

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await getSettings('businessHours');
        if (!settings) {
          const id = await createSettings('businessHours', DEFAULT_SETTINGS);
          setSettings({ ...DEFAULT_SETTINGS, id });
        } else {
          setSettings({ ...DEFAULT_SETTINGS, ...settings });
        }
      } catch (err) {
        setError('Failed to load business hours settings');
      }
    };

    loadSettings();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);
    
    try {
      const id = settings.id || await createSettings('businessHours', settings);
      await updateSettings(id, settings);
      setIsEditing(false);
    } catch (err) {
      setError('Failed to save settings');
      console.error('Error saving settings:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleScheduleChange = (day: string, field: string, value: string | boolean) => {
    const newSchedule = settings.schedule.map(item => {
      if (item.day === day) {
        return { ...item, [field]: value };
      }
      return item;
    });

    setSettings({ ...settings, schedule: newSchedule });
  };

  const handleAddHoliday = () => {
    if (!newHoliday.date || !newHoliday.name) return;

    setSettings({
      ...settings,
      holidays: [...settings.holidays, newHoliday],
    });

    setNewHoliday({ date: '', name: '', isOpen: false });
  };

  const handleRemoveHoliday = (date: string) => {
    setSettings({
      ...settings,
      holidays: settings.holidays.filter(h => h.date !== date),
    });
  };

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-lg font-medium text-gray-900">Business Hours</h2>
          <p className="mt-1 text-sm text-gray-500">
            Set your regular business hours and holiday schedule
          </p>
        </div>
        {!isEditing && (
          <button
            onClick={() => setIsEditing(true)}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
          >
            Edit Hours
          </button>
        )}
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {settings.schedule.map((day) => (
              <li key={day.day} className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={day.isOpen}
                      onChange={(e) => handleScheduleChange(day.day, 'isOpen', e.target.checked)}
                      disabled={!isEditing}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 disabled:opacity-50"
                    />
                    <span className="ml-3 block text-sm font-medium text-gray-700">
                      {day.day}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <input
                      type="time"
                      value={day.openTime}
                      onChange={(e) => handleScheduleChange(day.day, 'openTime', e.target.value)}
                      disabled={!isEditing || !day.isOpen}
                      className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:opacity-50"
                    />
                    <span className="text-gray-500">to</span>
                    <input
                      type="time"
                      value={day.closeTime}
                      onChange={(e) => handleScheduleChange(day.day, 'closeTime', e.target.value)}
                      disabled={!isEditing || !day.isOpen}
                      className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:opacity-50"
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-medium text-gray-900">Holidays</h3>
          {settings.holidays.map((holiday) => (
            <div key={holiday.date} className="flex items-center justify-between bg-gray-50 px-4 py-3 rounded-md">
              <div className="flex items-center space-x-4">
                <span className="text-sm font-medium text-gray-700">{holiday.name}</span>
                <span className="text-sm text-gray-500">{holiday.date}</span>
                <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                  holiday.isOpen ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {holiday.isOpen ? 'Open' : 'Closed'}
                </span>
              </div>
              {isEditing && (
                <button
                  type="button"
                  onClick={() => handleRemoveHoliday(holiday.date)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              )}
            </div>
          ))}

          {isEditing && (
            <div className="flex items-center space-x-4">
              <input
                type="date"
                value={newHoliday.date}
                onChange={(e) => setNewHoliday({ ...newHoliday, date: e.target.value })}
                className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
              <input
                type="text"
                value={newHoliday.name}
                onChange={(e) => setNewHoliday({ ...newHoliday, name: e.target.value })}
                placeholder="Holiday name"
                className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="holidayIsOpen"
                  checked={newHoliday.isOpen}
                  onChange={(e) => setNewHoliday({ ...newHoliday, isOpen: e.target.checked })}
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="holidayIsOpen" className="text-sm text-gray-700">Open</label>
              </div>
              <button
                type="button"
                onClick={handleAddHoliday}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
              >
                <Plus className="h-4 w-4" />
                Add Holiday
              </button>
            </div>
          )}
        </div>

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>{error}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {isEditing && (
          <div className="flex justify-end gap-x-3">
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSaving}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {isSaving ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="h-4 w-4" />
                  Save Changes
                </>
              )}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}