import React, { useState } from 'react';
import { X, Send, Video, Loader2 } from 'lucide-react';
import { useTemplates } from '../../hooks/useTemplates';
import { AIMessageGenerator } from './AIMessageGenerator';
import { VideoMessage } from './VideoMessage';
import { sendSMS } from '../../utils/twilio';
import { useNotifications } from '../../context/NotificationContext';

interface SMSComposerModalProps {
  recipientName: string;
  phoneNumber: string;
  onClose: () => void;
  onSent?: () => void;
}

export function SMSComposerModal({ recipientName, phoneNumber, onClose, onSent }: SMSComposerModalProps) {
  const [message, setMessage] = useState('');
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { templates, selectedTemplate, setSelectedTemplate } = useTemplates('sms');
  const { addNotification } = useNotifications();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);
    setError(null);

    try {
      let finalMessage = message;
      if (videoUrl) {
        finalMessage = message.trim();
        if (!finalMessage.includes("I've recorded a video message for you")) {
          finalMessage += "\n\nI've recorded a video message for you.";
        }
      }

      const response = await sendSMS({
        to: phoneNumber,
        message: finalMessage,
        userId: 'current-user',
        mediaUrl: videoUrl
      });

      if (!response.success) {
        throw new Error(response.error || 'Failed to send SMS');
      }

      addNotification({
        type: 'message',
        title: 'SMS Sent',
        message: `Message sent to ${recipientName}`,
        priority: 'low'
      });

      if (onSent) {
        onSent();
      }
      onClose();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to send SMS';
      setError(errorMessage);
      addNotification({
        type: 'system',
        title: 'Error',
        message: errorMessage,
        priority: 'high'
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleMessageGenerated = (generatedMessage: string) => {
    setMessage(generatedMessage);
    setSelectedTemplate('');
  };

  const handleVideoReady = (url: string) => {
    setVideoUrl(url);
    setMessage(prev => 
      prev ? `${prev}\n\nI've recorded a video message for you.` : 
      "I've recorded a video message for you."
    );
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                onClick={onClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                  Send SMS to {recipientName}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Phone: {phoneNumber}
                  </p>
                </div>

                <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                  <div>
                    <label htmlFor="template" className="block text-sm font-medium text-gray-700">
                      Template
                    </label>
                    <select
                      id="template"
                      value={selectedTemplate}
                      onChange={(e) => setSelectedTemplate(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    >
                      <option value="">Select a template</option>
                      {templates.map((template) => (
                        <option key={template.id} value={template.id}>
                          {template.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex items-center justify-between">
                    <AIMessageGenerator
                      messageType="sms"
                      onMessageGenerated={handleMessageGenerated}
                      customerName={recipientName}
                    />
                    <button
                      type="button"
                      onClick={() => setShowVideoRecorder(true)}
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-200"
                    >
                      <Video className="-ml-0.5 h-5 w-5" />
                      Add Video
                    </button>
                  </div>

                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      required
                    />
                  </div>

                  {error && (
                    <div className="rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="ml-3">
                          <p className="text-sm font-medium text-red-800">{error}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      disabled={isSending}
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:w-auto"
                    >
                      {isSending ? (
                        <>
                          <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                          Sending...
                        </>
                      ) : (
                        <>
                          <Send className="-ml-1 mr-2 h-5 w-5" />
                          Send Message
                        </>
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={onClose}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {showVideoRecorder && (
              <VideoMessage
                onClose={() => setShowVideoRecorder(false)}
                onVideoReady={handleVideoReady}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}