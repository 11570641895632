import React from 'react';
import { Card, Title, Text, DonutChart } from '@tremor/react';

interface SourcesChartProps {
  data: Array<{
    source: string;
    value: number;
  }>;
}

export function SourcesChart({ data }: SourcesChartProps) {
  const colors = ["blue", "indigo", "violet", "purple", "fuchsia"];
  
  // Ensure unique keys by adding index to source
  const chartData = data.map((item, index) => ({
    id: `${item.source}-${index}`, // Add unique identifier
    source: item.source,
    value: item.value
  }));
  
  return (
    <Card className="bg-gradient-to-br from-purple-50 to-pink-50 p-6">
      <Title className="text-xl font-semibold text-gray-900">Lead Sources Distribution</Title>
      <Text className="mt-2 text-gray-600">
        Breakdown of lead sources by percentage
      </Text>
      <div className="mt-6">
        <DonutChart
          data={chartData}
          category="value"
          index="id" // Use the unique id as index
          valueFormatter={(value) => `${value}%`}
          colors={colors}
          showAnimation={true}
          showTooltip={true}
          showLabel={true}
          label="Total Leads"
          className="h-80"
        />
        <div className="mt-4 grid grid-cols-2 gap-4">
          {chartData.map((source, index) => (
            <div key={source.id} className="flex items-center space-x-2">
              <div className={`w-3 h-3 rounded-full bg-${colors[index]}-500`} />
              <span className="text-sm text-gray-600">
                {source.source}: {source.value}%
              </span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}