import React from 'react';
import { Users, Clock, Pencil, Trash2, Play, Pause } from 'lucide-react';
import type { SmartList } from '../../types/smartList';
import { formatDate } from '../../utils/formatDate';

interface SmartListCardProps {
  list: SmartList;
  onEdit: () => void;
  onDelete: () => void;
  onToggleActive: () => void;
  isActive?: boolean;
}

export function SmartListCard({ list, onEdit, onDelete, onToggleActive, isActive = false }: SmartListCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow duration-200">
      <div className="p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Users className="h-5 w-5 text-blue-500" />
            <h3 className="text-lg font-medium text-gray-900">{list.name}</h3>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={onToggleActive}
              className={`p-1 rounded-full ${
                isActive 
                  ? 'text-green-600 hover:text-green-700' 
                  : 'text-gray-400 hover:text-gray-500'
              }`}
              title={isActive ? 'Pause list' : 'Activate list'}
            >
              {isActive ? (
                <Pause className="h-5 w-5" />
              ) : (
                <Play className="h-5 w-5" />
              )}
            </button>
            <button
              onClick={onEdit}
              className="p-1 text-gray-400 hover:text-gray-500"
              title="Edit list"
            >
              <Pencil className="h-5 w-5" />
            </button>
            <button
              onClick={onDelete}
              className="p-1 text-gray-400 hover:text-gray-500"
              title="Delete list"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>

        {list.description && (
          <p className="mt-2 text-sm text-gray-500">{list.description}</p>
        )}

        <div className="mt-4 flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center">
            <Users className="h-4 w-4 mr-1.5" />
            {list.memberCount || 0} members
          </div>
          <div className="flex items-center">
            <Clock className="h-4 w-4 mr-1.5" />
            Created {formatDate(list.createdAt)}
          </div>
        </div>

        <div className="mt-4 space-y-2">
          {list.filters.map((filter, index) => (
            <div
              key={index}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2"
            >
              {filter.label || `${filter.field} ${filter.operator} ${filter.value}`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}