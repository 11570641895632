import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { 
  Bot, Car, Brain, Mail, TrendingUp, Target, 
  DollarSign, BarChart2, Users, Wrench, Languages 
} from 'lucide-react';

const tools = [
  {
    name: 'Sales Coach',
    href: '/ai-tools/sales-coach',
    icon: Brain,
    description: 'Get AI-powered sales coaching and advice'
  },
  {
    name: 'Vehicle Recommender',
    href: '/ai-tools/recommender',
    icon: Car,
    description: 'AI-powered vehicle recommendations'
  },
  {
    name: 'Customer Insights',
    href: '/ai-tools/insights',
    icon: Users,
    description: 'Deep customer behavior analysis'
  },
  {
    name: 'Email Generator',
    href: '/ai-tools/email',
    icon: Mail,
    description: 'Generate personalized emails'
  },
  {
    name: 'Market Analyzer',
    href: '/ai-tools/market-analyzer',
    icon: TrendingUp,
    description: 'Market trends and analysis'
  },
  {
    name: 'Competitor Analysis',
    href: '/ai-tools/competitor-analysis',
    icon: Target,
    description: 'Analyze competitor strategies'
  },
  {
    name: 'Price Optimizer',
    href: '/ai-tools/price-optimizer',
    icon: DollarSign,
    description: 'Optimize pricing strategies'
  },
  {
    name: 'Inventory Optimizer',
    href: '/ai-tools/inventory-optimizer',
    icon: BarChart2,
    description: 'Optimize inventory levels'
  },
  {
    name: 'Customer Segmentation',
    href: '/ai-tools/customer-segmentation',
    icon: Users,
    description: 'AI-powered customer segmentation'
  },
  {
    name: 'Service Predictor',
    href: '/ai-tools/service-predictor',
    icon: Wrench,
    description: 'Predict service needs'
  },
  {
    name: 'Real-time Translation',
    href: '/ai-tools/translation',
    icon: Languages,
    description: 'Translate customer communications in real-time'
  },
  {
    name: 'Vehicle Comparison',
    href: '/ai-tools/comparison',
    icon: Car,
    description: 'AI-powered vehicle comparison and analysis'
  }
];

export function AITools() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/ai-tools') {
      navigate('/ai-tools/sales-coach');
    }
  }, [location, navigate]);

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">AI Tools</h1>
          <p className="mt-2 text-sm text-gray-700">
            Leverage AI to enhance your sales and customer service
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {tools.map((tool) => (
          <button
            key={tool.name}
            onClick={() => navigate(tool.href)}
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <tool.icon className="mx-auto h-12 w-12 text-gray-400" />
            <span className="mt-2 block text-lg font-semibold text-gray-900">
              {tool.name}
            </span>
            <span className="mt-2 block text-sm text-gray-500">
              {tool.description}
            </span>
          </button>
        ))}
      </div>

      <Outlet />
    </div>
  );
}