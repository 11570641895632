import React, { useState } from 'react';
import { X, Wand2, Loader2, Gift, Calendar } from 'lucide-react';
import { openai } from '../../utils/openai';
import type { Reminder, ReminderPriority, ReminderType } from '../../types/reminder';

interface ReminderModalProps {
  onClose: () => void;
  onSubmit: (reminder: Omit<Reminder, 'id' | 'createdAt' | 'updatedAt'>) => void;
  customerId?: string;
  leadId?: string;
  customerName?: string;
  vehicleInfo?: string;
}

export function ReminderModal({ onClose, onSubmit, customerId, leadId, customerName, vehicleInfo }: ReminderModalProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [priority, setPriority] = useState<ReminderPriority>('medium');
  const [type, setType] = useState<ReminderType>('general');
  const [isGenerating, setIsGenerating] = useState(false);

  const generateSuggestion = async () => {
    setIsGenerating(true);
    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4",
        messages: [
          {
            role: "system",
            content: "You are an automotive sales assistant helping to create follow-up reminders."
          },
          {
            role: "user",
            content: `Suggest a reminder for ${customerName || 'a customer'}${
              vehicleInfo ? ` who is interested in a ${vehicleInfo}` : ''
            }. Format: Title | Description | Priority (low/medium/high)`
          }
        ]
      });

      const suggestion = completion.choices[0]?.message?.content;
      if (suggestion) {
        const [suggestedTitle, suggestedDescription, suggestedPriority] = suggestion.split('|').map(s => s.trim());
        setTitle(suggestedTitle);
        setDescription(suggestedDescription);
        setPriority(suggestedPriority.toLowerCase() as ReminderPriority);
      }
    } catch (error) {
      console.error('Error generating suggestion:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      userId: 'current-user', // This would come from auth context
      customerId,
      leadId,
      title,
      description,
      dueDate,
      priority,
      type,
      status: 'pending'
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                onClick={onClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                  Create Reminder
                </h3>
                <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                  <div>
                    <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                      Reminder Type
                    </label>
                    <div className="mt-1 flex space-x-4">
                      <button
                        type="button"
                        onClick={() => setType('general')}
                        className={`flex-1 inline-flex items-center justify-center px-3 py-2 border rounded-md ${
                          type === 'general'
                            ? 'border-blue-600 bg-blue-50 text-blue-700'
                            : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
                        }`}
                      >
                        <Calendar className="mr-2 h-4 w-4" />
                        General
                      </button>
                      <button
                        type="button"
                        onClick={() => setType('birthday')}
                        className={`flex-1 inline-flex items-center justify-center px-3 py-2 border rounded-md ${
                          type === 'birthday'
                            ? 'border-pink-600 bg-pink-50 text-pink-700'
                            : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
                        }`}
                      >
                        <Gift className="mr-2 h-4 w-4" />
                        Birthday
                      </button>
                      <button
                        type="button"
                        onClick={() => setType('anniversary')}
                        className={`flex-1 inline-flex items-center justify-center px-3 py-2 border rounded-md ${
                          type === 'anniversary'
                            ? 'border-purple-600 bg-purple-50 text-purple-700'
                            : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
                        }`}
                      >
                        <Calendar className="mr-2 h-4 w-4" />
                        Anniversary
                      </button>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                      Title
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="description"
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
                        Due Date
                      </label>
                      <div className="mt-1">
                        <input
                          type="datetime-local"
                          id="dueDate"
                          value={dueDate}
                          onChange={(e) => setDueDate(e.target.value)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                        Priority
                      </label>
                      <div className="mt-1">
                        <select
                          id="priority"
                          value={priority}
                          onChange={(e) => setPriority(e.target.value as ReminderPriority)}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        >
                          <option value="low">Low</option>
                          <option value="medium">Medium</option>
                          <option value="high">High</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:w-auto"
                    >
                      Create Reminder
                    </button>
                    <button
                      type="button"
                      onClick={generateSuggestion}
                      disabled={isGenerating || type !== 'general'}
                      className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 sm:mt-0 sm:w-auto"
                    >
                      {isGenerating ? (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      ) : (
                        <Wand2 className="mr-2 h-4 w-4" />
                      )}
                      Generate Suggestion
                    </button>
                    <button
                      type="button"
                      onClick={onClose}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}