import React from 'react';
import { Check, CheckCheck, Clock, AlertCircle } from 'lucide-react';
import { classNames } from '../../utils/classNames';

interface MessageStatusProps {
  status: string;
  className?: string;
}

export function MessageStatus({ status, className }: MessageStatusProps) {
  const getStatusDetails = () => {
    switch (status.toLowerCase()) {
      case 'delivered':
        return {
          icon: CheckCheck,
          color: 'text-green-500',
          label: 'Delivered'
        };
      case 'sent':
        return {
          icon: Check,
          color: 'text-blue-500',
          label: 'Sent'
        };
      case 'pending':
        return {
          icon: Clock,
          color: 'text-yellow-500',
          label: 'Pending'
        };
      case 'failed':
        return {
          icon: AlertCircle,
          color: 'text-red-500',
          label: 'Failed'
        };
      default:
        return {
          icon: Clock,
          color: 'text-gray-500',
          label: status
        };
    }
  };

  const { icon: Icon, color, label } = getStatusDetails();

  return (
    <div className={classNames('flex items-center gap-1', className || '')}>
      <Icon className={classNames('h-4 w-4', color)} />
      <span className="text-xs text-gray-500">{label}</span>
    </div>
  );
}