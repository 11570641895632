import { supabase } from '../lib/supabase';
import { sendSMS } from './twilio';
import type { Customer } from '../types/customer';

export async function sendReviewRequest(customer: Customer): Promise<void> {
  try {
    // Generate unique tracking ID
    const trackingId = `${customer.id}-${Date.now()}`;
    
    // Get review settings
    const { data: settings } = await supabase
      .from('settings')
      .select('data')
      .eq('type', 'review')
      .single();

    const baseUrl = settings?.data?.reviewBaseUrl || 'https://g.page/r/';
    const message = settings?.data?.reviewReminder || 'Thank you for choosing our dealership! Please share your experience:';
    
    // Create tracking record
    await supabase
      .from('review_links')
      .insert({
        tracking_id: trackingId,
        customer_id: customer.id,
        created_at: new Date().toISOString(),
        clicked: false
      });

    // Generate review link with tracking
    const trackingUrl = `${window.location.origin}/r/${trackingId}`;
    const reviewUrl = `${baseUrl}?redirect=${encodeURIComponent(trackingUrl)}`;

    // Update customer review status
    await supabase
      .from('customers')
      .update({
        review_status: {
          sent: true,
          sentDate: new Date().toISOString(),
          linkUsed: reviewUrl
        }
      })
      .eq('id', customer.id);

    // Send SMS with review link
    await sendSMS({
      to: customer.phone,
      message: `${message}\n${reviewUrl}`
    });

  } catch (error) {
    console.error('Error sending review request:', error);
    throw error;
  }
}