import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { trackReviewLinkClick } from '../utils/reviewTracking';
import { Loader2 } from 'lucide-react';

export function ReviewRedirect() {
  const { trackingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirect = async () => {
      if (trackingId) {
        try {
          // Track the click
          await trackReviewLinkClick(trackingId);
          
          // For demo, redirect to Google reviews
          window.location.href = 'https://www.google.com/business';
        } catch (error) {
          console.error('Error tracking review click:', error);
          navigate('/');
        }
      }
    };

    handleRedirect();
  }, [trackingId, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
    </div>
  );
}