import React, { useState } from 'react';
import { Plus, Play, Pause, Settings, BarChart2 } from 'lucide-react';
import { CampaignList } from '../components/drip/CampaignList';
import { CampaignModal } from '../components/drip/CampaignModal';
import { useFirestore } from '../hooks/useFirestore';
import { collection, updateDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { DripCampaign } from '../types/dripCampaign';

export function DripCampaigns() {
  const [showModal, setShowModal] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState<DripCampaign | null>(null);

  // Fetch campaigns from Firestore
  const { data: campaigns, loading } = useFirestore<DripCampaign>('campaigns', []);

  const handleCreateCampaign = async (campaign: Omit<DripCampaign, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      const campaignsRef = collection(db, 'campaigns');
      await addDoc(campaignsRef, {
        ...campaign,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
      setShowModal(false);
    } catch (error) {
      console.error('Error creating campaign:', error);
    }
  };

  const handleUpdateCampaign = async (updatedCampaign: DripCampaign) => {
    try {
      const campaignRef = doc(db, 'campaigns', updatedCampaign.id);
      await updateDoc(campaignRef, {
        ...updatedCampaign,
        updatedAt: new Date().toISOString()
      });
      setEditingCampaign(null);
    } catch (error) {
      console.error('Error updating campaign:', error);
    }
  };

  const handleToggleStatus = async (campaignId: string) => {
    const campaign = campaigns.find(c => c.id === campaignId);
    if (!campaign) return;

    try {
      const campaignRef = doc(db, 'campaigns', campaignId);
      await updateDoc(campaignRef, {
        status: campaign.status === 'active' ? 'paused' : 'active',
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error toggling campaign status:', error);
    }
  };

  if (loading) {
    return <div className="flex items-center justify-center h-64">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
    </div>;
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Drip Campaigns</h1>
          <p className="mt-2 text-sm text-gray-700">
            Create and manage automated communication sequences
          </p>
        </div>
        <button
          onClick={() => setShowModal(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Plus className="-ml-0.5 h-5 w-5" />
          New Campaign
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Play className="h-6 w-6 text-green-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Active Campaigns
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {campaigns.filter(c => c.status === 'active').length}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Settings className="h-6 w-6 text-blue-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total Campaigns
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {campaigns.length}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <BarChart2 className="h-6 w-6 text-purple-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Avg. Completion Rate
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {Math.round(
                      campaigns.reduce((acc, c) => acc + (c.metrics?.completionRate || 0), 0) / 
                      campaigns.length
                    )}%
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CampaignList
        campaigns={campaigns}
        onEdit={setEditingCampaign}
        onToggleStatus={handleToggleStatus}
      />

      {(showModal || editingCampaign) && (
        <CampaignModal
          campaign={editingCampaign}
          onClose={() => {
            setShowModal(false);
            setEditingCampaign(null);
          }}
          onSubmit={editingCampaign ? handleUpdateCampaign : handleCreateCampaign}
        />
      )}
    </div>
  );
}