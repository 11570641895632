import React, { useState } from 'react';
import { TrendingUp, Loader2 } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

export function MarketAnalyzer() {
  const { generateResponse, isLoading } = useAI();
  const [analysis, setAnalysis] = useState<string | null>(null);
  const [selectedMarket, setSelectedMarket] = useState('local');

  const handleAnalyze = async () => {
    const prompt = `Analyze current automotive market trends for ${selectedMarket} market. Include:
      1. Current market conditions
      2. Popular vehicle segments
      3. Consumer preferences
      4. Price trends
      5. Recommendations for dealerships`;

    const response = await generateResponse(prompt);
    if (response) {
      setAnalysis(response);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <TrendingUp className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Market Analysis</h2>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Market Scope</label>
            <select
              value={selectedMarket}
              onChange={(e) => setSelectedMarket(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="local">Local Market</option>
              <option value="regional">Regional Market</option>
              <option value="national">National Market</option>
            </select>
          </div>

          <button
            onClick={handleAnalyze}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Analyzing Market...
              </>
            ) : (
              <>
                <TrendingUp className="-ml-1 mr-2 h-5 w-5" />
                Generate Analysis
              </>
            )}
          </button>
        </div>

        {analysis && (
          <div className="mt-6 bg-gray-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Market Analysis</h3>
            <div className="prose prose-blue max-w-none">
              {analysis.split('\n').map((line, index) => (
                <p key={index} className="text-gray-600">{line}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}