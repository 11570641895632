import React from 'react';
import { Mail, Phone, Calendar, Car, Brain, Zap, PhoneCall, Clock } from 'lucide-react';
import { VapiCallButton } from '../calls/VapiCallButton';
import type { Lead } from '../../types/lead';
import { formatDate } from '../../utils/formatDate';

interface LeadCardProps {
  lead: Lead;
  score: number;
  isSelected: boolean;
  onSelect: () => void;
  onStatusChange: (status: Lead['status']) => void;
  onShowScore?: () => void;
  onShowInsights?: () => void;
  onSendEmail: (message: string) => void;
  onSendSMS: (message: string) => void;
  onCallStarted?: () => void;
  onCallEnded?: () => void;
}

export function LeadCard({ 
  lead, 
  score, 
  isSelected, 
  onSelect, 
  onStatusChange,
  onShowScore,
  onShowInsights,
  onSendEmail,
  onSendSMS,
  onCallStarted,
  onCallEnded
}: LeadCardProps) {
  const handleEmailClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (typeof onSendEmail === 'function') {
      onSendEmail('');
    }
  };

  const handleSMSClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (typeof onSendSMS === 'function') {
      onSendSMS('');
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'new': return 'bg-green-100 text-green-800';
      case 'contacted': return 'bg-blue-100 text-blue-800';
      case 'qualified': return 'bg-purple-100 text-purple-800';
      case 'converted': return 'bg-yellow-100 text-yellow-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div 
      className={`bg-white rounded-lg shadow-sm border-2 transition-all cursor-pointer
        ${isSelected ? 'border-blue-500 ring-2 ring-blue-200' : 'border-transparent hover:border-gray-200'}`}
      onClick={onSelect}
    >
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-medium text-gray-900">{lead.name}</h3>
              <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${getStatusColor(lead.status)}`}>
                {lead.status.charAt(0).toUpperCase() + lead.status.slice(1)}
              </span>
            </div>
            <div className="mt-1 flex items-center gap-4 text-sm text-gray-500">
              <div className="flex items-center gap-1">
                <Mail className="h-4 w-4 text-gray-400" />
                {lead.email}
              </div>
              <div className="flex items-center gap-1">
                <Phone className="h-4 w-4 text-gray-400" />
                {lead.phone}
              </div>
            </div>
          </div>
          <div className="text-center">
            <div className={`text-2xl font-bold ${
              score >= 80 ? 'text-green-600' :
              score >= 60 ? 'text-blue-600' :
              score >= 40 ? 'text-yellow-600' :
              'text-red-600'
            }`}>
              {score}
            </div>
            <div className="text-xs text-gray-500">Score</div>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <Car className="h-4 w-4 text-gray-400" />
            {lead.vehicle}
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-600">
            <Calendar className="h-4 w-4 text-gray-400" />
            Created: {formatDate(lead.createdAt)}
          </div>
        </div>

        {lead.engagementMetrics && (
          <div className="mt-4 grid grid-cols-3 gap-4 pt-4 border-t border-gray-200">
            <div className="text-center">
              <div className="text-sm font-medium text-gray-900">{lead.engagementMetrics.emailOpens || 0}</div>
              <div className="text-xs text-gray-500">Email Opens</div>
            </div>
            <div className="text-center">
              <div className="text-sm font-medium text-gray-900">{lead.engagementMetrics.emailClicks || 0}</div>
              <div className="text-xs text-gray-500">Email Clicks</div>
            </div>
            <div className="text-center">
              <div className="text-sm font-medium text-gray-900">{lead.engagementMetrics.websiteVisits || 0}</div>
              <div className="text-xs text-gray-500">Website Visits</div>
            </div>
          </div>
        )}

        <div className="mt-4 flex items-center justify-between border-t border-gray-200 pt-4">
          <div className="flex items-center space-x-4">
            <button
              onClick={handleEmailClick}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <Mail className="h-4 w-4 text-gray-400" />
              Email
            </button>
            <button
              onClick={handleSMSClick}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <Phone className="h-4 w-4 text-gray-400" />
              SMS
            </button>
            <VapiCallButton 
              phoneNumber={lead.phone}
              onCallStarted={onCallStarted}
              onCallEnded={onCallEnded}
            />
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <Clock className="h-4 w-4" />
            Last Contact: {formatDate(lead.lastContact)}
          </div>
        </div>
      </div>
    </div>
  );
}