import { supabase } from '../lib/supabase';
import type { InventoryItem } from '../types/inventory';

export async function getInventory(): Promise<InventoryItem[]> {
  try {
    const { data: inventory, error } = await supabase
      .from('inventory')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    
    return inventory.map(item => ({
      id: item.id,
      stockNumber: item.stock_number,
      year: item.year,
      make: item.make,
      model: item.model,
      trim: item.trim,
      vin: item.vin,
      price: item.price,
      mileage: item.mileage,
      description: item.description,
      status: item.status,
      imageUrl: item.image_url,
      dealership: item.dealership,
      listingUrl: item.listing_url,
      location: item.location,
      bodyStyle: item.body_style,
      condition: item.condition
    }));
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
}

export async function createInventoryItem(item: Omit<InventoryItem, 'id'>): Promise<InventoryItem> {
  try {
    const { data, error } = await supabase
      .from('inventory')
      .insert([{
        stock_number: item.stockNumber,
        year: item.year,
        make: item.make,
        model: item.model,
        trim: item.trim,
        vin: item.vin,
        price: item.price,
        mileage: item.mileage,
        description: item.description,
        status: item.status,
        image_url: item.imageUrl,
        dealership: item.dealership,
        listing_url: item.listingUrl,
        location: item.location,
        body_style: item.bodyStyle,
        condition: item.condition
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating inventory item:', error);
    throw error;
  }
}