import React, { useState } from 'react';
import { Mail, MessageSquare, PenSquare } from 'lucide-react';
import { Link } from 'react-router-dom';
import { CommunicationHistory } from '../components/communications/CommunicationHistory';
import { classNames } from '../utils/classNames';

export function Messages() {
  const [messageType, setMessageType] = useState<'all' | 'sms' | 'email'>('all');

  const tabs = [
    { id: 'all', name: 'All Messages' },
    { id: 'sms', name: 'SMS', icon: MessageSquare },
    { id: 'email', name: 'Email', icon: Mail },
  ];

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Message History</h1>
          <p className="mt-1 text-sm text-gray-500">
            View and manage your communication history
          </p>
        </div>
        <Link
          to="/communications"
          className="inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold rounded-md bg-blue-600 text-white hover:bg-blue-500"
        >
          <PenSquare className="h-5 w-5" />
          New Message
        </Link>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setMessageType(tab.id as 'all' | 'sms' | 'email')}
                className={classNames(
                  tab.id === messageType
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-4 px-8 text-sm font-medium flex-1 justify-center'
                )}
              >
                {tab.icon && (
                  <tab.icon
                    className={classNames(
                      tab.id === messageType
                        ? 'text-blue-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      '-ml-0.5 mr-2 h-5 w-5'
                    )}
                    aria-hidden="true"
                  />
                )}
                {tab.name}
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6">
          <CommunicationHistory messageType={messageType} />
        </div>
      </div>
    </div>
  );
}