import React from 'react';
import { Mail, MessageSquare } from 'lucide-react';
import { Link } from 'react-router-dom';

export function Communications() {
  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">Communications</h1>
        <p className="mt-1 text-sm text-gray-500">
          Choose your preferred method of communication
        </p>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <Link
          to="/email"
          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <Mail className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block text-lg font-semibold text-gray-900">Email</span>
          <span className="mt-2 block text-sm text-gray-500">
            Send emails to leads and customers
          </span>
        </Link>

        <Link
          to="/sms"
          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <MessageSquare className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block text-lg font-semibold text-gray-900">SMS</span>
          <span className="mt-2 block text-sm text-gray-500">
            Send text messages and view conversations
          </span>
        </Link>
      </div>
    </div>
  );
}