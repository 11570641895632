import React, { useState, useEffect } from 'react';
import { Card, Title, BarList } from '@tremor/react';
import { motion } from 'framer-motion';
import { Calendar, Loader2, AlertCircle, ArrowUp, ArrowDown } from 'lucide-react';

interface AnalyticsData {
  value: string;
  count: number;
}

export function WebsiteAnalytics() {
  const [dateRange, setDateRange] = useState({
    from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0]
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [visitorData, setVisitorData] = useState<AnalyticsData[]>([]);

  const API_KEY = 'YAv7z3z6RUZXgASlmYNexLzZ5nSh6ou1BLjkEZBfqTDtbGx6ec9UE1tESPG5Nuzz';
  const WEBSITE_ID = '2';

  useEffect(() => {
    fetchVisitors();
  }, [dateRange]);

  const fetchVisitors = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `https://shawnryder.site/api/v1/stats/${WEBSITE_ID}?name=visitors&from=${dateRange.from}&to=${dateRange.to}`,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_KEY}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch visitor data');
      }

      const data = await response.json();
      setVisitorData(data.data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch visitor data');
    } finally {
      setLoading(false);
    }
  };

  const formattedData = visitorData.map(item => ({
    name: new Date(item.value).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    }),
    value: item.count,
    trend: Math.random() > 0.5 ? 'up' : 'down', // Example trend - replace with actual trend calculation
    change: Math.floor(Math.random() * 20) + 1 // Example change - replace with actual change calculation
  })).sort((a, b) => b.value - a.value);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-lg bg-red-50 p-4">
        <div className="flex">
          <AlertCircle className="h-5 w-5 text-red-400" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-3xl font-bold text-gray-900">Website Analytics</h1>
          <p className="mt-2 text-lg text-gray-600">
            Daily visitor tracking and analysis
          </p>
        </div>
        <div className="mt-4 sm:mt-0">
          <div className="flex items-center space-x-4">
            <input
              type="date"
              value={dateRange.from}
              onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })}
              className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <span className="text-gray-500">to</span>
            <input
              type="date"
              value={dateRange.to}
              onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })}
              className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>

      <Card className="p-6">
        <div className="flex items-center justify-between mb-8">
          <div>
            <Title className="text-xl font-bold">Daily Website Visitors</Title>
          </div>
          <Calendar className="h-5 w-5 text-gray-400" />
        </div>
        
        <div className="space-y-4">
          {formattedData.map((item, index) => (
            <motion.div
              key={item.name}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.05 }}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <div className="flex items-center space-x-4">
                <div className="w-32 text-sm font-medium text-gray-600">
                  {item.name}
                </div>
                <div className="flex-1">
                  <div className="relative h-4 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="absolute top-0 left-0 h-full bg-blue-500 transition-all duration-500"
                      style={{ 
                        width: `${(item.value / Math.max(...formattedData.map(d => d.value))) * 100}%` 
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <span className="text-lg font-semibold text-gray-900">
                  {item.value.toLocaleString()}
                </span>
                <div className={`flex items-center ${
                  item.trend === 'up' ? 'text-green-600' : 'text-red-600'
                }`}>
                  {item.trend === 'up' ? (
                    <ArrowUp className="h-4 w-4" />
                  ) : (
                    <ArrowDown className="h-4 w-4" />
                  )}
                  <span className="text-sm font-medium ml-1">
                    {item.change}%
                  </span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </Card>
    </motion.div>
  );
}