import React from 'react';
import { X, Video } from 'lucide-react';
import { VideoRecorder } from './VideoRecorder';

interface VideoMessageProps {
  onClose: () => void;
  onVideoReady: (url: string) => void;
}

export function VideoMessage({ onClose, onVideoReady }: VideoMessageProps) {
  const handleVideoReady = (url: string) => {
    onVideoReady(url);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                onClick={onClose}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                <div className="flex items-center">
                  <Video className="h-6 w-6 text-blue-600 mr-2" />
                  <h3 className="text-lg font-semibold leading-6 text-gray-900">
                    Record Video Message
                  </h3>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Record a personalized video message to share with your customer.
                </p>
                <div className="mt-4">
                  <VideoRecorder onVideoReady={handleVideoReady} maxDuration={120} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}