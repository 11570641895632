import { VehicleData } from '../types/vehicle';

const API_KEY = 'd2b84ca8d3mshd0157b770301615p1e86ebjsn23677096fef1';
const API_URL = 'https://cars-by-api-ninjas.p.rapidapi.com/v1/cars';

export async function searchVehicles(params: {
  make?: string;
  model?: string;
  year?: string;
  fuel?: string;
}): Promise<VehicleData[]> {
  try {
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value) queryParams.append(key, value);
    });

    const response = await fetch(`${API_URL}?${queryParams.toString()}`, {
      headers: {
        'X-RapidAPI-Key': API_KEY,
        'X-RapidAPI-Host': 'cars-by-api-ninjas.p.rapidapi.com'
      }
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || `Failed to fetch data (${response.status})`);
    }

    const data = await response.json();
    
    if (!Array.isArray(data)) {
      throw new Error('Invalid response format from API');
    }

    return data;
  } catch (error) {
    console.error('Error fetching vehicle data:', error);
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to fetch vehicle data. Please try again.'
    );
  }
}

export async function getVehicleDetails(vin: string): Promise<VehicleData | null> {
  try {
    const response = await fetch(`${API_URL}/vin/${vin}`, {
      headers: {
        'X-RapidAPI-Key': API_KEY,
        'X-RapidAPI-Host': 'cars-by-api-ninjas.p.rapidapi.com'
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch vehicle details (${response.status})`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching vehicle details:', error);
    return null;
  }
}