import React from 'react';
import { NotificationItem } from './NotificationItem';
import type { Notification } from '../../types/notification';

interface NotificationListProps {
  notifications: Notification[];
  onMarkAsRead: (id: string) => void;
  onRemove: (id: string) => void;
}

export function NotificationList({ notifications, onMarkAsRead, onRemove }: NotificationListProps) {
  if (notifications.length === 0) {
    return (
      <div className="py-4 text-center text-sm text-gray-500">
        No notifications
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-200">
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onMarkAsRead={onMarkAsRead}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
}