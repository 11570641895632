import React, { useState } from 'react';
import { Search, Filter, Car, Fuel, Gauge, Calendar } from 'lucide-react';
import { Card, Title, Text } from '@tremor/react';

interface CarData {
  city_mpg: number;
  class: string;
  combination_mpg: number;
  cylinders: number;
  displacement: number;
  drive: string;
  fuel_type: string;
  highway_mpg: number;
  make: string;
  model: string;
  transmission: string;
  year: number;
}

export function CarSearch() {
  const [searchParams, setSearchParams] = useState({
    make: '',
    model: '',
    year: '',
    fuel: '',
  });
  const [results, setResults] = useState<CarData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const queryParams = new URLSearchParams();
      if (searchParams.make) queryParams.append('make', searchParams.make);
      if (searchParams.model) queryParams.append('model', searchParams.model);
      if (searchParams.year) queryParams.append('year', searchParams.year);
      if (searchParams.fuel) queryParams.append('fuel_type', searchParams.fuel);

      const response = await fetch(
        `https://api.api-ninjas.com/v1/cars?${queryParams.toString()}`,
        {
          headers: {
            'X-Api-Key': 'd2b84ca8d3mshd0157b770301615p1e86ebjsn23677096fef1'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch car data');
      }

      const data = await response.json();
      setResults(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const getTransmissionType = (code: string) => {
    switch (code.toLowerCase()) {
      case 'a': return 'Automatic';
      case 'm': return 'Manual';
      default: return code;
    }
  };

  const getDriveType = (code: string) => {
    switch (code.toLowerCase()) {
      case 'fwd': return 'Front-Wheel Drive';
      case 'rwd': return 'Rear-Wheel Drive';
      case 'awd': return 'All-Wheel Drive';
      case '4wd': return 'Four-Wheel Drive';
      default: return code;
    }
  };

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Car Search</h1>
          <p className="mt-2 text-sm text-gray-700">
            Search and compare vehicle specifications
          </p>
        </div>
      </div>

      <div className="bg-white shadow-soft rounded-xl p-6">
        <form onSubmit={handleSearch} className="space-y-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <div>
              <label htmlFor="make" className="block text-sm font-medium text-gray-700">
                Make
              </label>
              <input
                type="text"
                id="make"
                value={searchParams.make}
                onChange={(e) => setSearchParams({ ...searchParams, make: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                placeholder="e.g., Toyota"
              />
            </div>

            <div>
              <label htmlFor="model" className="block text-sm font-medium text-gray-700">
                Model
              </label>
              <input
                type="text"
                id="model"
                value={searchParams.model}
                onChange={(e) => setSearchParams({ ...searchParams, model: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                placeholder="e.g., Camry"
              />
            </div>

            <div>
              <label htmlFor="year" className="block text-sm font-medium text-gray-700">
                Year
              </label>
              <input
                type="number"
                id="year"
                value={searchParams.year}
                onChange={(e) => setSearchParams({ ...searchParams, year: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                placeholder="e.g., 2024"
              />
            </div>

            <div>
              <label htmlFor="fuel" className="block text-sm font-medium text-gray-700">
                Fuel Type
              </label>
              <select
                id="fuel"
                value={searchParams.fuel}
                onChange={(e) => setSearchParams({ ...searchParams, fuel: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              >
                <option value="">Any</option>
                <option value="gas">Gasoline</option>
                <option value="diesel">Diesel</option>
                <option value="electricity">Electric</option>
                <option value="hybrid">Hybrid</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
            >
              {loading ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  Searching...
                </>
              ) : (
                <>
                  <Search className="h-4 w-4 mr-2" />
                  Search
                </>
              )}
            </button>
          </div>
        </form>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {results.length > 0 && (
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {results.map((car, index) => (
            <Card key={index} className="overflow-hidden">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <Title className="capitalize">
                      {car.make} {car.model}
                    </Title>
                    <Text className="text-gray-500">
                      {car.year} - {car.class}
                    </Text>
                  </div>
                  <Car className="h-8 w-8 text-brand-600" />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="flex items-center text-sm">
                      <Gauge className="h-4 w-4 text-gray-400 mr-2" />
                      <span className="text-gray-600">Engine:</span>
                      <span className="ml-2 font-medium">
                        {car.cylinders} cyl, {car.displacement}L
                      </span>
                    </div>
                    <div className="flex items-center text-sm">
                      <Fuel className="h-4 w-4 text-gray-400 mr-2" />
                      <span className="text-gray-600">Fuel Type:</span>
                      <span className="ml-2 font-medium capitalize">
                        {car.fuel_type}
                      </span>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="text-sm">
                      <span className="text-gray-600">Transmission:</span>
                      <span className="ml-2 font-medium">
                        {getTransmissionType(car.transmission)}
                      </span>
                    </div>
                    <div className="text-sm">
                      <span className="text-gray-600">Drive:</span>
                      <span className="ml-2 font-medium">
                        {getDriveType(car.drive)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-4 pt-4 border-t border-gray-200">
                  <div className="grid grid-cols-3 gap-4 text-center">
                    <div>
                      <div className="text-2xl font-bold text-brand-600">
                        {car.city_mpg}
                      </div>
                      <div className="text-xs text-gray-500">City MPG</div>
                    </div>
                    <div>
                      <div className="text-2xl font-bold text-brand-600">
                        {car.highway_mpg}
                      </div>
                      <div className="text-xs text-gray-500">Highway MPG</div>
                    </div>
                    <div>
                      <div className="text-2xl font-bold text-brand-600">
                        {car.combination_mpg}
                      </div>
                      <div className="text-xs text-gray-500">Combined MPG</div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}

      {results.length === 0 && !loading && !error && (
        <div className="text-center py-12">
          <Car className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No results</h3>
          <p className="mt-1 text-sm text-gray-500">
            Try adjusting your search parameters
          </p>
        </div>
      )}
    </div>
  );
}