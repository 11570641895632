import React from 'react';
import { BarChart, Card, Title, Text } from '@tremor/react';
import { Car, Settings, TrendingUp } from 'lucide-react';
import type { InventoryItem } from '../../types/inventory';

interface ModelStats {
  Model: string;
  Count: number;
  Average_Year: number;
  Total_Service_Count: number;
}

interface InventoryDashboardProps {
  inventory: InventoryItem[];
  modelStats: ModelStats[];
}

export function InventoryDashboard({ inventory, modelStats }: InventoryDashboardProps) {
  const totalVehicles = inventory.length;
  const availableVehicles = inventory.filter(item => item.status === 'Available').length;
  const totalValue = inventory.reduce((sum, item) => sum + item.price, 0);

  const chartData = modelStats.map(stat => ({
    name: stat.Model,
    'Vehicle Count': stat.Count,
    'Service Count': stat.Total_Service_Count,
    'Avg Year': Math.round(stat.Average_Year * 10) / 10
  }));

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <Card className="bg-gradient-to-br from-blue-50 to-blue-100">
          <div className="flex items-center justify-between">
            <div>
              <Text className="text-sm text-blue-600">Total Inventory</Text>
              <Title className="text-2xl font-bold text-blue-900">{totalVehicles}</Title>
            </div>
            <Car className="h-8 w-8 text-blue-500" />
          </div>
          <Text className="mt-2 text-sm text-blue-600">
            {availableVehicles} vehicles available
          </Text>
        </Card>

        <Card className="bg-gradient-to-br from-green-50 to-green-100">
          <div className="flex items-center justify-between">
            <div>
              <Text className="text-sm text-green-600">Total Value</Text>
              <Title className="text-2xl font-bold text-green-900">
                ${totalValue.toLocaleString()}
              </Title>
            </div>
            <TrendingUp className="h-8 w-8 text-green-500" />
          </div>
          <Text className="mt-2 text-sm text-green-600">
            Avg: ${(totalValue / totalVehicles).toLocaleString()}
          </Text>
        </Card>

        <Card className="bg-gradient-to-br from-purple-50 to-purple-100">
          <div className="flex items-center justify-between">
            <div>
              <Text className="text-sm text-purple-600">Service Records</Text>
              <Title className="text-2xl font-bold text-purple-900">
                {modelStats.reduce((sum, stat) => sum + stat.Total_Service_Count, 0)}
              </Title>
            </div>
            <Settings className="h-8 w-8 text-purple-500" />
          </div>
          <Text className="mt-2 text-sm text-purple-600">
            Across all models
          </Text>
        </Card>
      </div>

      <Card>
        <Title>Model Distribution & Service History</Title>
        <Text>Vehicle count and service records by model</Text>
        <BarChart
          className="mt-6 h-80"
          data={chartData}
          index="name"
          categories={['Vehicle Count', 'Service Count']}
          colors={['blue', 'purple']}
          valueFormatter={(value) => value.toString()}
          stack={false}
          yAxisWidth={48}
        />
      </Card>
    </div>
  );
}