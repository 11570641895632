import React, { useState } from 'react';
import { Shield, Search, ThumbsUp, ThumbsDown, Loader2 } from 'lucide-react';
import { useAI } from '../ai/AIContext';

interface Objection {
  id: string;
  category: string;
  objection: string;
  response: string;
  effectiveness: number;
}

const COMMON_OBJECTIONS: Objection[] = [
  {
    id: '1',
    category: 'Price',
    objection: "The price is too high compared to other dealerships",
    response: "I understand your concern about the price. Let me show you our vehicle's total value proposition, including our comprehensive warranty, service benefits, and the specific features that set it apart from competitors. We can also explore different financing options to find a solution that works for your budget.",
    effectiveness: 85
  },
  {
    id: '2',
    category: 'Timing',
    objection: "I need to think about it and discuss with my family",
    response: "That's a very reasonable approach. Family input is important for such a significant decision. While you're discussing it, would you like me to prepare a detailed summary of the vehicle's features, pricing, and financing options? This will help you have a more informed discussion with your family.",
    effectiveness: 90
  }
];

export function ObjectionHandler() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [customObjection, setCustomObjection] = useState('');
  const { generateResponse, isLoading } = useAI();
  const [aiResponse, setAiResponse] = useState<string | null>(null);

  const categories = ['all', 'price', 'timing', 'features', 'competition', 'trade-in'];

  const filteredObjections = COMMON_OBJECTIONS.filter(obj => {
    const matchesSearch = obj.objection.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         obj.response.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || obj.category.toLowerCase() === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const handleCustomObjection = async () => {
    if (!customObjection.trim()) return;

    const prompt = `Generate a professional and effective response to this sales objection:
    "${customObjection}"
    
    Include:
    1. Acknowledgment of the concern
    2. Value proposition
    3. Specific benefits
    4. Next steps or call to action`;

    const response = await generateResponse(prompt);
    if (response) {
      setAiResponse(response);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <Shield className="h-6 w-6 text-blue-500 mr-2" />
          <h2 className="text-xl font-semibold text-gray-900">Objection Handler</h2>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-1 space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search objections..."
              className="pl-10 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div className="flex flex-wrap gap-2">
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${
                  selectedCategory === category
                    ? 'bg-blue-100 text-blue-800'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>

          <div className="space-y-4">
            {filteredObjections.map(obj => (
              <div
                key={obj.id}
                className="p-4 rounded-lg border border-gray-200 hover:border-blue-300 cursor-pointer"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm font-medium text-gray-900">{obj.category}</span>
                  <div className="flex items-center">
                    <span className="text-sm text-gray-500 mr-2">{obj.effectiveness}% effective</span>
                    <div className="flex space-x-1">
                      <ThumbsUp className="h-4 w-4 text-green-500" />
                      <ThumbsDown className="h-4 w-4 text-gray-300" />
                    </div>
                  </div>
                </div>
                <p className="text-sm text-gray-600">{obj.objection}</p>
                <div className="mt-2 p-3 bg-gray-50 rounded text-sm text-gray-700">
                  {obj.response}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="lg:col-span-2">
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Custom Objection Handler</h3>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Enter Customer Objection
              </label>
              <textarea
                value={customObjection}
                onChange={(e) => setCustomObjection(e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Enter the customer's objection..."
              />
            </div>

            <button
              onClick={handleCustomObjection}
              disabled={isLoading || !customObjection.trim()}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
            >
              {isLoading ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                  Generating Response...
                </>
              ) : (
                'Get Response'
              )}
            </button>

            {aiResponse && (
              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-900 mb-2">Suggested Response:</h4>
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="prose prose-sm max-w-none text-gray-600">
                    {aiResponse.split('\n').map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}