import React, { useState, useEffect } from 'react';
import { Mail, Save, Loader2 } from 'lucide-react';
import { getSettings, updateSettings, createSettings } from '../../lib/supabase/settings';

export function EmailSettings() {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);
  const [error, setError] = useState(null);

  const loadSettings = async () => {
    try {
      const emailSettings = await getSettings('email');
      if (!emailSettings) {
        const id = await createSettings('email', {
          enabled: true,
          server: 'smtp.gmail.com',
          port: 587,
          username: 'leads@gmail.com'
        });
        setSettings({ ...settings, id });
      } else {
        setSettings(emailSettings);
      }
    } catch (err) {
      setError('Failed to load email settings');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-lg font-medium text-gray-900">Email Integration</h2>
          <p className="mt-1 text-sm text-gray-500">
            Gmail integration for ADF/XML lead processing
          </p>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-900">Connected Account</p>
              <p className="text-sm text-gray-500">leads@gmail.com</p>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={loadSettings}
                disabled={loading}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50"
              >
                {loading ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Loading...
                  </>
                ) : (
                  <>
                    <Mail className="h-4 w-4" />
                    Refresh Settings
                  </>
                )}
              </button>
            </div>
          </div>

          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>{error}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-900">Email Settings</h3>
            <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value="leads@gmail.com"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  disabled
                />
              </div>
              <div>
                <label htmlFor="server" className="block text-sm font-medium text-gray-700">
                  IMAP Server
                </label>
                <input
                  type="text"
                  id="server"
                  value="imap.gmail.com"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-900">Recent Email Activity</h3>
            <div className="mt-2 bg-gray-50 rounded-lg p-4">
              <p className="text-sm text-gray-500">
                Demo Mode: Email processing is simulated. No actual emails will be sent or received.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}