import OpenAI from 'openai';

const OPENAI_API_KEY = import.meta.env.VITE_OPENAI_API_KEY;

if (!OPENAI_API_KEY) {
  throw new Error('OpenAI API key is required. Please set VITE_OPENAI_API_KEY environment variable.');
}

export const openai = new OpenAI({
  apiKey: OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

// Helper function for common error handling
export async function generateAIResponse(prompt: string, options = {}) {
  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "You are an expert automotive sales and marketing assistant."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      ...options
    });

    return completion.choices[0]?.message?.content;
  } catch (error) {
    console.error('OpenAI API Error:', error);
    throw new Error('Failed to generate AI response. Please try again.');
  }
}