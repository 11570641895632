import React from 'react';
import { Card, Title, Text, BarChart, DonutChart } from '@tremor/react';
import { 
  TrendingUp, 
  TrendingDown,
  Users,
  Car,
  Clock,
  Calendar,
  DollarSign
} from 'lucide-react';

interface MetricsData {
  totalLeads: number;
  conversionRate: number;
  avgResponseTime: number;
  totalSales: number;
  revenue: number;
  metrics: {
    date: string;
    leads: number;
    conversions: number;
    revenue: number;
  }[];
  sourceDistribution: {
    source: string;
    value: number;
  }[];
}

interface MetricsDashboardProps {
  data: MetricsData;
  dateRange: {
    from: Date;
    to: Date;
  };
}

export function MetricsDashboard({ data, dateRange }: MetricsDashboardProps) {
  const formatCurrency = (value: number) => 
    new Intl.NumberFormat('en-CA', { 
      style: 'currency', 
      currency: 'CAD',
      maximumFractionDigits: 0 
    }).format(value);

  const getPercentageChange = (current: number, previous: number) => {
    if (previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  const stats = [
    {
      name: 'Total Leads',
      value: data.totalLeads,
      change: getPercentageChange(data.totalLeads, data.totalLeads * 0.8),
      icon: Users,
      color: 'blue'
    },
    {
      name: 'Conversion Rate',
      value: `${data.conversionRate}%`,
      change: getPercentageChange(data.conversionRate, data.conversionRate * 0.9),
      icon: TrendingUp,
      color: 'green'
    },
    {
      name: 'Avg Response Time',
      value: `${data.avgResponseTime}h`,
      change: getPercentageChange(data.avgResponseTime, data.avgResponseTime * 1.2),
      icon: Clock,
      color: 'purple',
      inverse: true
    },
    {
      name: 'Total Sales',
      value: data.totalSales,
      change: getPercentageChange(data.totalSales, data.totalSales * 0.85),
      icon: Car,
      color: 'indigo'
    },
    {
      name: 'Revenue',
      value: formatCurrency(data.revenue),
      change: getPercentageChange(data.revenue, data.revenue * 0.9),
      icon: DollarSign,
      color: 'emerald'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-5">
        {stats.map((stat) => (
          <Card 
            key={stat.name}
            className={`bg-gradient-to-br from-${stat.color}-50 to-${stat.color}-100`}
          >
            <div className="flex items-center justify-between">
              <div>
                <Text className={`text-sm font-medium text-${stat.color}-600`}>
                  {stat.name}
                </Text>
                <Title className="mt-2 text-2xl font-bold text-gray-900">
                  {stat.value}
                </Title>
              </div>
              <stat.icon className={`h-12 w-12 text-${stat.color}-500 opacity-50`} />
            </div>
            <div className="mt-4 flex items-center space-x-2">
              {stat.change > 0 ? (
                <TrendingUp className={`h-4 w-4 ${stat.inverse ? 'text-red-500' : 'text-green-500'}`} />
              ) : (
                <TrendingDown className={`h-4 w-4 ${stat.inverse ? 'text-green-500' : 'text-red-500'}`} />
              )}
              <Text className={`text-sm ${
                (stat.change > 0 && !stat.inverse) || (stat.change < 0 && stat.inverse)
                  ? 'text-green-600'
                  : 'text-red-600'
              }`}>
                {Math.abs(stat.change).toFixed(1)}%
              </Text>
              <Text className="text-sm text-gray-500">vs previous period</Text>
            </div>
          </Card>
        ))}
      </div>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <Card>
          <Title>Lead Performance</Title>
          <Text>Leads and conversions over time</Text>
          <BarChart
            className="mt-6 h-80"
            data={data.metrics}
            index="date"
            categories={["leads", "conversions"]}
            colors={["blue", "green"]}
            valueFormatter={(value) => value.toString()}
            stack={false}
            yAxisWidth={48}
          />
        </Card>

        <Card>
          <Title>Lead Sources Distribution</Title>
          <Text>Breakdown of lead sources</Text>
          <DonutChart
            className="mt-6 h-80"
            data={data.sourceDistribution}
            category="value"
            index="source"
            valueFormatter={(value) => `${value}%`}
            colors={["violet", "indigo", "blue", "cyan", "teal"]}
          />
        </Card>
      </div>

      <Card>
        <Title>Revenue Trends</Title>
        <Text>Monthly revenue and growth</Text>
        <BarChart
          className="mt-6 h-80"
          data={data.metrics}
          index="date"
          categories={["revenue"]}
          colors={["emerald"]}
          valueFormatter={(value) => formatCurrency(value)}
          yAxisWidth={80}
        />
      </Card>
    </div>
  );
}