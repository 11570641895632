import React, { useState } from 'react';
import { Mail, Phone, Car, Calendar, MessageSquare, Clock, User, ChevronRight, MapPin, Star } from 'lucide-react';
import { CustomerFilters } from './CustomerFilters';
import { SMSComposerModal } from '../communications/SMSComposerModal';
import { useAuth } from '../../context/AuthContext';
import type { Customer } from '../../types/customer';
import { sampleCustomers } from '../../data/sampleCustomers';
import { formatDate } from '../../utils/formatDate';
import { motion } from 'framer-motion';

import { supabase } from '../../lib/supabase';
import { sendReviewRequest } from '../../utils/sendReviewRequest';

export function CustomerList() {
  const { user } = useAuth();
  const [customers, setCustomers] = useState<Customer[]>(sampleCustomers);
  const [loading, setLoading] = useState(false);
  const [expandedCustomer, setExpandedCustomer] = useState<string | null>(null);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  const handleSendSMS = (customer: Customer) => {
    setSelectedCustomer(customer);
    setShowSMSModal(true);
  };

  const handleSendReviewRequest = async (customer: Customer) => {
    try {
      await sendReviewRequest(customer);
      // Refresh customer list to show updated review status
      const { data } = await supabase
        .from('customers')
        .select('*')
        .order('created_at', { ascending: false });
      if (data) setCustomers(data);
    } catch (error) {
      console.error('Error sending review request:', error);
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-xl overflow-hidden">
      <ul role="list" className="divide-y divide-gray-200">
        {customers.map((customer) => (
          <motion.li
            key={customer.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="group relative hover:bg-gray-50 transition-colors duration-200"
          >
            <div className="flex items-start gap-6 p-6">
              <div className="flex-shrink-0">
                <div className="h-14 w-14 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center text-white font-semibold text-lg">
                  {customer.firstName[0]}{customer.lastName[0]}
                </div>
              </div>
              
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">
                      {customer.firstName} {customer.lastName}
                    </h3>
                    <div className="mt-1 flex flex-wrap items-center gap-4 text-sm text-gray-600">
                      <div className="flex items-center gap-1.5">
                        <Mail className="h-4 w-4 text-gray-400" />
                        {customer.email}
                      </div>
                      <div className="flex items-center gap-1.5">
                        <Phone className="h-4 w-4 text-gray-400" />
                        {customer.phone}
                      </div>
                    </div>
                  </div>
                  
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => handleSendSMS(customer)}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <MessageSquare className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleSendReviewRequest(customer)}
                      className={`rounded-full p-2 ${
                        customer.reviewStatus?.sent 
                          ? 'text-yellow-400 hover:text-yellow-500' 
                          : 'text-gray-400 hover:text-gray-600'
                      }`}
                      title={customer.reviewStatus?.sent ? 'Review link sent' : 'Send review link'}
                    >
                      <Star className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => {/* TODO: Implement email */}}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <Mail className="h-5 w-5" />
                    </button>
                    <ChevronRight className="h-5 w-5 text-gray-400 group-hover:text-gray-600 transition-colors" />
                  </div>
                </div>
                
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  <div className="flex items-center gap-2 text-sm">
                    <Car className="h-4 w-4 text-gray-400" />
                    <span className="text-gray-900 font-medium">
                      {customer.vehicle.year} {customer.vehicle.make} {customer.vehicle.model}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-gray-600">
                    <MapPin className="h-4 w-4 text-gray-400" />
                    <span>{customer.address}</span>
                  </div>
                  <div className="flex items-center gap-2 text-sm text-gray-500">
                    <Clock className="h-4 w-4" />
                    <span>Last Contact: {formatDate(customer.lastContact)}</span>
                  </div>
                  {customer.reviewStatus?.sent && (
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      <Star className="h-4 w-4 text-yellow-400" />
                      <span>
                        Review link sent: {formatDate(customer.reviewStatus.sentDate || '')}
                        {customer.reviewStatus.reviewed && ' (Reviewed)'}
                      </span>
                    </div>
                  )}
                </div>
                
                {expandedCustomer === customer.id && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="mt-4 pt-4 border-t border-gray-200"
                  >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div>
                        <h4 className="text-sm font-medium text-gray-900">Customer Details</h4>
                        <dl className="mt-2 text-sm text-gray-600">
                          <div className="flex items-center gap-2">
                            <Calendar className="h-4 w-4 text-gray-400" />
                            <dt className="inline">Birthday:</dt>
                            <dd className="inline">{new Date(customer.birthday).toLocaleDateString()}</dd>
                          </div>
                          {customer.anniversary && (
                            <div className="flex items-center gap-2 mt-1">
                              <Calendar className="h-4 w-4 text-gray-400" />
                              <dt className="inline">Anniversary:</dt>
                              <dd className="inline">{new Date(customer.anniversary).toLocaleDateString()}</dd>
                            </div>
                          )}
                        </dl>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-900">Vehicle Information</h4>
                        <dl className="mt-2 text-sm text-gray-600">
                          <div className="flex items-center gap-2">
                            <Car className="h-4 w-4 text-gray-400" />
                            <dt className="inline">VIN:</dt>
                            <dd className="inline font-mono">{customer.vehicle.vin}</dd>
                          </div>
                          <div className="flex items-center gap-2 mt-1">
                            <Calendar className="h-4 w-4 text-gray-400" />
                            <dt className="inline">Purchase Date:</dt>
                            <dd className="inline">{new Date(customer.purchaseDate).toLocaleDateString()}</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
            
            <button
              onClick={() => setExpandedCustomer(
                expandedCustomer === customer.id ? null : customer.id
              )}
              className="absolute bottom-2 right-2 text-sm text-blue-600 hover:text-blue-500"
            >
              {expandedCustomer === customer.id ? 'Show Less' : 'Show More'}
            </button>
          </motion.li>
        ))}
      </ul>
    </div>
  );
}