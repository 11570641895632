import React from 'react';
import { Card, Title, Text, BarList } from '@tremor/react';
import { DollarSign } from 'lucide-react';

interface SalesFunnelProps {
  data: {
    stage: string;
    count: number;
    value: number;
    color: string;
  }[];
  totalLeads: number;
}

export function SalesFunnel({ data, totalLeads }: SalesFunnelProps) {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const getPercentage = (count: number) => {
    return `${Math.round((count / totalLeads) * 100)}%`;
  };

  const funnelData = data.map(item => ({
    name: item.stage,
    value: item.count,
    icon: () => (
      <div className="flex items-center">
        <span className={`w-3 h-3 rounded-full mr-2 ${item.color}`} />
        <span className="font-medium">{item.stage}</span>
        <span className="ml-2 text-gray-500">({getPercentage(item.count)})</span>
      </div>
    ),
    color: item.color,
  }));

  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

  return (
    <Card className="bg-gradient-to-br from-indigo-50 to-blue-50">
      <div className="flex items-center justify-between">
        <div>
          <Title className="text-xl font-semibold text-gray-900">Sales Funnel</Title>
          <Text className="mt-2 text-gray-600">
            Conversion through sales stages
          </Text>
        </div>
        <div className="text-right">
          <div className="flex items-center text-gray-500">
            <DollarSign className="h-5 w-5" />
            <span className="text-sm">Pipeline Value</span>
          </div>
          <p className="text-2xl font-bold text-gray-900">{formatCurrency(totalValue)}</p>
        </div>
      </div>

      <div className="mt-8">
        <BarList
          data={funnelData}
          valueFormatter={(value) => value.toString()}
          showAnimation={true}
          color="blue"
        />
      </div>

      <div className="mt-8 grid grid-cols-2 gap-4 sm:grid-cols-4">
        {data.map((item) => (
          <div key={item.stage} className="bg-white rounded-lg p-4 shadow-sm">
            <div className="flex items-center">
              <div className={`w-3 h-3 rounded-full ${item.color}`} />
              <p className="ml-2 text-sm font-medium text-gray-600">{item.stage}</p>
            </div>
            <p className="mt-2 text-2xl font-bold text-gray-900">{formatCurrency(item.value)}</p>
            <p className="mt-1 text-sm text-gray-500">{item.count} leads</p>
          </div>
        ))}
      </div>
    </Card>
  );
}