import React, { useState } from 'react';
import { Save, Loader2, Plus, X } from 'lucide-react';
import type { Campaign, InterestTag, CampaignSegment } from '../../types/campaign';

interface InterestCampaignFormProps {
  onSubmit: (campaign: Partial<Campaign>) => Promise<void>;
  onCancel: () => void;
  initialData?: Partial<Campaign>;
}

export function InterestCampaignForm({ onSubmit, onCancel, initialData }: InterestCampaignFormProps) {
  const [formData, setFormData] = useState<Partial<Campaign>>(initialData || {
    name: '',
    description: '',
    type: 'interest',
    status: 'draft',
    segment: {
      id: '',
      name: '',
      interests: [],
      filters: [],
      customerCount: 0
    },
    schedule: {
      type: 'immediate'
    },
    settings: {
      allowOptOut: true,
      trackClicks: true,
      trackOpens: true,
      enablePersonalization: true
    }
  });
  const [isSaving, setIsSaving] = useState(false);

  const interestOptions: { value: InterestTag; label: string }[] = [
    { value: 'electric', label: 'Electric Vehicles' },
    { value: 'luxury', label: 'Luxury Models' },
    { value: 'suv', label: 'SUVs' },
    { value: 'sports', label: 'Sports Cars' },
    { value: 'family', label: 'Family Vehicles' },
    { value: 'eco', label: 'Eco-Friendly' },
    { value: 'performance', label: 'Performance Vehicles' }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      await onSubmit(formData);
    } finally {
      setIsSaving(false);
    }
  };

  const updateSegment = (updates: Partial<CampaignSegment>) => {
    setFormData(prev => ({
      ...prev,
      segment: {
        ...prev.segment!,
        ...updates
      }
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Campaign Name
          </label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            required
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Target Interests</label>
          <div className="mt-2 space-y-2">
            {interestOptions.map((option) => (
              <label key={option.value} className="inline-flex items-center mr-4">
                <input
                  type="checkbox"
                  checked={formData.segment?.interests.includes(option.value)}
                  onChange={(e) => {
                    const interests = e.target.checked
                      ? [...(formData.segment?.interests || []), option.value]
                      : formData.segment?.interests.filter(i => i !== option.value) || [];
                    updateSegment({ interests });
                  }}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">{option.label}</span>
              </label>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Schedule</label>
          <div className="mt-2">
            <select
              value={formData.schedule?.type}
              onChange={(e) => setFormData({
                ...formData,
                schedule: { ...formData.schedule!, type: e.target.value as any }
              })}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            >
              <option value="immediate">Send Immediately</option>
              <option value="scheduled">Schedule for Later</option>
              <option value="recurring">Recurring</option>
            </select>
          </div>
        </div>

        {formData.schedule?.type !== 'immediate' && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="datetime-local"
                id="startDate"
                value={formData.schedule?.startDate}
                onChange={(e) => setFormData({
                  ...formData,
                  schedule: { ...formData.schedule!, startDate: e.target.value }
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>

            {formData.schedule?.type === 'recurring' && (
              <div>
                <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">
                  Frequency
                </label>
                <select
                  id="frequency"
                  value={formData.schedule?.frequency}
                  onChange={(e) => setFormData({
                    ...formData,
                    schedule: { ...formData.schedule!, frequency: e.target.value as any }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
            )}
          </div>
        )}

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Settings</label>
          <div className="space-y-2">
            {Object.entries(formData.settings || {}).map(([key, value]) => (
              <label key={key} className="inline-flex items-center mr-6">
                <input
                  type="checkbox"
                  checked={value}
                  onChange={(e) => setFormData({
                    ...formData,
                    settings: { ...formData.settings!, [key]: e.target.checked }
                  })}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">
                  {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <X className="h-4 w-4 mr-1.5" />
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSaving}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {isSaving ? (
            <Loader2 className="h-4 w-4 mr-1.5 animate-spin" />
          ) : (
            <Save className="h-4 w-4 mr-1.5" />
          )}
          {isSaving ? 'Saving...' : 'Save Campaign'}
        </button>
      </div>
    </form>
  );
}