import React, { useState } from 'react';
import { Mic, Phone, Star, Copy, ThumbsUp } from 'lucide-react';
import { useAI } from '../ai/AIContext';

interface Script {
  id: string;
  title: string;
  content: string;
  scenario: string;
  rating: number;
}

const DEFAULT_SCRIPTS: Script[] = [
  {
    id: '1',
    title: 'Initial Contact',
    scenario: 'First time reaching out to a new lead',
    content: `Hi [Name], this is [Your Name] from [Dealership]. I noticed you recently expressed interest in the [Vehicle Model]. I'd love to learn more about what you're looking for in your next vehicle. Is this a good time to chat?`,
    rating: 4.8
  },
  {
    id: '2',
    title: 'Follow-up Call',
    scenario: 'Following up after initial contact',
    content: `Hi [Name], I'm following up on our conversation about the [Vehicle Model]. Have you had a chance to think about scheduling a test drive? I have some great time slots available this week.`,
    rating: 4.5
  }
];

export function CallScripts() {
  const [scripts, setScripts] = useState<Script[]>(DEFAULT_SCRIPTS);
  const [selectedScript, setSelectedScript] = useState<Script | null>(null);
  const [customization, setCustomization] = useState('');
  const { generateResponse, isLoading } = useAI();

  const handleCustomize = async () => {
    if (!selectedScript) return;
    
    const prompt = `Customize this sales script for ${customization}:\n${selectedScript.content}`;
    const response = await generateResponse(prompt);
    if (response) {
      setSelectedScript({
        ...selectedScript,
        content: response
      });
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Call Scripts</h2>
        <div className="flex items-center space-x-2">
          <Phone className="h-5 w-5 text-blue-500" />
          <span className="text-sm text-gray-500">Sales Call Assistant</span>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-1 space-y-4">
          {scripts.map(script => (
            <div
              key={script.id}
              onClick={() => setSelectedScript(script)}
              className={`p-4 rounded-lg border cursor-pointer transition-colors ${
                selectedScript?.id === script.id
                  ? 'border-blue-500 bg-blue-50'
                  : 'border-gray-200 hover:border-blue-300'
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <h3 className="font-medium text-gray-900">{script.title}</h3>
                <div className="flex items-center">
                  <Star className="h-4 w-4 text-yellow-400" />
                  <span className="ml-1 text-sm text-gray-600">{script.rating}</span>
                </div>
              </div>
              <p className="text-sm text-gray-500">{script.scenario}</p>
            </div>
          ))}
        </div>

        <div className="lg:col-span-2">
          {selectedScript ? (
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">{selectedScript.title}</h3>
                <button
                  onClick={() => handleCopy(selectedScript.content)}
                  className="inline-flex items-center text-sm text-blue-600 hover:text-blue-700"
                >
                  <Copy className="h-4 w-4 mr-1" />
                  Copy Script
                </button>
              </div>

              <div className="bg-gray-50 rounded-lg p-4">
                <p className="text-gray-700 whitespace-pre-wrap">{selectedScript.content}</p>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Customize Script
                </label>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={customization}
                    onChange={(e) => setCustomization(e.target.value)}
                    placeholder="e.g., luxury vehicle buyer, first-time buyer..."
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <button
                    onClick={handleCustomize}
                    disabled={isLoading || !customization}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
                  >
                    <Mic className="h-4 w-4 mr-2" />
                    Customize
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-end space-x-2">
                <button className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700">
                  <ThumbsUp className="h-4 w-4 mr-1" />
                  Helpful
                </button>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">Select a script to view</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}