import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCmDbPxmdLAOhoYGjm3hdxMOjI_uU0zKqo",
  authDomain: "crmdemo-d59f2.firebaseapp.com", 
  projectId: "crmdemo-d59f2",
  storageBucket: "crmdemo-d59f2.appspot.com",
  messagingSenderId: "477198573918",
  appId: "1:477198573918:web:6507031217bb3674b9efcf",
  measurementId: "G-MX5VE5R1YB"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;