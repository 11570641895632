import React from 'react';
import { ChevronUp, ChevronDown, ExternalLink } from 'lucide-react';
import type { InventoryItem } from '../../types/inventory';
import { formatCurrency } from '../../utils/formatters';

interface InventoryTableProps {
  inventory: InventoryItem[];
  sortField: keyof InventoryItem;
  sortDirection: 'asc' | 'desc';
  onSort: (field: keyof InventoryItem) => void;
}

export function InventoryTable({ inventory, sortField, sortDirection, onSort }: InventoryTableProps) {
  const formatNumber = (number: number) => {
    return new Intl.NumberFormat('en-CA').format(number);
  };

  const renderSortIcon = (field: keyof InventoryItem) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? (
      <ChevronUp className="h-4 w-4" />
    ) : (
      <ChevronDown className="h-4 w-4" />
    );
  };

  return (
    <div className="bg-white shadow-sm rounded-lg border border-gray-200 overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer"
                onClick={() => onSort('stockNumber')}
              >
                <div className="flex items-center gap-x-2">
                  Stock #
                  {renderSortIcon('stockNumber')}
                </div>
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                onClick={() => onSort('year')}
              >
                <div className="flex items-center gap-x-2">
                  Vehicle
                  {renderSortIcon('year')}
                </div>
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                onClick={() => onSort('price')}
              >
                <div className="flex items-center gap-x-2">
                  Price
                  {renderSortIcon('price')}
                </div>
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                onClick={() => onSort('mileage')}
              >
                <div className="flex items-center gap-x-2">
                  Mileage
                  {renderSortIcon('mileage')}
                </div>
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                onClick={() => onSort('status')}
              >
                <div className="flex items-center gap-x-2">
                  Status
                  {renderSortIcon('status')}
                </div>
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                onClick={() => onSort('condition')}
              >
                <div className="flex items-center gap-x-2">
                  Condition
                  {renderSortIcon('condition')}
                </div>
              </th>
              <th
                scope="col"
                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
              >
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {inventory.map((vehicle) => (
              <tr key={vehicle.id} className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {vehicle.stockNumber}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="flex flex-col">
                    <span>{vehicle.year} {vehicle.make} {vehicle.model}</span>
                    <span className="text-xs text-gray-400">{vehicle.trim}</span>
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium">
                  {formatCurrency(vehicle.price)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {formatNumber(vehicle.mileage)} km
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm">
                  <span className={`inline-flex rounded-full px-2 py-1 text-xs font-medium ${
                    vehicle.status === 'In Stock'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {vehicle.status}
                  </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm">
                  <span className={`inline-flex rounded-full px-2 py-1 text-xs font-medium ${
                    vehicle.condition === 'New'
                      ? 'bg-blue-100 text-blue-800'
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {vehicle.condition}
                  </span>
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a
                    href={vehicle.listingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-900 inline-flex items-center gap-1"
                  >
                    View
                    <ExternalLink className="h-4 w-4" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}