import { Reminder } from '../types/reminder';

export const sampleReminders: Reminder[] = [
  // Birthday reminders
  {
    id: '1',
    userId: '1',
    customerId: '1',
    title: 'Birthday Wishes - John Smith',
    description: 'Send birthday wishes and special EV promotion to John',
    dueDate: '2024-06-15T09:00:00Z',
    priority: 'medium',
    type: 'birthday',
    status: 'pending',
    createdAt: '2024-03-15T08:00:00Z',
    updatedAt: '2024-03-15T08:00:00Z'
  },
  {
    id: '2',
    userId: '2',
    customerId: '2',
    title: 'Birthday Wishes - Sarah Johnson',
    description: 'Send birthday wishes and BMW X7 test drive invitation',
    dueDate: '2024-08-22T09:00:00Z',
    priority: 'medium',
    type: 'birthday',
    status: 'pending',
    createdAt: '2024-03-14T08:00:00Z',
    updatedAt: '2024-03-14T08:00:00Z'
  },

  // Purchase Anniversary reminders
  {
    id: '3',
    userId: '1',
    customerId: '11',
    title: 'Purchase Anniversary - Richard Cooper',
    description: 'One-year anniversary of Audi RS e-tron GT purchase',
    dueDate: '2024-12-10T09:00:00Z',
    priority: 'medium',
    type: 'anniversary',
    status: 'pending',
    createdAt: '2024-03-10T08:00:00Z',
    updatedAt: '2024-03-10T08:00:00Z'
  },
  {
    id: '4',
    userId: '2',
    customerId: '12',
    title: 'Purchase Anniversary - Amanda Patel',
    description: 'First service check-up reminder and anniversary wishes',
    dueDate: '2024-04-15T09:00:00Z',
    priority: 'high',
    type: 'anniversary',
    status: 'pending',
    createdAt: '2024-03-15T08:00:00Z',
    updatedAt: '2024-03-15T08:00:00Z'
  },

  // Service reminders
  {
    id: '5',
    userId: '1',
    customerId: '13',
    title: 'Service Due - Thomas Wright',
    description: 'First maintenance service for Mercedes-Benz EQS',
    dueDate: '2024-03-20T10:00:00Z',
    priority: 'high',
    type: 'general',
    status: 'pending',
    createdAt: '2024-03-13T08:00:00Z',
    updatedAt: '2024-03-13T08:00:00Z'
  },
  {
    id: '6',
    userId: '2',
    customerId: '14',
    title: 'Follow-up - Sophie Kim',
    description: 'Check satisfaction with new Porsche Taycan',
    dueDate: '2024-03-19T14:00:00Z',
    priority: 'medium',
    type: 'general',
    status: 'pending',
    createdAt: '2024-03-12T08:00:00Z',
    updatedAt: '2024-03-12T08:00:00Z'
  },

  // Test drive follow-ups
  {
    id: '7',
    userId: '1',
    customerId: '15',
    title: 'Test Drive Follow-up - Marcus Johnson',
    description: 'Follow up on Lexus RX 450h test drive experience',
    dueDate: '2024-03-18T11:00:00Z',
    priority: 'high',
    type: 'general',
    status: 'pending',
    createdAt: '2024-03-15T08:00:00Z',
    updatedAt: '2024-03-15T08:00:00Z'
  },

  // Maintenance reminders
  {
    id: '8',
    userId: '2',
    customerId: '1',
    title: 'Tesla Model 3 Software Update',
    description: 'Notify about new software update and schedule installation',
    dueDate: '2024-03-22T13:00:00Z',
    priority: 'medium',
    type: 'general',
    status: 'pending',
    createdAt: '2024-03-15T08:00:00Z',
    updatedAt: '2024-03-15T08:00:00Z'
  },

  // Special promotions
  {
    id: '9',
    userId: '1',
    customerId: '11',
    title: 'EV Charging Network Expansion',
    description: 'Share updates about new charging stations installation',
    dueDate: '2024-03-25T15:00:00Z',
    priority: 'low',
    type: 'general',
    status: 'pending',
    createdAt: '2024-03-15T08:00:00Z',
    updatedAt: '2024-03-15T08:00:00Z'
  },

  // Trade-in opportunities
  {
    id: '10',
    userId: '2',
    customerId: '13',
    title: 'Trade-in Opportunity',
    description: 'Discuss EQS trade-in for upcoming EQS AMG',
    dueDate: '2024-04-01T10:00:00Z',
    priority: 'medium',
    type: 'general',
    status: 'pending',
    createdAt: '2024-03-15T08:00:00Z',
    updatedAt: '2024-03-15T08:00:00Z'
  }
];