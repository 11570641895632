import { Lead } from '../types/lead';
import { v4 as uuidv4 } from 'uuid';

const createLead = (data: Omit<Lead, 'id'>): Lead => ({
  id: uuidv4(),
  ...data
});

export const sampleLeads: Lead[] = [
  createLead({
    name: 'Michael Chen',
    email: 'mchen@email.com',
    phone: '(902) 555-0123',
    vehicle: '2024 BMW M5 Competition',
    status: 'qualified',
    source: 'website',
    createdAt: new Date('2024-03-15T10:30:00').toISOString(),
    lastContact: new Date('2024-03-15T14:45:00').toISOString(),
    score: 85,
    engagementMetrics: {
      emailOpens: 8,
      emailClicks: 4,
      websiteVisits: 6,
      callDuration: 15,
      lastInteraction: new Date('2024-03-15T14:45:00').toISOString()
    },
    preferences: {
      preferredContact: 'email',
      interests: ['performance', 'luxury']
    }
  }),
  createLead({
    name: 'Sarah Johnson',
    email: 'sarah.j@email.com',
    phone: '(902) 555-0456',
    vehicle: '2024 Range Rover Sport Autobiography',
    status: 'new',
    source: 'referral',
    createdAt: new Date('2024-03-16T09:15:00').toISOString(),
    lastContact: new Date('2024-03-16T09:15:00').toISOString(),
    score: 45,
    engagementMetrics: {
      emailOpens: 2,
      emailClicks: 1,
      websiteVisits: 3,
      lastInteraction: new Date('2024-03-16T09:15:00').toISOString()
    },
    preferences: {
      preferredContact: 'phone',
      interests: ['luxury', 'suv']
    }
  }),
  createLead({
    name: 'James Anderson',
    email: 'j.anderson@email.com',
    phone: '(902) 555-0789',
    vehicle: '2024 Porsche 911 GT3 RS',
    status: 'contacted',
    source: 'website',
    createdAt: new Date('2024-03-14T15:20:00').toISOString(),
    lastContact: new Date('2024-03-15T11:30:00').toISOString(),
    score: 62,
    engagementMetrics: {
      emailOpens: 5,
      emailClicks: 2,
      websiteVisits: 4,
      callDuration: 8,
      lastInteraction: new Date('2024-03-15T11:30:00').toISOString()
    },
    preferences: {
      preferredContact: 'email',
      interests: ['performance', 'luxury']
    }
  }),
  createLead({
    name: 'Emily Brown',
    email: 'emily.b@email.com',
    phone: '(902) 555-1012',
    vehicle: '2024 Tesla Model S Plaid',
    status: 'converted',
    source: 'phone',
    createdAt: new Date('2024-03-10T13:45:00').toISOString(),
    lastContact: new Date('2024-03-15T16:20:00').toISOString(),
    score: 95,
    engagementMetrics: {
      emailOpens: 12,
      emailClicks: 7,
      websiteVisits: 9,
      callDuration: 45,
      lastInteraction: new Date('2024-03-15T16:20:00').toISOString()
    },
    preferences: {
      preferredContact: 'phone',
      interests: ['electric', 'performance', 'luxury']
    }
  }),
  createLead({
    name: 'David Wilson',
    email: 'dwilson@email.com',
    phone: '(902) 555-1345',
    vehicle: '2024 Mercedes-AMG GT 63 S',
    status: 'qualified',
    source: 'website',
    createdAt: new Date('2024-03-13T11:00:00').toISOString(),
    lastContact: new Date('2024-03-15T15:15:00').toISOString(),
    score: 78,
    engagementMetrics: {
      emailOpens: 6,
      emailClicks: 3,
      websiteVisits: 7,
      callDuration: 25,
      lastInteraction: new Date('2024-03-15T15:15:00').toISOString()
    },
    preferences: {
      preferredContact: 'email',
      interests: ['performance', 'luxury']
    }
  }),
  createLead({
    name: 'Jennifer Lee',
    email: 'jlee@email.com',
    phone: '(902) 555-2468',
    vehicle: '2024 Lexus LX 600 Ultra Luxury',
    status: 'new',
    source: 'referral',
    createdAt: new Date('2024-03-16T14:30:00').toISOString(),
    lastContact: new Date('2024-03-16T14:30:00').toISOString(),
    score: 40,
    engagementMetrics: {
      emailOpens: 1,
      emailClicks: 0,
      websiteVisits: 2,
      lastInteraction: new Date('2024-03-16T14:30:00').toISOString()
    },
    preferences: {
      preferredContact: 'email',
      interests: ['luxury', 'suv']
    }
  }),
  createLead({
    name: 'Robert Taylor',
    email: 'rtaylor@email.com',
    phone: '(902) 555-3579',
    vehicle: '2024 Audi RS e-tron GT',
    status: 'contacted',
    source: 'website',
    createdAt: new Date('2024-03-15T10:15:00').toISOString(),
    lastContact: new Date('2024-03-16T11:45:00').toISOString(),
    score: 55,
    engagementMetrics: {
      emailOpens: 4,
      emailClicks: 2,
      websiteVisits: 5,
      lastInteraction: new Date('2024-03-16T11:45:00').toISOString()
    },
    preferences: {
      preferredContact: 'phone',
      interests: ['electric', 'performance', 'luxury']
    }
  }),
  createLead({
    name: 'Maria Garcia',
    email: 'mgarcia@email.com',
    phone: '(902) 555-4680',
    vehicle: '2024 Genesis G90 Ultimate',
    status: 'new',
    source: 'email',
    createdAt: new Date('2024-03-16T16:20:00').toISOString(),
    lastContact: new Date('2024-03-16T16:20:00').toISOString(),
    score: 35,
    engagementMetrics: {
      emailOpens: 2,
      emailClicks: 1,
      websiteVisits: 1,
      lastInteraction: new Date('2024-03-16T16:20:00').toISOString()
    },
    preferences: {
      preferredContact: 'email',
      interests: ['luxury']
    }
  }),
  createLead({
    name: 'William Chang',
    email: 'wchang@email.com',
    phone: '(902) 555-5791',
    vehicle: '2024 Lucid Air Dream Edition',
    status: 'qualified',
    source: 'website',
    createdAt: new Date('2024-03-14T09:45:00').toISOString(),
    lastContact: new Date('2024-03-16T13:30:00').toISOString(),
    score: 72,
    engagementMetrics: {
      emailOpens: 7,
      emailClicks: 4,
      websiteVisits: 6,
      callDuration: 20,
      lastInteraction: new Date('2024-03-16T13:30:00').toISOString()
    },
    preferences: {
      preferredContact: 'phone',
      interests: ['electric', 'luxury', 'performance']
    }
  }),
  createLead({
    name: 'Sophie Martin',
    email: 'smartin@email.com',
    phone: '(902) 555-6802',
    vehicle: '2024 Rolls-Royce Ghost Extended',
    status: 'contacted',
    source: 'referral',
    createdAt: new Date('2024-03-15T13:20:00').toISOString(),
    lastContact: new Date('2024-03-16T15:45:00').toISOString(),
    score: 58,
    engagementMetrics: {
      emailOpens: 3,
      emailClicks: 2,
      websiteVisits: 4,
      lastInteraction: new Date('2024-03-16T15:45:00').toISOString()
    },
    preferences: {
      preferredContact: 'email',
      interests: ['luxury']
    }
  })
];

export const generateSampleLeads = () => sampleLeads;