import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Video, Camera, StopCircle, RefreshCw, AlertCircle, Loader2, Settings } from 'lucide-react';
import { agoraService } from '../../utils/agora';

interface VideoRecorderProps {
  onVideoReady: (url: string) => void;
  maxDuration?: number;
}

export function VideoRecorder({ onVideoReady, maxDuration = 60 }: VideoRecorderProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [timer, setTimer] = useState(0);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      agoraService.releaseDevices().catch(console.error);
    };
  }, []);

  const checkPermissions = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'camera' as PermissionName });
      if (result.state === 'denied') {
        setPermissionDenied(true);
        throw new Error('Camera permission is denied. Please enable it in your browser settings.');
      }
    } catch (error) {
      // Some browsers might not support the permissions API
      // We'll handle the permission in the getUserMedia call
    }
  };

  const handleStartRecording = useCallback(async () => {
    try {
      setError(null);
      setIsInitializing(true);
      setPermissionDenied(false);

      await checkPermissions();

      if (!videoRef.current) {
        throw new Error('Video element not found');
      }

      await agoraService.initializeDevices();
      await agoraService.startRecording(videoRef.current);

      setIsInitializing(false);
      setIsRecording(true);
      setTimer(0);

      timerRef.current = setInterval(() => {
        setTimer(prev => {
          if (prev >= maxDuration) {
            handleStopRecording();
            return prev;
          }
          return prev + 1;
        });
      }, 1000);

      setTimeout(() => {
        if (isRecording) {
          handleStopRecording();
        }
      }, maxDuration * 1000);
    } catch (error) {
      const errorMessage = error && typeof error === 'object' && 'message' in error 
        ? error.message 
        : 'Failed to start recording';
        
      if (errorMessage.includes('Permission denied') || 
          errorMessage.includes('NotAllowedError') || 
          errorMessage.includes('NotFoundError')) {
        setPermissionDenied(true);
        setError('Camera access was denied. Please enable camera access in your browser settings.');
      } else {
        setError(errorMessage as string);
      }
      
      setIsInitializing(false);
      setIsRecording(false);
      await agoraService.releaseDevices().catch(console.error);
    }
  }, [maxDuration, isRecording]);

  const handleStopRecording = useCallback(async () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    try {
      const videoUrl = await agoraService.stopRecording();
      setPreviewUrl(videoUrl);
      onVideoReady(videoUrl);
    } catch (error) {
      const errorMessage = error && typeof error === 'object' && 'message' in error 
        ? error.message 
        : 'Failed to stop recording';
      setError(errorMessage as string);
    } finally {
      setIsRecording(false);
    }
  }, [onVideoReady]);

  const handleReset = useCallback(async () => {
    setPreviewUrl(null);
    setError(null);
    setTimer(0);
    setPermissionDenied(false);
    await agoraService.releaseDevices().catch(console.error);
  }, []);

  const openBrowserSettings = () => {
    if (navigator.userAgent.includes('Chrome')) {
      window.open('chrome://settings/content/camera');
    } else if (navigator.userAgent.includes('Firefox')) {
      window.open('about:preferences#privacy');
    } else {
      window.open('about:settings');
    }
  };

  return (
    <div className="space-y-4">
      <div className="relative aspect-video bg-gray-100 rounded-lg overflow-hidden">
        {permissionDenied ? (
          <div className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center">
            <AlertCircle className="h-12 w-12 text-red-500 mb-2" />
            <p className="text-gray-700 mb-4">Camera access is required for recording</p>
            <button
              onClick={openBrowserSettings}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
            >
              <Settings className="h-4 w-4 mr-2" />
              Open Browser Settings
            </button>
          </div>
        ) : !previewUrl ? (
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            playsInline
            muted
          />
        ) : (
          <video
            src={previewUrl}
            controls
            className="w-full h-full"
          />
        )}

        {isRecording && (
          <div className="absolute top-4 right-4 bg-red-500 text-white px-2 py-1 rounded-full text-sm font-medium flex items-center">
            <span className="animate-pulse mr-2">●</span>
            {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}
          </div>
        )}
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Recording Error</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center space-x-4">
        {!isRecording && !previewUrl && !isInitializing && !permissionDenied && (
          <button
            onClick={handleStartRecording}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <Camera className="h-5 w-5 mr-2" />
            Start Recording
          </button>
        )}

        {isInitializing && (
          <div className="inline-flex items-center px-4 py-2 text-sm font-medium">
            <Loader2 className="h-5 w-5 mr-2 animate-spin" />
            Initializing camera...
          </div>
        )}

        {isRecording && (
          <button
            onClick={handleStopRecording}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
          >
            <StopCircle className="h-5 w-5 mr-2" />
            Stop Recording
          </button>
        )}

        {previewUrl && (
          <button
            onClick={handleReset}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
          >
            <RefreshCw className="h-5 w-5 mr-2" />
            Record Again
          </button>
        )}
      </div>
    </div>
  );
}